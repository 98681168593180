import UserDS from "datastore/UserDS";
import { DispatchLetter } from "components/phases/Contexts/interfaces";
import { RecordPayload, Letter } from "components/phases/Contexts/interfaces";
type RecordLetterArgs = {
  dispatchLetter: DispatchLetter;
  payload: RecordPayload;
};
type SendLetterArgs = {
  dispatchLetter: DispatchLetter;
  payload: Letter;
};
export const record = ({ dispatchLetter, payload }: RecordLetterArgs) => {
  try {
    dispatchLetter({ type: "RECORD_LETTER", payload });
  } catch (err: any) {
    console.log("error", err?.response?.data);
  }
};

export const setLetters = ({ dispatchLetter, payload }: any) => {
  try {
    dispatchLetter({ type: "SET_LETTERS", payload });
  } catch (err: any) {
    console.log("error", err?.response?.data);
  }
};
export const setCorrectedLetters = ({ dispatchLetter, payload }: any) => {
  try {
    dispatchLetter({ type: "SET_CORRECTED_LETTERS", payload });
  } catch (err: any) {
    console.log("error", err?.response?.data);
  }
};
export const updateNotRecordedLetters = ({ dispatchLetter, payload }: any) => {
  try {
    dispatchLetter({ type: "UPDATE_NOT_RECORDED_LETTER", payload });
  } catch (err: any) {
    console.log("error", err?.response?.data);
  }
};
export const setNotRecordedLetters = ({ dispatchLetter, payload }: any) => {
  try {
    dispatchLetter({ type: "SET_NOT_RECORDED_LETTERS", payload });
  } catch (err: any) {
    console.log("error", err?.response?.data);
  }
};
export const setCorrected = ({ dispatchLetter, payload }: any) => {
  try {
    dispatchLetter({ type: "SET_CORRECTED", payload });
  } catch (err: any) {
    console.log("error", err?.response?.data);
  }
};
export const setLoading = ({ dispatchLetter, payload }: any) => {
  try {
    dispatchLetter({ type: "SET_LOADING", payload });
  } catch (err: any) {
    console.log("error", err?.response?.data);
  }
};
export const setCurrentRevision = ({ dispatchLetter, payload }: any) => {
  try {
    dispatchLetter({ type: "SET_CURRENT_REVISION", payload });
  } catch (err: any) {
    console.log("error", err?.response?.data);
  }
};
export const setAllSent = ({ dispatchLetter, payload }: any) => {
  try {
    dispatchLetter({ type: "SET_ALL_SENT", payload });
  } catch (err: any) {
    console.log("error", err?.response?.data);
  }
};
export const setIsRemake = ({ dispatchLetter, payload }: any) => {
  try {
    dispatchLetter({ type: "SET_IS_REMAKE", payload });
  } catch (err: any) {
    console.log("error", err?.response?.data);
  }
};
export const sendLetter = ({ dispatchLetter, payload }: SendLetterArgs) => {
  try {
    dispatchLetter({ type: "SENDING_TRUE" });

    UserDS.saveVoiceRecord({ ...payload }, (data: any) => {
      dispatchLetter({ type: "SEND_LETTER", payload: payload.id });
      dispatchLetter({ type: "SENDING_FALSE" });
    });
  } catch (err: any) {
    console.log("error", err?.response?.data);
  }
};
