import React, { useState, useEffect, useCallback, useImperativeHandle } from 'react';
import { ReactComponent as IconAudioExo } from '../../assets/icon_audio_exo.svg';
import './AudioBtn.scss'
import FileDS from '../../datastore/FileDS';
import { BeatLoader } from 'react-spinners';
import AudioPlayer from '../utils/AudioPlayer';

let retries = 0;
const AudioBtn = React.forwardRef((props: { audioKey: string, onAudioEnded?: Function, autoPlay?: boolean, style?: any, onPlay?: Function }, ref: any) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>();
  const { onAudioEnded, autoPlay, onPlay } = props;

  useEffect(() => {
    if (props.audioKey) {
        setFileUrl(FileDS.getURLFile(props.audioKey));
    }
  }, [props.audioKey]);

  const play = useCallback((afterAudioPlayed?: Function) => {
    if (fileUrl) {
      setPlaying(true);
      if(onPlay) onPlay()
      AudioPlayer.audio.onended = () => {
        setPlaying(false);
        if (onAudioEnded) onAudioEnded();
        if(afterAudioPlayed) afterAudioPlayed();
      }
      AudioPlayer.play(fileUrl, () => {
        retries++;
        if (retries < 5) {
          console.log('Retries => ' + retries);
          play();
        } else {
          console.log('End of Retries');
          setPlaying(false); if (onAudioEnded) onAudioEnded();
          retries = 0
        }
      });
    }
  }, [fileUrl, onAudioEnded, onPlay]);

  useEffect(() => {
    if (autoPlay)
      play();
  }, [play, autoPlay])

  useImperativeHandle(ref, () => ({
    play(afterAudioPlayed?: Function) {
      console.log('audioKey', props.audioKey);
      play(afterAudioPlayed);
    },
    stop() {
      console.log('stop Audio', props.audioKey);
      AudioPlayer.stop();
    }
  }));

  return (
    <>
      {!playing ? (
        <IconAudioExo ref={ref} className="ks-clickable ks-audioBtn" onClick={() => play()} style={props.style} />
      ) : (
          <div className="ks-audio-playing" style={props.style}>
            <BeatLoader />
          </div>
        )}
    </>
  );
})

export default AudioBtn;
