import { Trans, useTranslation } from 'react-i18next';
import './Section2.scss';

function Section2() {
  useTranslation();

  return (
    <>
      <section className="section2">
        <h2><Trans i18nKey={"homepage-video-title"} /></h2>
        <div className="vimeoVideo desktop">
          <iframe src="https://player.vimeo.com/video/535138410?h=4e6c1061cb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="AWLAD School Pr&amp;eacute;sentation"></iframe>
        </div>
      </section>
    </>
  );
}

export default Section2;
