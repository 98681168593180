import './IndexOverview.scss'
import { ReactComponent as IconClose } from 'assets/v3/icon-close.svg';
import { ReactComponent as IconSearch } from 'assets/v3/magnifier.svg';
import { TSession } from 'types';
import { ReactComponent as Stars } from 'assets/v2/sessions/stars.svg';
import { ReactComponent as QuestionMark } from 'assets/v3/question-mark-rounded.svg';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const compare = (objectifTag: string, query: string) => {
 return objectifTag.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(query.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1;
}

function IndexOverview(props: { setSessionToUnlock: Function, setOpenUnlockLesson: Function, openIndexOverview: boolean, setOpenIndexOverview: Function, sessions: TSession[] | undefined }) {
  const [sessionList, setSessionList] = useState<TSession[] | undefined>(props.sessions)
  const filter = (event: any) => {
    const query = event.target.value;
    setSessionList(query
      ? props.sessions?.filter(sessionTemp => sessionTemp.objectifTag && compare(sessionTemp.objectifTag, query))
      : props.sessions);
  }

  useEffect(() => {
    setSessionList(props.sessions);
  }, [props.sessions])

  useEffect(() => {
    if (!props.openIndexOverview) {
      setSessionList(props.sessions);
    }
  }, [props.openIndexOverview, props.sessions])

  return (<>
    {!props.openIndexOverview ? (<></>) : (
      <div className='ks-IndexOverview' >
        <IconClose className='ks-IndexOverview-frame-close' onClick={() => props.setOpenIndexOverview(false)} />
        <div className='ks-IndexOverview-search'>
          <IconSearch className='ks-IndexOverview-search-icon' />
          <input type='text' placeholder='Recherche par mot clé…' onChange={filter} />
        </div>
        <div className='ks-IndexOverview-results'>
          {sessionList?.filter(s => s.sessionType !== "revision").map((sessionTemp: TSession) => (
            <div className={`ks-IndexOverview-results-frame ${(sessionTemp.isLocked || !sessionTemp.isPublished) && 'locked'}`}>
              <div className='ks-IndexOverview-results-title'>{sessionTemp.objectifTitle}</div>
              <div className='ks-IndexOverview-results-desc'>{sessionTemp.objectifDescription}</div>
              <Stars className={`ks-chapter3-homepage-session-stars star-enabled-${sessionTemp.starsCount}`} />
              <div className='ks-chapter3-homepage-session-btn-qm'>
                <Link to={`/phase3/${sessionTemp.chapterId}/session/${sessionTemp.sessionKey}`} className={`mainBtn`} >
                  {!sessionTemp.isPublished ? 'Prochainement' : (sessionTemp.isLocked ? 'Bloquée' : 'Commencer')}
                </Link>
                {sessionTemp.isLocked && sessionTemp.isPublished ? (

                  <div className='ks-chapter3-homepage-session-qm' onClick={() => {
                    props.setSessionToUnlock(sessionTemp.id);
                    props.setOpenUnlockLesson(true);
                  }}>
                    <QuestionMark className='ks-chapter3-homepage-session-qm' onClick={() => {
                      props.setSessionToUnlock(sessionTemp.id);
                      props.setOpenUnlockLesson(true);
                    }} />
                  </div>
                ) : (<></>)}
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
  </>
  );
}

export default IndexOverview;
