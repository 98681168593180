import SignUpFormV3 from '../elements/SignupFormV3';
import './EmbeddedAuthentication.scss'

const EmbeddedAuthentication = (props: any) => {

  return (<>
    <div className="ks-embedded-authentication">
      <div className="ks-loginV3">
          <SignUpFormV3 {...props} showAvatar />
      </div>
    </div>
  </>);
}
export default EmbeddedAuthentication;