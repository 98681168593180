import './NewsletterRegistration.scss'
import { ReactComponent as ContactUsImg } from '../../assets/standalone/contactus-img.svg';
import { ReactComponent as BDouinLogo } from '../../assets/standalone/bdouin-logo-full.svg';
import { useState } from 'react';
import BaseDS from 'datastore/BaseDS';

const NewsletterRegistration = () => {

  const [loading, setLoading] = useState<boolean>();
  const [success, setSuccess] = useState<boolean>();
  const [formData, setFormData] = useState<any>({
    name: '',
    email: '',
  });

  const handleInputChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const sendBtnHandler = async () => {
    setLoading(true);
    try {
      await BaseDS.registerToNewsletter(formData);
      setSuccess(true);
    } catch (err: any) {
      alert(err.message);
    }
    setLoading(false);

  }

  const isValid = () => {
    return !formData.name && !formData.email;
  }
  return (
    <div className='ks-register-newsletter'>
      <ContactUsImg className='ks-register-newsletter-img'/>
      <div className='ks-register-newsletter-title'>S'inscrire à la newsletter du BDouin</div>
      <div className='ks-register-newsletter-form-bkg'>
      </div>
      <div className='ks-register-newsletter-form'>
        <label>Nom&nbsp;<span>(obligatoire)</span></label>
        <input onChange={handleInputChange} name='name' autoComplete='name' placeholder="Votre nom ici" />
        <label>Email&nbsp;<span>(obligatoire)</span></label>
        <input onChange={handleInputChange} name='email' autoComplete='email' type="email" placeholder="Votre email ici" />
        <div className='ks-register-newsletter-btn-mention'>
          <button disabled={isValid() || loading || success} onClick={sendBtnHandler}>{!success ? "S'INSCRIRE" : "INSCRIT !"}</button>
          <p><i>* Vos coordonnées resteront 100% confidentielles et ne seront ni partagées, ni revendues, à un tiers.</i></p>
        </div>
      </div>
      <BDouinLogo className='ks-register-newsletter-logo'/>
    </div>
  );
}
export default NewsletterRegistration;