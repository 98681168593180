import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './LessonNumbers.scss'
import { SessionContext } from 'components/utils/SessionContext';
import { ReactComponent as IconDone } from '../../../assets/v2/sessions/icon-correct.svg';
import { ReactComponent as IconArrow } from '../../../assets/icon_profil_arrow.svg';
import { ReactComponent as IconPadlock } from '../../../assets/padlock.svg';
import { TInterface } from 'types';
import { unblockedUsers } from 'utils/Constants';

function LessonNumbers() {

  const enabledNumbers = useRef<any>();
  const [newUnlockedInterface, setNewUnlockedInterface] = useState<string>();
  const unlockLessons = localStorage.getItem('unlockLessons');

  const { sessionDefinition, currentInterfaceIndex, setNextInterfaceUnlock,
    profilSession, setCurrentInterfaceIndex, setNbLoadIndex, currentProfil } = useContext(SessionContext);

  const interfaceEnabled = useCallback((interfaceKey: number) => {
    return interfaceKey === 1 || unlockLessons === '1' || unblockedUsers.indexOf(currentProfil.profilKey) > -1 || unblockedUsers.indexOf(currentProfil.name) > -1
      || (profilSession && profilSession.filter((session: any) => interfaceKey === session.interfaceKey).length > 0);
  }, [profilSession, unlockLessons, currentProfil.profilKey, currentProfil.name])

  const interfaceCompleted = useCallback((index: number) => {
    return profilSession[index] && profilSession[index].state === 'completed';
  }, [profilSession]);

  const goToInterface = useCallback((index: number) => {
    setCurrentInterfaceIndex(index);
    setNbLoadIndex((prev: number) => prev + 1);
    setNextInterfaceUnlock(false);
  }, [setCurrentInterfaceIndex, setNbLoadIndex, setNextInterfaceUnlock]);

  useEffect(() => {
    if (!enabledNumbers.current) {
      enabledNumbers.current
        = sessionDefinition.interface.filter((i: any) => interfaceEnabled(i.interfaceKey)).map((i: any) => i.id);
      if (!enabledNumbers.current || !enabledNumbers.current.length) {
        enabledNumbers.current = [sessionDefinition.interface[0].id];
      }
    }
  }, [sessionDefinition, interfaceEnabled])

  const lessonState = (interfaceDesc: TInterface, index: number) => {
    if (interfaceEnabled(interfaceDesc.interfaceKey)) {
      if (index === currentInterfaceIndex) {
        return 'selected';
      } else {
        if (interfaceCompleted(index)) {
          return 'completed';
        } else {
          return 'unlocked';
        }
      }
    } else {
      return 'locked';
    }
  }

  useEffect(() => {
    setNewUnlockedInterface(undefined);
    sessionDefinition.interface.forEach((interfaceTemp: any) => {
      if (interfaceEnabled(interfaceTemp.interfaceKey)
        && !enabledNumbers.current.includes(interfaceTemp.id)) {
        setNewUnlockedInterface(interfaceTemp.id);
        enabledNumbers.current.push(interfaceTemp.id);
      }
    });
  }, [sessionDefinition, interfaceEnabled, currentInterfaceIndex]);

  return (
    <div className='ks-session-lesson-numerotation'>
      {sessionDefinition && sessionDefinition.interface.map((interfaceTemp: any, index: number) => (
        <div key={'lesson_' + index}
          className={`ks-session-lesson-numerotation-item ks-lesson-${lessonState(interfaceTemp, index)} ${newUnlockedInterface === interfaceTemp.id && 'newUnlocked'}`}
          onClick={() => {
            const lessonStateTmp = lessonState(interfaceTemp, index);
            if (lessonStateTmp === 'unlocked'
              || lessonStateTmp === 'completed') {
              goToInterface(index)
            }
          }}>
          {lessonState(interfaceTemp, index) === 'locked' ? (<>
            <IconPadlock className={`ks-session-lesson-numerotation-padlock`} />
          </>) : (<></>)}
          <IconArrow className={`ks-session-lesson-numerotation-arrow`} />
          {(interfaceTemp.label && interfaceTemp.label.toUpperCase()) || `LEÇON 0${index + 1}`}
          <IconDone className={`ks-session-lesson-numerotation-done`} />
        </div>
      ))}
    </div>
  );
}

export default LessonNumbers;
