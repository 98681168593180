import './css/chapters/Dialogue-Chapter2.scss';
import { ReactComponent as NumberPad } from '../assets/v2/interface_number_pad.svg';
import { ReactComponent as NumberPad2 } from '../assets/interlude-interface_number_pad.svg';
import { ReactComponent as Background } from '../assets/v2/sessions/phase3-chapter1-bkg.svg';
import { ReactComponent as Background2 } from '../assets/bkp-phase3-interlude1.svg';
import { ReactComponent as Background3 } from '../assets/bkp-phase3-chapter2.svg';
import { ReactComponent as LessonsBKG } from '../assets/v3/lessons-bkg.svg';
import { ReactComponent as Lock } from '../assets/v2/sessions/lock-overlay.svg';
import { ReactComponent as Lock2 } from '../assets/chapter2-lesson-locked.svg';

import { ClipLoader } from 'react-spinners';

let chapterId: string = "";

export const numberPad = (numberPadRef: any, loaderSize: number) => {
  switch (chapterId) {
    case "1622868529412-3":
    case "1622868529412-1":
      return (<NumberPad ref={numberPadRef} className="ks-number-pad" />);
    case "1622868529412-2":
      return (<div className="ks-number-pad"><NumberPad2 ref={numberPadRef} className="ks-number-pad2" /></div>);
    default:
      return <div className="ks-number-pad"><ClipLoader color='white' size={loaderSize} /></div>;
  }
}

export const svgPerChapter = (className?: string) => {
  switch (chapterId) {
    case '2':
    case "1622868529412-2":
      return [<Background2 className={className || "ks-phase3-chapter1-bkg"} />, <Lock2 className="locked-image" />];
    case "vocabulary":
      return [<Background2 className={className || "ks-phase3-chapter1-bkg"} />, <Lock className="locked-image" />];
    case '3':
    case "1622868529412-3":
      return [<Background3 className={className || "ks-phase3-chapter1-bkg"} />, <Lock className="locked-image" />];
    case "lessons":
      return [<LessonsBKG className={className || "ks-phase3-chapter1-bkg"} />, <Lock className="locked-image" />];
    case '1':
    case "1622868529412-1":
    default:
      return [<Background className={className || "ks-phase3-chapter1-bkg"} />, <Lock className="locked-image" />];
  }
}

function ChapterTheme(props: { children: any, chapterId: any }) {
  chapterId = props.chapterId;
  return (
    <>
      <div className={`ks-chapter-${props.chapterId}`}>
        {props.children}
      </div>
    </>
  );
}

export default ChapterTheme;
