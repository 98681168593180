import UserDS from "datastore/UserDS";
import { createContext, useRef, useState } from "react";

import { TFinalTestReport, TReport, TSession, TTuto, TUserInfo } from "types";



const interfaceFinished = (practiseResult: TReport[] | TFinalTestReport | undefined, sessionDefinition: TSession, currentProfil: TUserInfo | undefined,
  currentInterfaceIndex: number, setCurrentProfil: Function, setProfilSession: Function, setNextInterfaceUnlock?: Function, callback?: Function, forceUpdate?: boolean) => {
  console.log('interfaceFinished');
  if (sessionDefinition && currentInterfaceIndex < sessionDefinition.interface.length) {
    if (currentProfil
      && (!currentProfil[sessionDefinition.chapterId][sessionDefinition.sessionKey][currentInterfaceIndex] ||
        (currentProfil[sessionDefinition.chapterId][sessionDefinition.sessionKey][currentInterfaceIndex].state !== 'completed' || forceUpdate)
        || sessionDefinition.interface[currentInterfaceIndex].type === 'assessment')) {
      const interfaceKey: any = sessionDefinition.interface[currentInterfaceIndex];
      console.log('interfaceKey', interfaceKey);
      console.log("Complete current interface and open next one.");
      UserDS.completeLastInterfaceAndEnableNext(currentProfil.profilKey, sessionDefinition, interfaceKey.interfaceKey, practiseResult).then((data: any) => {
        currentProfil[sessionDefinition.chapterId][sessionDefinition.sessionKey] = data[sessionDefinition.chapterId][sessionDefinition.sessionKey];
        console.log("Profil updated correctly.");
        setCurrentProfil(currentProfil);
        setProfilSession(data[sessionDefinition.chapterId][sessionDefinition.sessionKey]);
        if (setNextInterfaceUnlock) setNextInterfaceUnlock(true);
        console.log("currentDebug", callback);
        if (callback) callback();
      });
    } else if (callback) {
      callback();
    }
    console.log('User finish the interface.');
  }
};

export const SessionContext = createContext<any>("");

const SessionContextProvider = (props: any) => {
  const [sessionDefinition, setSessionDefinition] = useState<TSession>();
  const [currentInterfaceIndex, setCurrentInterfaceIndex] = useState<number>(-1);
  const [nextInterfaceUnlock, setNextInterfaceUnlock] = useState<boolean>();
  const [nbLoadIndex, setNbLoadIndex] = useState<number>(1);
  const [currentInterfaceState, setCurrentInterfaceState] = useState<string>('');
  const [currentProfil, setCurrentProfil] = useState<TUserInfo>();
  const [profilSession, setProfilSession] = useState<any>();
  const [screen, setScreen] = useState(<></>);
  const [currentScreenInfo, setCurrentScreenInfo] = useState<any>();
  const [currentScreen, setCurrentScreen] = useState<number>(-1);
  const [tuto, setTuto] = useState<TTuto>();
  const [currentTutoIndex, setCurrentTutoIndex] = useState<number>();
  const [timeFinished, setTimeFinished] = useState<boolean>(false);
  const timerRef = useRef<any>();

  return (
    <SessionContext.Provider value={{
      interfaceFinished,
      sessionDefinition, setSessionDefinition,
      currentInterfaceIndex, setCurrentInterfaceIndex,
      nextInterfaceUnlock, setNextInterfaceUnlock,
      nbLoadIndex, setNbLoadIndex,
      currentInterfaceState, setCurrentInterfaceState,
      currentProfil, setCurrentProfil,
      profilSession, setProfilSession,
      screen, setScreen,
      currentScreen, setCurrentScreen,
      tuto, setTuto,
      currentTutoIndex, setCurrentTutoIndex,
      timeFinished, setTimeFinished,
      currentScreenInfo, setCurrentScreenInfo,
      timerRef
    }} >
      {props.children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
