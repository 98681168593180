import { Auth } from "aws-amplify";
import React, { createContext, useCallback, useRef, useState } from "react";

import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

export const UserContext = createContext<any>("");
export const productId = process.env.REACT_APP_PRODUCT_ID

const UserContextProvider = (props: any) => {
	const [userInfo, setUserInfo] = useState<any>();
	const [userBlocked, setUserBlocked] = useState<any>();
	const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>();
	const userAccess = useRef<string[]>();
	const freemiumEnabled = process.env.REACT_APP_FEATURE_FREEMIUM === '1';

	const refreshUserInfo = useCallback((callback?: Function) => {

		Auth.currentAuthenticatedUser({ bypassCache: true }).then((data) => {
			setUserInfo(data);
			setIsUserAuthenticated(true);
			// TODO Remove when freemium goes live
			// Start
			if (!freemiumEnabled) {
				const userGroup = data?.signInUserSession.accessToken.payload['cognito:groups'];
				if (!userGroup || !userGroup.length || userGroup.indexOf('blocked') === -1) {
					setUserBlocked(false);
				} else {
					setUserBlocked(true);
				}
			}

			if (callback) callback();
			// End
		}).catch((error) => {
			console.log('error', error);
			setUserInfo(undefined)
			setIsUserAuthenticated(false);
			if (!freemiumEnabled) {
				setUserBlocked(true);
			}
			userAccess.current = [];
		});
	}, [freemiumEnabled]);

	React.useEffect(() => {
		console.log("useEffect => refreshUserInfo");
		refreshUserInfo();
		return onAuthUIStateChange((nextAuthState) => {
			if (AuthState.SignedIn === nextAuthState) {
				console.log("onAuthUIStateChange => refreshUserInfo");
				refreshUserInfo();
			} else {
				setUserInfo(undefined);
				setIsUserAuthenticated(false);
			}
		});
	}, [refreshUserInfo]);

	return (
		<UserContext.Provider value={{ userBlocked, freemiumEnabled, isUserAuthenticated, userInfo, refreshUserInfo }} >
			{props.children}
		</UserContext.Provider>
	);
};

export default UserContextProvider;
