import React, { useRef } from 'react';
import './ImageModal.scss';
import Modal from 'react-modal';
import SVGWithSoundIcon from '../elements/SVGWithSoundIcon';
import Sounds from '../utils/Sounds';

function ImageModal(props: any) {
  const imageAudioRef = useRef<any>();
  const { imageAudio, onModalClose, modalIsOpen, setIsOpen, showRepeatBtn, className, closeBtnName } = props;

  function closeModal() {
    Sounds.playButtonSound();
    setIsOpen(false);
    if (onModalClose) onModalClose();
  }

  return (<>
    {!modalIsOpen ? (<div style={{ display: 'none' }}>
      <SVGWithSoundIcon imageAudio={imageAudio} autoPlay={false} preload/>
    </div>) : (<>
      {imageAudio && (
        <>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            portalClassName={'ReactModalPortal ks-repeatableImageAudio ' + className}
            contentLabel="Seconde fois"
            parentSelector={() => document.body}
            appElement={document.body}
          >
            <div className='ks-modalContent'>
              <SVGWithSoundIcon ref={imageAudioRef} imageAudio={imageAudio} hideAudioIcon autoPlay={modalIsOpen} disabled forcePlay />
              <div className='ks-modalBtn'>
                <button className='mainBtn' onClick={closeModal}>{closeBtnName ? closeBtnName : 'D’ACCORD !'}</button>
                {showRepeatBtn && (
                  <button className='mainBtn ks-repeatBtn' onClick={() => {
                    Sounds.playButtonSound();
                    if (imageAudioRef.current) {
                      imageAudioRef.current.replay();
                    }
                  }}>RÉÉCOUTER</button>
                )}
              </div>
            </div>
          </Modal>

        </>
      )}
    </>)}
  </>)
}

export default ImageModal;
