import { useContext } from 'react';
import './css/ReadWriteChapterLeftArea.scss';
import { ReactComponent as ChapterTitle } from '../assets/v3/readwrite-icon.svg';
import { ReactComponent as WaladBtn } from '../assets/v2/walad-btn.svg';
import { ReactComponent as HomepageBtn } from '../assets/v2/homepage-btn.svg';
import { ReactComponent as DownloadBtn } from '../assets/v2/download-btn.svg';
import { ReactComponent as RevisionBtn } from '../assets/v2/revision-btn.svg';
import { ReactComponent as DiplomaBtn } from '../assets/v2/diploma-btn.svg';
import { ReactComponent as AwladLogo } from '../assets/v3/awlad-school-logo.svg';
import { ReactComponent as BDouinLogo } from '../assets/standalone/bdouin-logo.svg';
import { Link, useHistory } from 'react-router-dom';
import { ChapterContext } from './utils/ChapterContext';
import { unblockedUsers } from 'utils/Constants';
import FileDS from 'datastore/FileDS';

function ReadWriteChapterLeftArea(props: any) {
  const history = useHistory();
  const { setShowWaladCards } = props;
  const { downloadFile, downloading, currentProfil, setShowDiploma } = useContext(ChapterContext);

  return (
    <div className="ks-readwrite-homepage">
      <div className={'ks-awlad-avatar-student'}>
        <img src={FileDS.getHoopowURLFile(currentProfil?.iconAvatar)} alt={currentProfil?.name + " Avatar"} />
        <span>{currentProfil?.name || currentProfil?.profilKey}</span>
      </div>
      <AwladLogo className={'ks-awlad-logo'} onClick={() => history.push('/')} />
      <ChapterTitle className="ks-readwrite-homepage-title" />
      <WaladBtn className="ks-walad-btn" onClick={() => setShowWaladCards((prev: any) => !prev)} />
      <DownloadBtn className={`${downloading && 'ks-disabled'} ks-download-btn`} onClick={() => {
        if (!downloading) {
          downloadFile()
        }
      }} />
      {((currentProfil && currentProfil['1609229669522-1'] && currentProfil['1609229669522-1']['17'])
        || unblockedUsers.indexOf(currentProfil?.name) > -1 || unblockedUsers.indexOf(currentProfil?.profilKey) > -1) && (
          <Link to='/chapter/1609229669522-1/session/17'><RevisionBtn className="ks-revision-btn" /></Link>
        )}
      {currentProfil && currentProfil['1609229669522-1'] && currentProfil['1609229669522-1'].assessmentResult && (
        <DiplomaBtn className="ks-diploma-btn" onClick={() => {
          console.log("ks-diploma-btn");
          setShowDiploma((prev: boolean) => !prev)
        }
        } />
      )}
      <Link to='/'><HomepageBtn className="ks-homepage-btn" /></Link>
      <div className='ks-awlad-mention'>
        <BDouinLogo className='ks-bdouin-logo' />
        <p>Logiciel réalisé et illustré par le studio <a href="https://www.bdouin.com">BDouin</a></p>
      </div>
    </div>);
}

export default ReadWriteChapterLeftArea;
