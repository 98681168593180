import { forwardRef, MouseEventHandler } from 'react';
import ImageDataStore from '../../datastore/ImageDataStore';
import FileLoadEvent from '../utils/FileLoadEvent';
import { ReactSVG } from 'react-svg';

type SVGProps = {
  src: string | null | undefined,
  key?: any,
  afterInjection?: any,
  className?: string,
  preload?: boolean,
  style?: any,
  unifyId?: boolean,
  onClick?: MouseEventHandler<any>
}

const SVG = forwardRef((props: SVGProps, ref: any) => {
  const { src, afterInjection } = props;

  return (
    <div ref={ref} key={props.key} onClick={props.onClick} className={props.className ? props.className : ''} style={props.style} >
      <ReactSVG src={((src && ImageDataStore.getURLImage(src)) || "")}
        onLoadStart={() => setTimeout(() => {
          if (src) FileLoadEvent.triggerFileToLoad(src);
        })}
        afterInjection={(err, svg) => {
          if (src) setTimeout(() => FileLoadEvent.triggerFileLoaded(src), 500);
          if (afterInjection) afterInjection(err, svg)
        }} />
    </div>
  );
});

export default SVG;
