import "./styles.scss";
import Polygon from "assets/Icons/Polygon";

const DropdownComponent = ({
  options,
  handleChange,
  value,
  type,
  label,
}: {
  options?: any;
  niveau?: boolean;
  type?: string;
  label?: string;
  handleChange?: any;
  value?: any;
  selectLabel?: string;
}) => {
  return (
    <div className={`dropdown-component ${type === "niveau" ? "niveau" : ""}`}>
      <div className="dropdown-label">{label}</div>
      <div className={`dropdown-button ${value ? "active" : ""}`}>
        <select name={type} id="dropdown-select" onChange={handleChange}>
          {options.map((option: { label: string; value: string }, id: any) => (
            <option
              key={id}
              value={option.value}
              hidden={id === options.indexOf(options[0])}
            >
              {option.label}
            </option>
          ))}
        </select>
        <Polygon className="dropdown-arrow" color="black" />
      </div>
    </div>
  );
};
export default DropdownComponent;
