import './UnlockLesson.scss'
import { ReactComponent as PadLock } from 'assets/padlock.svg';
import { ReactComponent as IconClose } from 'assets/v3/icon-close.svg';
import BaseDS from 'datastore/BaseDS';
import UserDS from 'datastore/UserDS';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import useResponsiveSize from 'components/utils/useResponsiveSize';

function UnlockLesson(props: { openUnlockLesson: boolean, setOpenUnlockLesson: Function, sessionId: string | undefined }) {
  const [loading, setLoading] = useState<boolean>();
  const loaderSize = useResponsiveSize(30);

  const unlockSession = async () => {
    setLoading(true);
    UserDS.getCurrentProfilKey(async (profilKey: string) => {
      try {
        await BaseDS.unblockSession(profilKey, props.sessionId);
        document.location.reload();
      } catch (err) {
        setLoading(false)
      }
    })
  }

  return (<>
    {!props.openUnlockLesson ? (<></>) : (
      <div className='ks-unLockLesson' >
        <div className='ks-unLockLesson-background'></div>
        <div className='ks-unLockLesson-frame'>
          <IconClose className='ks-unLockLesson-frame-close' onClick={() => props.setOpenUnlockLesson(false)} />
          <PadLock className='ks-unLockLesson-frame-lock' />
          <div className='ks-unLockLesson-frame-desc'>Pour débloquer une nouvelle leçon,<br />vous devez d’abord terminer la précédente.</div>
          <div className='ks-unLockLesson-frame-subdesc'>
            En effet, la méthode a été conçu dans le cadre d’un apprentissage<br />
            autonome progressif, avec certaines notions clés à posséder<br />
            avant d’en apprendre de nouvelles.<br />
            Cependant, si vous posséder déjà ces connaissances, vous pouvez<br />
            débloquer manuellement la leçon.
          </div>
          <button className={'mainBtn cancel ' + (loading && "ks-disabled")} onClick={() => props.setOpenUnlockLesson(false)}>REVENIR</button>
          <button className={'mainBtn unlock ' + (loading && "ks-disabled")} onClick={unlockSession}>
            {loading ? (<><ClipLoader color='white' size={loaderSize} />&nbsp;</>) : (<></>)}DÉBLOQUER LA LEÇON
          </button>
        </div>
      </div>
    )}
  </>
  );
}

export default UnlockLesson;
