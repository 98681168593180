import React, { useContext, useEffect, useState, useCallback, RefObject } from 'react';
import './Tuto.scss'
import Draggable from 'react-draggable';
import { ReactComponent as ArrowTuto } from '../../assets/arrow_tuto.svg';
import { ReactComponent as ArrowTuto2 } from '../../assets/tuto-arrow.svg';
import { ReactComponent as IconClose } from '../../assets/icon_close.svg';
import { TTutoStep } from '../../types';
import TextDecoder from '../utils/TextDecoder';
import AudioBtn from './AudioBtn';
import Sounds from '../utils/Sounds';
import { TutoContext } from 'components/utils/TutoContext';

const StepTutoComponent = (props: { arrowType?: string, steps: TTutoStep[], open: boolean, loadingProfil: boolean, setCurrentTutoIndex: Function, onAudioEnded: Function }) => {
  const wordTag: RefObject<any> = React.createRef();
  const [arrowPosition, setArrowPosition] = useState<any>({ x: 0, y: 0 });
  const { currentStep, setCurrentStep, playTuto, setPlayTuto, audioRef, closeTuto } = useContext(TutoContext);
  const tuto: TTutoStep = props.steps[currentStep];
  const { setCurrentTutoIndex } = props;

  useEffect(() => {
    setCurrentTutoIndex(currentStep);
  }, [currentStep, setCurrentTutoIndex])

  const calculatePosition = useCallback(() => {
    if (tuto?.displayArrow === 0) return;
    let positionX = tuto?.positionX;
    let positionY = tuto?.positionY;
    if (!positionX) {
      positionX = 0;
    }
    if (!positionY) {
      positionY = 0;
    }
    const screen = document.getElementById('screen');
    if (screen) {
      setArrowPosition({
        x: positionX * screen.clientWidth,
        y: positionY * screen.clientHeight,
      });
    } else setArrowPosition({ x: 0, y: 0 });
  }, [tuto.positionX, tuto.positionY, tuto.displayArrow]);

  useEffect(() => {
    window.addEventListener('resize', calculatePosition);
    calculatePosition();
    return () => {
      window.removeEventListener('resize', calculatePosition);
    }
  }, [calculatePosition])

  const calculateFontSize = () => {
    const screen = document.getElementById('screen');
    if (tuto.fontSize && screen) {
      const fontSizeCalculated = tuto.fontSize * screen.clientWidth / 1526;
      return { fontSize: fontSizeCalculated + 'px' };
    } else {
      return {};
    }
  }

  useEffect(() => {
    if (currentStep === 5) {
      document.getElementById('ks-puzzle')?.click();
    }
  }, [currentStep]);

  useEffect(() => {
    if (!playTuto) {
      audioRef.current.stop();
    } else {
      audioRef.current.play();
    }
  }, [playTuto, audioRef])

  useEffect(() => {
    setPlayTuto(props.open);
  }, [props.open, setPlayTuto])

  return (<>

    <div className={'ks-tutoExplanation' + (props.open ? ' ks-tutoExplanation-open' : '')}>
      <div className='ks-tutoProgress'>
        <div className='ks-tutoProgress-title'>Tuto</div>
        <div className='ks-tutoProgress-progress'>{currentStep + 1}/{props.steps.length}</div>
      </div>
      <div className='ks-tutoText-btn'>
        {tuto.stepAudio && (
          <AudioBtn ref={audioRef} key={tuto.stepAudio + '_' + currentStep} audioKey={tuto.stepAudio} autoPlay={props.open && !props.loadingProfil && playTuto} onAudioEnded={() => { if (props.onAudioEnded) props.onAudioEnded() }} style={{ display: 'none' }} />
        )}
        {props.steps[currentStep + 1] && (
          <AudioBtn key={props.steps[currentStep + 1].stepAudio + '_' + (currentStep + 1)} audioKey={props.steps[currentStep + 1].stepAudio} autoPlay={false} style={{ display: 'none' }} />
        )}
        <div className='ks-tutoText' style={calculateFontSize()}>{TextDecoder.formatText(tuto.tutoText)}</div>
        <div className='ks-tutoBtn'>
          <button disabled={currentStep === 0} className={`mainBtn ${currentStep === 0 ? 'ks-disabled' : ''}`} onClick={() => {
            Sounds.playNextSound(() => setCurrentStep((prev: number) => prev - 1));
          }}>RETOUR</button>
          {props.steps && currentStep < props.steps.length - 1 ? (
            <button className='mainBtn' onClick={() => {
              Sounds.playNextSound(() => setCurrentStep((prev: number) => prev + 1));
            }}>SUIVANT</button>
          ) : (
            <button className='mainBtn' onClick={() => {
              Sounds.playNextSound(closeTuto);
            }}>FERMER</button>
          )}
        </div>
      </div>
      <div className='ks-iconClose-wrapper'>
        <IconClose className='ks-iconClose ks-clickable' onClick={() => {
          Sounds.playNextSound(closeTuto);
        }} />
      </div>
    </div>
    {props.open && tuto.displayArrow !== 0 && (
      <Draggable disabled
        nodeRef={wordTag}
        bounds="parent"
        position={arrowPosition}
      >
        <div
          ref={wordTag}
          style={{ position: 'absolute', zIndex: 55 }}
          className='ks-arrowTuto'
        >{props.arrowType === 'directional'
          ? (<ArrowTuto2 className={`ks-tuto-arrow display-arrow-${tuto.arrowDirection || 1}`} />)
          : (<ArrowTuto />)}</div>
      </Draggable>
    )}
    {props.open && (
      <div className="ks-tutoOverlay"></div>
    )}
  </>);
};

export default function StepTuto(props: any) {
  return (
    <div>
      <StepTutoComponent {...props} />
    </div>
  );
}
