import { useContext } from "react";
import { LetterContext } from "components/phases/Contexts/providers/LetterContext";

const useAlphabets = () => {
  const context = useContext(LetterContext);

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
};

export default useAlphabets;
