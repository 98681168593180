import { ChapterContext } from "components/utils/ChapterContext";
import { useContext, useEffect, useState } from "react";
import UserDS from "../../datastore/UserDS";
import Sounds from "../utils/Sounds";
import AvatarSelector from "./AvatarSelector";
import './ProfilFormV3.scss'
import { ReactComponent as CloseIcon } from 'assets/v3/icon-close.svg';
import { ReactComponent as SelectArrow } from 'assets/v3/select-arrow.svg';
import { BlockerContext } from "components/utils/BlockerContext";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const errorMessageByCode: any = {
  "duplicate_profil_name": "Le nom de l'utilisateur existe déjà.",
  "not_premium": "La version web d’Awlad School ne permet pas la création de plusieurs profils. Cette fonction est disponible uniquement sur la version mobile."
}

const ProfilFormV3 = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [avatarSelected, setAvatarSelected] = useState<string>();
  const { currentProfil, setCurrentProfil } = useContext(ChapterContext);
  const { setBlock } = useContext(BlockerContext);

  const [formData, setFormData] = useState({
    profilKey: currentProfil && currentProfil.profilKey ? currentProfil.profilKey : '',
    name: currentProfil && currentProfil.name ? currentProfil.name : '',
    gender: currentProfil && currentProfil.gender ? currentProfil.gender : '',
    age: currentProfil && currentProfil.age ? currentProfil.age : '',
    language: currentProfil && currentProfil.language ? currentProfil.language : '',
    level: currentProfil && currentProfil.level ? currentProfil.level : ''
  });

  const handleChange = (e: any, prop: string) => {
    Sounds.playButtonSound();
    setFormData({
      ...formData,
      [prop]: e.target ? e.target.value : e
    });
  };

  const enableSaveBtn = () => {
    return (formData.name !== '' && formData.gender !== '' && formData.age !== '' && formData.language !== '' && formData.level !== '') &&
      (formData.name !== currentProfil.name
        || formData.gender !== currentProfil.gender
        || formData.age !== currentProfil.age
        || formData.language !== currentProfil.language
        || formData.level !== currentProfil.level
        || (avatarSelected && currentProfil.iconAvatar !== avatarSelected))
  }

  useEffect(() => {
    setFormData({
      profilKey: currentProfil && currentProfil.profilKey ? currentProfil.profilKey : '',
      name: currentProfil && currentProfil.name ? currentProfil.name : '',
      gender: currentProfil && currentProfil.gender ? currentProfil.gender : '',
      age: currentProfil && currentProfil.age ? currentProfil.age : '',
      language: currentProfil && currentProfil.language ? currentProfil.language : '',
      level: currentProfil && currentProfil.level ? currentProfil.level : ''
    });
    setAvatarSelected('')
  }, [currentProfil]);

  useEffect(() => {
    // Configuring the notification 
    toast.configure();
  }, []);

  const closeProfilForm = () => {
    if (!currentProfil.profilKey) {
      setCurrentProfil(undefined);
    }
    setBlock(false);
  }

  return (
    <div className='ks-studentprofil'>
      <CloseIcon className="ks-studentprofil-close-btn" onClick={closeProfilForm} />
      <div className='ks-studentprofil-left'>
        <form className='ks-form'>
          <div className="ks-form-group">
            <label className="form-label">JE M’APPELLE</label>
            <input type='text' placeholder="..." onChange={(e) => handleChange(e, 'name')}
              className="ks-form-control" value={formData.name}
            />
          </div>
          <div className="ks-form-group select-input">
            <label className="form-label">J'AI</label>
            <select value={formData?.age} onChange={(e) => handleChange(e, 'age')}>
              <option value={""}>...</option>
              <option value={"less10"}>Entre 6 et 10 ans</option>
              <option value={"10and15"}>Entre 10 et 15 ans</option>
              <option value={"more16"}>plus de 16 ans</option>
            </select>
            <SelectArrow className="select-arrow"/>
          </div>
          <div className="ks-form-group select-input">
            <label className="form-label">JE SUIS</label>
            <select value={formData?.gender} onChange={(e) => handleChange(e, 'gender')}>
              <option value={""}>...</option>
              <option value={"man"}>un garçon</option>
              <option value={"woman"}>une fille</option>
            </select>
            <SelectArrow className="select-arrow" />
          </div>
          <div className="ks-form-group select-input">
            <label className="form-label">MA LANGUE NATALE EST</label>
            <select value={formData?.language} onChange={(e) => handleChange(e, 'language')}>
              <option value={""}>...</option>
              <option value={"FR"}>le français</option>
              <option value={"AR"}>l'arabe</option>
              <option value={"EN"}>l'anglais</option>
            </select>
            <SelectArrow className="select-arrow" />
          </div>
          <div className="ks-form-group select-input">
            <label className="form-label">MON NIVEAU EN ARABE</label>
            <select value={formData?.level} onChange={(e) => handleChange(e, 'level')}>
              <option value={""}>...</option>
              <option value={"0"}>Je ne connais absolument rien</option>
              <option value={"1"}>Je sais un peu lire</option>
              <option value={"2"}>Je sais lire et écrire, mais je ne comprends pas</option>
              <option value={"3"}>Je connais un peu, mais j’ai besoin de réviser</option>
              <option value={"4"}>Je parle le darija, mais je ne connais pas l’arabe littéraire</option>
            </select>
            <SelectArrow className="select-arrow" />
          </div>
        </form>
        <button className='mainBtn save' disabled={!enableSaveBtn() || loading} onClick={async () => {
          if (loading) return;
          setLoading(true);
          try {
            const profilSaved = await UserDS.saveProfil(formData.profilKey, formData.name, formData.gender, formData.level, formData.language, formData.age, avatarSelected);
            setCurrentProfil({
              ...currentProfil,
              ...profilSaved
            })
            UserDS.setCurrentUserProfil(profilSaved.profilKey);
            toast.success(`Vos données ont été enregistrées correctement.`, { position: toast.POSITION.TOP_CENTER });
          } catch (error: any) {
            console.log("MyError", error);
            toast.error(errorMessageByCode[error.response.data.code], { position: toast.POSITION.TOP_CENTER });
          }
          setLoading(false);
        }}>ENREGISTRER</button>
        <button className='mainBtn reinit' disabled={currentProfil.root} onClick={async () => {
          if (loading) return;
          if (window.confirm("Êtes vous sûr de vouloir supprimer le compte ?")) {
            setLoading(true);
            try {
              await UserDS.deleteProfil(formData.profilKey);
              document.location.reload();
            } catch (error: any) {
              toast.error(errorMessageByCode[error.response.data.code], { position: toast.POSITION.TOP_CENTER });
            }
            setLoading(false);
          }
        }}>Supprimer ce profil</button>
      </div>

      <div className='ks-studentprofil-right'>
        <AvatarSelector setAvatarId={setAvatarSelected} avatarId={avatarSelected} />
      </div>
    </div>
  );
}
export default ProfilFormV3;