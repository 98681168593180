import React from "react";
import ReactDOM from "react-dom";
import "./styles.scss";
interface ModalProps {
  children: React.ReactChild;
  isOpen?: boolean;
  typeModal?: string;
  onClose: () => void;
}
const Modal = ({ children, typeModal, onClose }: ModalProps) => {
  const domEl = document.getElementById("audio-mainContent_Screen");
  const outsideRef = React.useRef(null);
  if (!domEl) return null;

  return ReactDOM.createPortal(
    <div>
      <div className="modal-overlay" ref={outsideRef} />
      <div
        className={`${
          typeModal === "ProfileCreation" ? "creation-modal" : ""
        } ${typeModal === "FirstTime" ? "welcome-modal" : ""} ${
          typeModal === "AllSent" ? "allSent" : ""
        } modal-wrapper`}
      >
        {children}
      </div>
      {typeModal === "ProfileCreation" ? (
        <div className="modal-note">
          <p>
            NOTE IMPORTANTE :
            <br />
            Ces données sont collectées uniquement dans le but de proposer un
            meilleur service. <br /> Elles ne seront ni revendues, ni partagées
            à un tiers.
          </p>
        </div>
      ) : null}
    </div>,
    domEl
  );
};
export default Modal;
