import { Player } from '@lottiefiles/react-lottie-player';
import { BlockerContext, steps } from 'components/utils/BlockerContext';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './Section1.scss';

function Section1() {
  const { t } = useTranslation();
  const { setBlock, setUnblockStep } = useContext(BlockerContext);

  return (
    <>
      <section className="section1">
        <div className="section1-content">
          <div className="section1-try-it">
            <h1 className="section1-try-it-mobile-title">{t('homepage-try-it-title')}</h1>
            <img src='./images/binti-study.png' alt={t('binti-study')} className="img-binti-study" />
            <div className="section1-try-it-text">
              <h1>{t('homepage-try-it-title')}</h1>
              <p><Trans i18nKey={'homepage-try-it-desc'} /></p>
              <button onClick={() => {
                setUnblockStep(steps.SIGNUP);
                setBlock(true)
              }}>{t('homepage-try-it-btn')}</button>
            </div>
          </div>
          <hr />
          <div className='section1-participate'>
            <div className='col1'>
              <Trans i18nKey={'homepage-participate'} />
              <div className="lf-player-container">
                <Player autoplay loop src="./lotties/rocket.json" />
              </div>
            </div>
            <div className='col2'>
              <a href="https://awladschool.org/donation" className='donation' rel="noreferrer" target="_blank">{t('homepage-donation-btn')}</a>
              <a href="https://awladschool.org/partner" className='partner' rel="noreferrer" target="_blank">{t('homepage-partner-btn')}</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Section1;
