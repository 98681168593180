import { useContext } from "react";
import { CurrentLetterContext } from "components/phases/Contexts/providers/CurrentLetterContext";

const useCurrentLetter = () => {
  const context = useContext(CurrentLetterContext);

  if (context === undefined) {
    throw new Error(
      "useCurrentLetter must be used within CurrentLetterProvider"
    );
  }

  return context;
};

export default useCurrentLetter;
