import { SVGProps } from "Interfaces/SharedInterfaces";
import React from "react";

const Check: React.FC<SVGProps> = (props: SVGProps) => {
  const { width = "42", height = "42", color = "#5a9f6d" } = props;

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 72 72"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="VRAI" transform="translate(0.373 0.266)">
        <circle
          id="Ellipse_41"
          data-name="Ellipse 41"
          cx="36"
          cy="36"
          r="36"
          transform="translate(-0.373 -0.266)"
          fill={color}
        />
        <path
          id="Path_14655"
          data-name="Path 14655"
          d="M14-22.852a5.818,5.818,0,0,1-1.863,4.008l-.193.193L1.081-7.207a67.492,67.492,0,0,1-5.9,5.625Q-7.251.4-8.253.4a5.228,5.228,0,0,1-2.619-.993,5.006,5.006,0,0,1-2.021-2.048,16.073,16.073,0,0,1-.729-3.727,51.778,51.778,0,0,1-.343-6.17,4.705,4.705,0,0,1,1.512-3.349,4.6,4.6,0,0,1,3.34-1.538q1.74,0,2.074,3.182.035.369.053.545a15.944,15.944,0,0,0,.65,3.41q.369.967,1,.967a2.267,2.267,0,0,0,.984-.571,22.579,22.579,0,0,0,1.723-1.556L8.534-22.5a16.284,16.284,0,0,1,2.514-2.127,3.664,3.664,0,0,1,1.881-.721.87.87,0,0,1,.826.439A3.221,3.221,0,0,1,14-23.432Z"
          transform="translate(36.141 47.09)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default Check;
