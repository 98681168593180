import { FC, useEffect, useState } from "react";
import Tooltip from "components/common/Tooltip";
import Send from "assets/Icons/Send";
import "./styles.scss";
import {
  sendLetter,
  updateNotRecordedLetters,
} from "components/phases/Contexts/actions/LetterAcions";
import useDispatchLetter from "components/phases/Contexts/hooks/useDispatchLetter";
import { updateCurrentLetter } from "components/phases/Contexts/actions/CurrentLetterActions";
import useDispatchCurrentLetter from "components/phases/Contexts/hooks/useDispatchCurrentLetter";
import { AudioRecorder } from "./AudioRecorder";
import { AudioPlayer } from "./AudioPlayer";
import AudioPlayerComponent from "./FullAudioPlayer";
import useAlphabets from "components/phases/Contexts/hooks/useAlphabets";
import { Letter } from "components/phases/Contexts/interfaces";
import { setCurrentLetter } from "components/phases/Contexts/actions/CurrentLetterActions";
import { produce } from "immer";

const AudioActions: FC<any> = ({
  currentLetter,
  isCorrected,
}: Letter | any) => {
  const { letters, notRecordedLetters } = useAlphabets();

  const [goNext, setGoNext] = useState<boolean>(false);
  const [disable1_2, setDisable1_2] = useState<boolean>(false);
  const [disable1_3, setDisable1_3] = useState<boolean>(false);
  const [disable2_3, setDisable2_3] = useState<boolean>(false);
  const { id, isSent, audioKey, fullAudioKey, status } = currentLetter;
  const dispatchLetter = useDispatchLetter();
  const dispatchCurrentLetter = useDispatchCurrentLetter();

  useEffect(() => {
    if (goNext && notRecordedLetters.length !== 0) {
      const index = letters.findIndex(
        (letter) => letter.id === notRecordedLetters[0].id
      );

      let newLetter = produce(notRecordedLetters, (draft) => {
        draft[0].isRecorded = true;
        draft[0].audioKey = letters[index].audioKey;
      });
      setCurrentLetter({
        dispatchCurrentLetter,
        letter: newLetter[0],
      });
      setGoNext(false);
    }
  }, [
    notRecordedLetters,
    goNext,
    dispatchCurrentLetter,
    currentLetter,
    letters,
  ]);

  const handleSendLetter = () => {
    setDisable1_2(true);
    if (currentLetter.status && currentLetter.status !== undefined) {
      sendLetter({
        dispatchLetter,
        payload: {
          ...currentLetter,
          recordKey: `${currentLetter.recordKey}-${currentLetter.revision + 1}`,
          revision: currentLetter.revision + 1,
        },
      });
    } else {
      sendLetter({ dispatchLetter, payload: currentLetter });
    }
    updateNotRecordedLetters({ dispatchLetter, payload: id });
    updateCurrentLetter({
      dispatchCurrentLetter,
      payload: { ...currentLetter, isSent: true },
    });
    setGoNext(true);
    setDisable1_2(false);
  };
  return (
    <div
      className={`audio-recorder-container ${isCorrected ? "corrected" : ""}`}
    >
      {isCorrected ? (
        <>
          <AudioPlayerComponent
            audioKey={fullAudioKey}
            tooltipContent="TON AUDIO"
          />
          <AudioPlayerComponent
            audioKey={fullAudioKey}
            tooltipContent="CORRECTION"
            disabled={status === "approved"}
          />
        </>
      ) : (
        <>
          <AudioRecorder
            currentLetter={currentLetter}
            disabled={isSent || disable1_2 || disable1_3}
            setDisabled={setDisable2_3}
          />
          <AudioPlayer
            audioKey={audioKey}
            disabled={isSent || disable1_2 || disable2_3}
            setDisabled={setDisable1_3}
          />
          <Tooltip
            content="ENVOYER"
            v2
            className="send"
            disabled={
              isSent || disable1_3 || disable2_3
                ? true
                : audioKey
                ? false
                : true
            }
          >
            <Send
              onClick={handleSendLetter}
              disabled={
                (isSent || disable1_3 || disable2_3) && audioKey
                  ? true
                  : audioKey
                  ? false
                  : true
              }
            />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default AudioActions;
