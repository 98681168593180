import { useEffect, useState } from "react";
import Tooltip from "components/common/Tooltip";
import Micro from "assets/Icons/Micro";
import Stop from "assets/Icons/Stop";
import useRecorder from "hooks/useRecorder";
import { record } from "components/phases/Contexts/actions/LetterAcions";
import { updateCurrentLetter } from "components/phases/Contexts/actions/CurrentLetterActions";
import useDispatchLetter from "components/phases/Contexts/hooks/useDispatchLetter";
import useDispatchCurrentLetter from "components/phases/Contexts/hooks/useDispatchCurrentLetter";

export const AudioRecorder = ({
  currentLetter,
  disabled,
  setDisabled,
}: any) => {
  const { id, isRecorded } = currentLetter;

  const [content, setContent] = useState<string>(``);
  const [recorded, setRecorded] = useState<boolean>(false);
  const dispatchCurrentLetter = useDispatchCurrentLetter();
  const dispatchLetter = useDispatchLetter();
  let [audioURL, isRecording, recorder, startRecording, stopRecording] =
    useRecorder();
  const handleStartRecording = () => {
    setDisabled(true);
    startRecording();
    setTimeout(function () {
      stopRecording();
      setDisabled(false);
    }, 10000); //wait 2 seconds
  };
  const handleStopRecording = () => {
    stopRecording();
    setDisabled(false);
  };
  useEffect(() => {
    if (audioURL) {
      setRecorded(true);
      record({ dispatchLetter, payload: { id, audioURL } });
      updateCurrentLetter({
        dispatchCurrentLetter,
        payload: { ...currentLetter, audioKey: audioURL, isRecorded: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioURL]);
  useEffect(() => {
    setContent(
      `Appuie sur le bouton micro et récite clairement la lettre 3 fois de suite.`
    );

    if (recorded && isRecorded && !isRecording) {
      setContent(`ENREGISTRER À NOUVEAU`);
    }
  }, [isRecorded, recorded, isRecording]);

  return (
    <>
      {isRecording && recorder !== null ? (
        <Tooltip
          className="isRecording"
          content={`ENREGISTREMENT EN COURS`}
          disabled={disabled}
        >
          <Stop disabled={disabled} onClick={handleStopRecording} />
        </Tooltip>
      ) : (
        <Tooltip
          content={content}
          v2={recorded && isRecorded}
          className="mic"
          disabled={disabled}
        >
          <Micro disabled={disabled} onClick={handleStartRecording} />
        </Tooltip>
      )}
    </>
  );
};
