import AlphabetLetter from "assets/Icons/AlphabetLetter";
import True from "assets/Icons/True";
import False from "assets/Icons/False";
import Link from "assets/Icons/Link";
import AudioActions from "components/elements/Audio";
import "./styles.scss";
import { ClipLoader } from "react-spinners";
import useCurrentLetter from "components/phases/Contexts/hooks/useCurrentLetter";
import { useState, useEffect } from "react";
export default function CorrectedCurrentLetter() {
  const { currentLetter } = useCurrentLetter();
  const [color, setColor] = useState<string>("");
  const correct = currentLetter.status === "approved";
  useEffect(() => {
    correct ? setColor("#5A9F6D") : setColor("#CE6F3D");
  }, [correct, currentLetter]);
  return (
    <div className="corrected-wrapper">
      {!currentLetter ? (
        <ClipLoader color="black" size={60} />
      ) : (
        <>
          <div className={`container ${correct ? "true" : "false"}`}>
            {correct ? (
              <div className="corrections flexend">
                <True />
              </div>
            ) : (
              <div className="corrections">
                <Link />
                <False />
              </div>
            )}

            <div className="letter">
              <AlphabetLetter
                letter={currentLetter.name}
                color={color}
                xy={currentLetter.xy}
              />
            </div>
          </div>
          <AudioActions
            currentLetter={currentLetter}
            isCorrected
            tooltipContent="ÉCOUTER"
          />
        </>
      )}
    </div>
  );
}
