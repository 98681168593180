import { useEffect } from 'react';
import { Route } from 'react-router-dom'

const TitledRoute = (props: any) => {
  const { path, exact, render, pageName, ...rest } = props;
  useEffect(() => {
    console.log('pageName', pageName);
    document.title = document.title.split(' - ')[0] + ' - ' + pageName;
  }, [pageName])

  return (
    <>
      <Route path={path} exact={exact} render={render} {...rest} />
    </>)
}

export default TitledRoute;