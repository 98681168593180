import JWTApi from "./APIWrapper";
import { TChapter, TMainConfig, TSession, TUserInfo } from "../types";

const apiName = "ApiGatewayRestApi";

export default class BaseDS {
  static getRootProfil = (): Promise<TUserInfo> => {
    return JWTApi.get(apiName, "/userInfo/getRootProfil");
  };

  static getProfil = (profilKey: string): Promise<TUserInfo> => {
    return JWTApi.get(apiName, "/userInfo/getProfil/" + profilKey);
  };

  static saveProfil = (
    profilKey: string,
    name?: string,
    gender?: string,
    level?: string,
    language?: string,
    age?: string,
    avatarId?: string
  ): Promise<TUserInfo> => {
    return JWTApi.put(apiName, "/userInfo/saveProfil", {
      body: { name, profilKey, gender, level, language, age, avatarId },
    });
  };

  static deleteProfil = (
    profilKey: string
  ): Promise<TUserInfo> => {
    return JWTApi.del(apiName, "/userInfo/delete/" + profilKey, {});
  };

  static getAllProfils = (): Promise<TUserInfo[]> => {
    return JWTApi.get(apiName, "/userInfo/getAllProfils");
  };

  static sendEmailContactUs = (formData: any): Promise<TUserInfo[]> => {
    return JWTApi.post(apiName, "/contactus", { body: formData });
  };

  static registerToNewsletter = (formData: any): Promise<TUserInfo[]> => {
    return JWTApi.post(apiName, "/newsletter/registration", { body: formData });
  };

  static completeInterfaceAndEnableNext = (
    profilKey: string,
    body: any
  ): Promise<TUserInfo[]> => {
    return JWTApi.put(
      apiName,
      `/userInfo/completeInterfaceAndEnableNext/${profilKey}`,
      { body }
    );
  };

  static unblockNexChapter = (
    profilKey: string,
    chapterId: any
  ): Promise<TUserInfo> => {
    return JWTApi.put(
      apiName,
      `/userInfo/unblockNexChapter/${profilKey}/${chapterId}`,
      { body: {} }
    );
  };

  static unblockSession = (
    profilKey: string,
    sessionId: any
  ): Promise<TUserInfo> => {
    return JWTApi.put(
      apiName,
      `/unblockSession/${profilKey}/${sessionId}`,
      { body: {} }
    );
  };

  static completeBonus = (
    profilKey: string,
    chapterId: string,
    sessionId: string,
    body: any
  ): Promise<TUserInfo[]> => {
    return JWTApi.post(
      apiName,
      `/userInfo/bonusDone/${profilKey}/${chapterId}/${sessionId}`,
      { body }
    );
  };

  static resetProfilSession = (
    profilKey: string,
    chapterId: string,
    sessionId: number
  ) => {
    return JWTApi.put(
      apiName,
      `/userInfo/resetSession/${profilKey}/${chapterId}/${sessionId}`,
      {}
    );
  };

  static getTutoByName = (tutoName: string): Promise<any> => {
    return JWTApi.get(apiName, "/tuto/getFullObjectById/" + tutoName);
  };

  static getAllGreyLetterSets = (letterSetName: string): Promise<any> => {
    return JWTApi.get(
      apiName,
      "/letterSet/getChildrenByParentId/" + letterSetName
    );
  };

  static getMainConfig = (): Promise<TMainConfig> => {
    return JWTApi.get(apiName, "/main/first");
  };

  static getAllPhases = (): Promise<TMainConfig[]> => {
    return JWTApi.get(apiName, "/main/all/withChildren");
  };

  static getMainConfigById = (mainId: string): Promise<TMainConfig> => {
    return JWTApi.get(apiName, `/main/getFullObjectById/${mainId}`);
  };

  static getChapterById = (chapterId: string): Promise<TChapter> => {
    return JWTApi.get(apiName, `/chapter/getById/${chapterId}`);
  };

  static getFullChapterById = (chapterId: string): Promise<TChapter> => {
    return JWTApi.get(apiName, `/chapter/getFullObjectById/${chapterId}`);
  };

  static getFullDialogueLessons = (profilKey: string, sectionType: string): Promise<TSession[]> => {
    return JWTApi.get(apiName, `/dialogue/${sectionType}/${profilKey}`);
  };

  static getFullSessionById = (
    chapterId: string,
    sessionKey: number
  ): Promise<TSession> => {
    return JWTApi.get(
      apiName,
      `/session/getFullObjectById/${chapterId}-${sessionKey}`
    );
  };

  static getAllScreensByInterfaceId = (
    sessionId: string,
    interfaceKey: number
  ): Promise<any[]> => {
    return JWTApi.get(
      apiName,
      `/screen/getAllScreensByInterfaceId/${sessionId}-${interfaceKey}`
    );
  };

  static getAllVoiceRecord = (profilKey: string): Promise<any> => {
    return JWTApi.get(apiName, `/userVoiceRecord/${profilKey}`);
  };

  static saveVoiceRecord = (
    profilKey: string,
    audioFileKey: string,
    alias: string,
    revision: number | undefined,
    userIdentityId: string
  ): Promise<any> => {
    return JWTApi.post(apiName, `/userVoiceRecord`, {
      body: {
        alias,
        profilKey,
        audioFileKey,
        revision,
        userIdentityId,
      },
    });
  };

  static unsubscribeFromProgressionEmails = ({ token }: { token: string }) => {
    return JWTApi.post(apiName, "/unsubscribeFromProgressionEmails", {
      body: { token },
    });
  }
}
