import { useEffect, useState } from "react";
import styled from "styled-components";
import groupBy from "lodash.groupby";
import "components/phases/css/VoiceRecorder.scss";
import { useModal } from "hooks/useModal";
import { InstructionsModal } from "components/common/Modals/instructions/index";
import Alphabets from "components/Listing/Alphabets";
import CurrentLetter from "components/elements/CurrentLetter";
import useDispatchLetter from "components/phases/Contexts/hooks/useDispatchLetter";
import useAlphabets from "components/phases/Contexts/hooks/useAlphabets";
import produce from "immer";
import { setCurrentLetter } from "components/phases/Contexts/actions/CurrentLetterActions";
import useDispatchCurrentLetter from "components/phases/Contexts/hooks/useDispatchCurrentLetter";

import {
  setLetters,
  setCurrentRevision,
  setCorrectedLetters,
  setNotRecordedLetters,
  setCorrected,
  setAllSent,
  setIsRemake,
} from "components/phases/Contexts/actions/LetterAcions";
import { letters } from "data/letters";
import UserDS from "datastore/UserDS";
import { ProfileCreationModal } from "components/common/Modals/profileCreation";
const Content = styled.div`
  display: grid;
  text-transform: none;
  background-color: white !important;
  grid-template-columns: 3fr 1fr;
  column-gap: 10px;
  row-gap: 15px;
  justify-content: space-between;
  align-items: center;
`;

function VoiceRecorder() {
  const dispatchCurrentLetter = useDispatchCurrentLetter();
  const dispatchLetter = useDispatchLetter();
  const { isCorrected, isRemake, currentRevision, notRecordedLetters } =
    useAlphabets();
  const [loading, setLoading] = useState<boolean>();
  const [profileAlreadyExists, setProfileAlreadyExists] =
    useState<boolean>(false);
  const [modalTodisplay, setModalTodisplay] = useState<string>("");
  const typeModal =
    modalTodisplay === "AllSent"
      ? "AllSent"
      : modalTodisplay === "FirstTime"
      ? "FirstTime"
      : modalTodisplay === "ProfileCreation"
      ? "ProfileCreation"
      : "";
  const {
    show: showInstructions,
    hide: hideInsctrusions,
    RenderModal: RenderInstructionsModal,
  } = useModal(typeModal);
  const {
    show: showProfileCreation,
    hide: hideProfileCreation,
    RenderModal: RenderProfileCreationModal,
  } = useModal(typeModal);
  useEffect(() => {
    UserDS.getCurrentUserProfil((profil: any) => {
      if (profil.language) setProfileAlreadyExists(true);
      UserDS.getAllVoiceRecord(profil.profilKey).then((data) => {
        // console.log(data);

        /**------------------------------- Get nonDuplicated letters----------------------------------- */
        const grouped = groupBy(
          data,
          (letter: any) => letter.recordKey.split("-")[0]
        );
        const notDuplicateLetters: any = [];
        //array for the last revisioned letters
        const lastRevisionedLetter: any = [];
        for (let letter in grouped) {
          let lastElement = grouped[letter].slice(-1);
          notDuplicateLetters.push(lastElement[0]);
          lastRevisionedLetter.push(grouped[letter].slice(-2)[0]);
        }
        /**--------------------------------------------------------------------------------------- */
        // console.log("notDuplicateLetters", notDuplicateLetters);

        // If the user visits the page for the first time
        if (notDuplicateLetters.length === 0) {
          setLoading(true);

          setModalTodisplay("FirstTime");
          setLetters({ dispatchLetter, payload: letters.letters });
          setNotRecordedLetters({
            dispatchLetter,
            payload: letters.letters,
          });
          setLoading(false);
        }
        // If the user have already recorded some letters or has everything corrected
        else {
          setLoading(true);
          setCurrentRevision({
            dispatchLetter,
            payload: Math.max.apply(
              Math,
              notDuplicateLetters.map(function (o: any) {
                return o.revision;
              })
            ),
          });
          const isRevisioned =
            notDuplicateLetters.every(
              (letter: any) =>
                letter.status !== undefined && letter.reason !== undefined
            ) || data.some((letter: any) => letter.revision > 1);

          //if everything is corrected
          if (isRevisioned) {
            setIsRemake({ dispatchLetter, payload: true });
            const isCorrectedLetters = notDuplicateLetters.some(
              (letter: any) =>
                // letter.revision <= currentRevision &&
                letter.status && letter.reason && letter.status !== undefined
            );
            ///=====================
            //check if letters are corrected
            if (isCorrectedLetters) {
              setCorrected({ dispatchLetter, payload: true });
              notDuplicateLetters.forEach((publishedLetter: any) => {
                letters.letters.forEach((defaultLetter: any) => {
                  if (
                    publishedLetter.recordKey.split("-")[0] ===
                    defaultLetter.recordKey
                  ) {
                    publishedLetter.xy = defaultLetter.xy;
                    publishedLetter.name = defaultLetter.name;
                  }
                });
              });
              setLetters({ dispatchLetter, payload: notDuplicateLetters });
              setCorrectedLetters({
                dispatchLetter,
                payload: notDuplicateLetters,
              });
              setLoading(false);
            }
            const notRecordedLetters = letters.letters.filter((el) => {
              return notDuplicateLetters.some((f: any): any => {
                return (
                  f.recordKey.split("-")[0] === el.recordKey &&
                  f.revision <= currentRevision &&
                  f.status &&
                  f.status !== "approved"
                );
              });
            });
            const allAreCorrect = notRecordedLetters.every(
              (letter: any) => letter.status === "approved"
            );
            //if all letters are correct or everything is recorded again
            if (notRecordedLetters.length === 0) {
              setLoading(true);
              if (!allAreCorrect) setModalTodisplay("AllSent");
            }
            //if some letters are not revisioned
            else {
              const updatedNotRecordedLetters = produce(
                notRecordedLetters,
                (draft) => {
                  draft.every((elem, index, arr) => {
                    arr[index].audioKey = "";
                    arr[index].isRecorded = false;
                    arr[index].isSent = false;
                    arr[index].status = "to work";
                    arr[index].fullAudioKey = "";
                    return arr;
                  });
                }
              );
              setNotRecordedLetters({
                dispatchLetter,
                payload: updatedNotRecordedLetters,
              });
              const filteredLetters = produce(letters.letters, (draft) => {
                notDuplicateLetters.forEach((recordedLetter: any) => {
                  draft.every((elem, index, arr) => {
                    if (
                      elem.recordKey ===
                        recordedLetter.recordKey.split("-")[0] &&
                      recordedLetter.revision <= currentRevision &&
                      recordedLetter.status === "approved"
                    ) {
                      arr[index].audioKey = recordedLetter.audioKey;
                      arr[index].fullAudioKey = recordedLetter.fullAudioKey;
                      arr[index].isRecorded = true;
                      arr[index].isSent = true;
                      arr[index].status = "approved";
                      arr[index].revision = recordedLetter.revision;
                    } else if (
                      elem.recordKey === recordedLetter.recordKey.split("-")[0]
                    ) {
                      arr[index].fullAudioKey = recordedLetter.fullAudioKey;
                      arr[index].status = recordedLetter.status;
                      arr[index].revision = recordedLetter.revision;
                    }
                    return arr;
                  });
                });
              });
              // console.log("filtered:", filteredLetters);
              setCorrectedLetters({ dispatchLetter, payload: filteredLetters });
              setLetters({ dispatchLetter, payload: filteredLetters });
              setCurrentLetter({
                dispatchCurrentLetter,
                letter: filteredLetters[0],
              });
              setLoading(false);
            }
          }
          //if some/all letters are recorded
          else {
            const notRecordedLetters = letters.letters.filter((el) => {
              return !notDuplicateLetters.some((f: any): any => {
                return f.recordKey.split("-")[0] === el.recordKey;
              });
            });
            // if some letters are recorded
            if (notRecordedLetters.length !== 0) {
              setNotRecordedLetters({
                dispatchLetter,
                payload: notRecordedLetters,
              });
              const filteredLetters = letters.letters;
              filteredLetters.forEach((defaultLetter: any) => {
                notDuplicateLetters.forEach((recordedLetter: any) => {
                  if (
                    defaultLetter.recordKey ===
                    recordedLetter.recordKey.split("-")[0]
                  ) {
                    defaultLetter.audioKey = recordedLetter.audioKey;
                    defaultLetter.isRecorded = true;
                    defaultLetter.isSent = true;
                  }
                });
              });

              setLetters({ dispatchLetter, payload: filteredLetters });
              setLoading(false);
            }
            // if all letters are recorded
            else {
              setModalTodisplay("AllSent");
              let allLettersArePublished = notDuplicateLetters.every(
                (letter: any) => letter.status !== undefined
              );
              const publishedLetters = notDuplicateLetters.filter(
                (letter: any) => letter.status !== undefined
              );

              if (publishedLetters.length !== 0 && allLettersArePublished) {
                setCorrected({ dispatchLetter, payload: true });
                publishedLetters.forEach((publishedLetter: any) => {
                  letters.letters.forEach((defaultLetter: any) => {
                    if (publishedLetter.recordKey === defaultLetter.recordKey) {
                      publishedLetter.xy = defaultLetter.xy;
                      publishedLetter.name = defaultLetter.name;
                    }
                  });
                });
                setLetters({ dispatchLetter, payload: publishedLetters });
                setCorrectedLetters({
                  dispatchLetter,
                  payload: publishedLetters,
                });
                setLoading(false);
              } else if (!allLettersArePublished) {
                const recorderLetters = letters.letters;
                recorderLetters.forEach((defaultLetter: any) => {
                  notDuplicateLetters.forEach((recordedLetter: any) => {
                    if (
                      defaultLetter.recordKey ===
                      recordedLetter.recordKey.split("-")[0]
                    ) {
                      defaultLetter.audioKey = recordedLetter.audioKey;
                      defaultLetter.fullAudioKey = recordedLetter.fullAudioKey;
                      defaultLetter.isRecorded = true;
                      defaultLetter.isSent = true;
                    }
                  });
                });
                setLetters({ dispatchLetter, payload: recorderLetters });
                setLoading(false);
              }
            }
          }
        }
        setLoading(false);
      });
    });
  }, [dispatchLetter, currentRevision, dispatchCurrentLetter]);

  // show allsent if all are recorded and sent
  useEffect(() => {
    if (
      loading !== undefined &&
      !loading &&
      !isRemake &&
      !isCorrected &&
      notRecordedLetters.length === 0
    ) {
      setModalTodisplay("AllSent");
      setAllSent({ dispatchLetter });
    }
  }, [notRecordedLetters, loading, dispatchLetter, isCorrected, isRemake]);
  useEffect(() => {
    if (isCorrected) {
      setModalTodisplay("AllCorrected");
    }
  }, [isCorrected, hideInsctrusions]);
  useEffect(() => {
    setTimeout(function () {
      showInstructions();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalTodisplay]);
  const handleClose = () => {
    hideInsctrusions();
    setModalTodisplay("ProfileCreation");
    showProfileCreation();
  };
  return (
    <Content id="audio-mainContent_Screen" className="ks-content ks-voice">
      {!profileAlreadyExists ? (
        <RenderProfileCreationModal>
          <ProfileCreationModal onClose={hideProfileCreation} />
        </RenderProfileCreationModal>
      ) : (
        ""
      )}
      {modalTodisplay === "AllSent" ? (
        <RenderInstructionsModal>
          <InstructionsModal
            title={"Tous vos audios ont été envoyé à notre équipe !"}
            description={`Vous recevrez une notification `}
            description2={`lorsque vos corrections seront arrivées.`}
            description3="Restez connectés !"
            className="all-sent"
            hasIcon
            onClose={hideInsctrusions}
          />
        </RenderInstructionsModal>
      ) : modalTodisplay === "AllCorrected" ? (
        <RenderInstructionsModal>
          <InstructionsModal
            title={"Vos corrections sont disponibles !"}
            primaryButtonTitle={`C’EST COMPRIS`}
            description={`N’hésitez pas à bien réécouter vos audios
            et à les comparer aux corrections. `}
            description2="Vous aurez également accès, pour erreur,
            à un lien direct vers la leçon concernée."
            allCorrected
            description3={`Exercez-vous, pratiquez, mais surtout n’abandonnez pas !`}
            onClose={hideInsctrusions}
          />
        </RenderInstructionsModal>
      ) : modalTodisplay === "FirstTime" ? (
        <RenderInstructionsModal>
          <InstructionsModal
            title={"Bienvenue sur l’interface d’enregistrement"}
            className="welcome"
            primaryButtonTitle={`C’EST COMPRIS`}
            description={`Cet écran te permettra d’enregistrer ta prononciation
          et de la faire valider à distance par un correcteur.
          Munie-toi d’un micro et prononce-bien clairement.
          `}
            description2={`Pas de stress ! `}
            description3={`
          Tu pourras t’y reprendre à plusieurs fois.`}
            onClose={handleClose}
          />
        </RenderInstructionsModal>
      ) : (
        ""
      )}
      <CurrentLetter />
      <Alphabets setModalTodisplay={setModalTodisplay} loading={loading} />
    </Content>
  );
}

export default VoiceRecorder;
