import BaseDS from "datastore/BaseDS";
import FileDS from "datastore/FileDS";
import UserDS from "datastore/UserDS";
import { useGA4React } from "ga-4-react";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { TChapter, TUserInfo } from "types";
import { unblockedUsers } from "utils/Constants";
import { UserContext } from "./UserContext";

export const ChapterContext = createContext<any>("");

export const chapterIdToRoot: any = {
  '1609229669522-0': '/alphabet',
  '1609229669522-1': '/readwrite',
  '1614578174766-1': '/arabnames',
  '1614578174766-2': '/names'
}

export const phaseIds = {
  "readwrite": "1609229669522",
  "vocabulary": "1614578174766",
  "dialogue": "1622868529412"
}

const removeAllDash = (value: string) => {
  return value ? value.replace(/-/g, '') : value;
}

const isPublished = (date: string | undefined) => {
  return date
    && parseInt(removeAllDash(date)) <= parseInt(removeAllDash(new Date().toISOString()));
};

const ChapterContextProvider = (props: any) => {
  const ga: any = useGA4React();
  const [chapterDefinition, setChapterDefinition] = useState<TChapter>();
  const [chapterId, setChapterId] = useState<string>();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [currentProfil, setCurrentStudentProfil] = useState<TUserInfo>();
  const [chaptersById, setChaptersById] = useState<any>({});
  const [phasesWithChapters, setPhasesWithChapters] = useState<any>();
  const [showDiploma, setShowDiploma] = useState<boolean>(false);
  const { isUserAuthenticated } = useContext(UserContext);

  useEffect(() => {
    BaseDS.getAllPhases().then((data) => {
      setPhasesWithChapters(data);
    });
  }, []);

  useEffect(() => {
    console.log("chapterId chapter", currentProfil);
    if (currentProfil && phasesWithChapters) {
      const chaptersByIdTemp: any = {};
      phasesWithChapters[2].chapter?.forEach((chapter: TChapter, index: number) => {
        chaptersByIdTemp[chapter.id] = {
          enabled: isPublished(chapter.publicationDate)
        }
        if (currentProfil[chapter.id] || index === 0 || (chaptersByIdTemp[chapter.id].enabled && isPublished(chapter.publicationDate))) {
          const sessionDefById: any = chapter.session?.reduce((map: any, obj) => {
            map[obj.id] = obj;
            return map;
          }, {});
          const nbSession = currentProfil[chapter.id] ? Object.keys(currentProfil[chapter.id])
            .filter(sessionKey => !sessionDefById[chapter.id + "-" + sessionKey]?.sessionType).length
            : ((unblockedUsers.indexOf(currentProfil?.profilKey) > -1 || unblockedUsers.indexOf(currentProfil?.name) > -1) ? chapter.numberOfSession : 1);

          chaptersByIdTemp[chapter.id] = {
            ...chaptersByIdTemp[chapter.id],
            type: chapter.type,
            completed: currentProfil[chapter.id]?.completed,
            label: nbSession + "/" + chapter.numberOfSession
          };
        }
      })
      setChaptersById(chaptersByIdTemp);
    }
  }, [currentProfil, phasesWithChapters]);

  const downloadFile = () => {
    if (!downloading) {
      setDownloading(true);
      let pdfFileKey: string = 'images/full_alphabet.pdf';
      if (chapterDefinition?.phaseType && chapterDefinition?.phaseType !== '') {
        pdfFileKey = 'images/' + chapterDefinition?.phaseType + '_' + chapterDefinition?.chapterKey + '.pdf';
      }
      const pdfFileName: string | undefined = pdfFileKey.split('/').pop();
      ga && ga.event('awlad', 'Download PDF ' + pdfFileName, 'engagement');
      setDownloading(false);
      let link: HTMLAnchorElement = document.createElement("a");
      link.download = pdfFileName ? pdfFileName : new Date().getTime() + '';
      link.href = FileDS.getURLFile(pdfFileKey);
      link.click();
    }
  }

  const setCurrentProfil = useCallback((profil: TUserInfo) => {
    setChaptersById({});
    setCurrentStudentProfil(profil);
  }, []);

  useEffect(() => {
    if(isUserAuthenticated) {
      UserDS.getCurrentUserProfil((profil: TUserInfo) => {
        setCurrentProfil(profil);
      });
    }
  }, [setCurrentProfil, isUserAuthenticated])

  return (
    <ChapterContext.Provider value={{
      chapterDefinition, setChapterDefinition, downloadFile, chaptersById,
      downloading, setDownloading, currentProfil, setCurrentStudentProfil, setCurrentProfil, chapterId, setChapterId,
      showDiploma, setShowDiploma
    }} >
      {props.children}
    </ChapterContext.Provider>
  );
};

export default ChapterContextProvider;
