import { useEffect, useState } from "react";
import './Login.scss'
import { ReactComponent as AwladLogo } from '../../assets/v3/awlad-school-logo.svg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Auth, Hub } from "aws-amplify";
import { ClipLoader } from "react-spinners";

import {
  AuthState,
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT,
} from "@aws-amplify/ui-components";
import useResponsiveSize from "components/utils/useResponsiveSize";

//needed for lambda trigger hoopowMigration
Auth.configure({
  authenticationFlowType: 'USER_PASSWORD_AUTH',
})

const Login = () => {
  const [loading, setLoading] = useState(false);
  const loaderSize = useResponsiveSize(30);

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const handleInputChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const signInClick = async () => {
    setLoading(true);
    try {
      await Auth.signIn(formData.email, formData.password);
      document.location.href = '/';
    }
    catch (error: any) {
      if (error.code && error.code === 'NotAuthorizedException') {
        toast.error('L’adresse e-mail ou le mot de passe est invalide.', { position: toast.POSITION.TOP_CENTER });
      } else if (error.code === "InvalidParameterException" && error.message.indexOf("Member must satisfy regular expression pattern") > -1) {
        toast.error("Mauvais format d'email.", { position: toast.POSITION.TOP_CENTER });
      } else {
        toast.error(error.code, { position: toast.POSITION.TOP_CENTER });
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    // Configuring the notification 
    toast.configure();
  }, []);

  const forgotPassword = () => {
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: AuthState.ForgotPassword,
      data: null,
    });
  }

  return (
    <div className='ks-login' slot="sign-in">
      <AwladLogo className={'ks-awlad-logo '} />
      <h1>SE CONNECTER</h1>
      <div className="ks-form-group">
        <label>email</label>
        <input type="text" name="email" placeholder="Tapez votre email ici" onChange={handleInputChange} />
      </div>
      <div className="ks-form-group">
        <label>mot de passe</label>
        <input type="password" name="password" placeholder="Tapez votre mot de passe ici" onChange={handleInputChange} />
      </div>
      <button className="ks-forgetBtn" onClick={forgotPassword}>Mot de passe oublié ?</button>
      <button className={"submitBtn"} onClick={signInClick} disabled={formData.email === '' || formData.password === '' || loading}>
        {loading ? (<div className="loader"><ClipLoader color='white' size={loaderSize} /></div>) : (<></>)}VALIDER
      </button>
    </div>
  );
}
export default Login;