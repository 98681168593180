import { SVGProps } from "Interfaces/SharedInterfaces";
import React from "react";

const Stop: React.FC<SVGProps> = (props: SVGProps) => {
  let {
    width = "52",
    height = "52",
    color = "#51aabc",
    disabled,
    onClick,
  } = props;

  return (
    <svg
      id="music-and-multimedia_1_"
      data-name="music-and-multimedia"
      width={width}
      height={height}
      onClick={disabled ? null : onClick}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      fill="none"
      viewBox="0 0 146 146"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="MICRO" transform="translate(-1641.361 -684)">
        <g
          id="Ellipse_104"
          data-name="Ellipse 104"
          transform="translate(1645.361 688)"
          fill={color}
          stroke={color}
          strokeWidth="4"
        >
          <circle cx="69" cy="69" r="69" stroke="none" />
          <circle cx="69" cy="69" r="71" fill="none" />
        </g>
        <rect
          id="Rectangle_650"
          data-name="Rectangle 650"
          width="59"
          height="59"
          rx="3"
          transform="translate(1685.36 728)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default Stop;
