import { Storage } from 'aws-amplify';

const responseMap: any = {};

export const blobToText = (b: Blob, callback: Function) => {
  const reader = new FileReader();
  reader.onload = function () {
    callback(reader.result);
  }
  reader.readAsText(b);
}

export default class ImageDataStore {

  static getImage(key: string, callback: Function, size?: string | number) {
    if (responseMap[key]) {
      console.log('from cache => ' + key);
      callback(responseMap[key]);
    } else {
      Storage.get(key, {
        customPrefix: {
          public: ''
        },
        download: true
      }).then((response: any) => {
        responseMap[key] = response;
        callback(response);
      }).catch((err) => {
        console.log(err);
        callback(undefined, err);
      });
    }
  }

  static getURLImage(key: string) {
    return process.env.REACT_APP_IMAGES_API_ENPOINT_BASE_URL + "/" + key;
  }

}

