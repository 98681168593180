import ImageDataStore from '../../datastore/ImageDataStore';

type ImageProps = {
  imageSrc?: string | null | undefined,
  imageAlt: string,
  className?: string
}

function Image(props: ImageProps) {
  const { imageSrc } = props;
  return (<>
    <img src={ImageDataStore.getURLImage(imageSrc || "") || ''} alt={props.imageAlt} className={props.className ? props.className : ''} />
  </>);
}

export default Image;
