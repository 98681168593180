import { useContext, useEffect, useState } from 'react';
import BaseDS from '../../datastore/BaseDS';
import { TChapter, TMainConfig, TUserInfo } from '../../types';
import SVG from '../elements/SVG';
import FileLoadEvent from '../utils/FileLoadEvent';
import { ReactComponent as Phase3Icon } from '../../assets/v2/homepage/phase3_icon.svg';
import { ReactComponent as HomeBtn } from '../../assets/v2/home-btn.svg';
import { ReactComponent as DialoguerIcon } from 'assets/v3/dialoguer-icon.svg';
import { ReactComponent as AwladLogo } from '../../assets/v3/awlad-school-logo.svg';
import './css/Phase3.scss';
import { Link, useHistory } from 'react-router-dom';
import { ChapterContext } from 'components/utils/ChapterContext';
import FileDS from 'datastore/FileDS';
import { UserContext } from 'components/utils/UserContext';
import UserDS from 'datastore/UserDS';

const phaseId = '1622868529412';
const removeAllDash = (value: string) => {
  return value ? value.replace(/-/g, '') : '999999999';
}

function Phase3LeftArea(props: any) {
  const history = useHistory();
  const [chapters, setChapters] = useState<TChapter[]>([]);
  const [selectedChaper, setSelectedChapter] = useState<string>('1');
  const { currentProfil, setCurrentProfil } = useContext(ChapterContext);
  const { isUserAuthenticated } = useContext(UserContext);

  const getSelectedChapterDetails = (chapterKey: string): TChapter => {
    if (!chapterKey) return chapters && chapters[0];
    else return chapters && chapters.filter(c => c.id === (phaseId + '-' + chapterKey))[0]
  }

  useEffect(() => {
    if (isUserAuthenticated) {
      UserDS.getCurrentUserProfil((profil: TUserInfo) => {
        setCurrentProfil(profil);
      });
    }
  }, [setCurrentProfil, isUserAuthenticated]);

  useEffect(() => {
    if (props.match.params.chapterKey) {
      setSelectedChapter(props.match.params.chapterKey);
    }
  }, [props.match.params.chapterKey])

  useEffect(() => {
    FileLoadEvent.triggerFileToLoad('loadingphase3');
    BaseDS.getMainConfigById(phaseId).then((data: TMainConfig) => {
      if (data.chapter) {
        const chaptersPublished = data.chapter;
        chaptersPublished.sort((a, b) => (parseInt(removeAllDash(a.publicationDate)) - parseInt(removeAllDash(b.publicationDate))));
        setChapters(chaptersPublished);
      }
      setTimeout(() => FileLoadEvent.triggerFileLoaded('loadingphase3'), 500);
    });
  }, []);

  console.log(currentProfil);

  return (
    <>
      {process.env.REACT_APP_FEATURE_V3 !== "1" ? (
        <>
          <div className={'ks-phase3-chapterSelected ks-phase3-' + selectedChaper}>
            <SVG src={getSelectedChapterDetails(selectedChaper)?.arabicTitleSVG} className='ks-phase3-chapterTitle-arabic' />
            <SVG src={getSelectedChapterDetails(selectedChaper)?.titleSVG} className='ks-phase3-chapterTitle-latin' />
            <Phase3Icon className='phase_icon' fill='white' />
          </div>
          <Link to='/'><HomeBtn /></Link>

          <div className='not-selected-chapter'>
            {chapters && chapters.filter(c => c.id !== getSelectedChapterDetails(selectedChaper).id).map((chapterTemp) => (
              <Link key={chapterTemp.id} to={`/phase3/${chapterTemp.chapterKey}`} className='ks-phase3-chapterBtn'>
                <SVG src={chapterTemp.titleSVG} className='ks-phase3-chapterBtn-latin' />
                <SVG src={chapterTemp.arabicTitleSVG} className='ks-phase3-chapterBtn-arabic' />
              </Link>
            ))}
          </div>
        </>
      ) : (<>
        <div className={'ks-awlad-avatar-student'}>
          <img src={FileDS.getHoopowURLFile(currentProfil?.iconAvatar)} alt={currentProfil?.name + " Avatar"} />
          <span>{currentProfil?.name || currentProfil?.profilKey}</span>
        </div>
        <AwladLogo className={'ks-awlad-logo'} onClick={() => history.push('/')} />
        <div className={'ks-phase3-chapterSelected ks-phase3-' + selectedChaper}>
          <DialoguerIcon />
        </div>

        <div className='ks-chapter-list'>
          {chapters && chapters.map((chapterTemp) => (
            <Link key={chapterTemp.id} to={`/phase3/${chapterTemp.chapterKey}`}
              className={'ks-phase3-chapterBtn ' + (chapterTemp.id === getSelectedChapterDetails(selectedChaper).id && "selected")}>
              {chapterTemp.name}
            </Link>
          ))}
        </div>
        <Link to='/'><HomeBtn /></Link>
      </>)}
    </>
  );
}

export default Phase3LeftArea;
