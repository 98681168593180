import { useContext, useEffect } from 'react';
import './TutoControl.scss';
import { ReactComponent as ArrowBtn } from '../../../assets/v2/sessions/tuto-arrow-btn.svg';
import { ReactComponent as PlayBtn } from '../../../assets/v2/sessions/play-tuto.svg';
import { ReactComponent as PauseBtn } from '../../../assets/v2/sessions/pause-tuto.svg';
import { SessionContext } from 'components/utils/SessionContext';
import { TutoContext } from 'components/utils/TutoContext';
import AudioPlayer from 'components/utils/AudioPlayer';

function TutoControl() {

  const { tuto } = useContext(SessionContext);
  const { currentStep, setCurrentStep, playTuto, setPlayTuto, audioRef, showTuto } = useContext(TutoContext);

  useEffect(() => {
    if (showTuto) {
      if (currentStep !== 0)
        audioRef.current.play();
      setPlayTuto(currentStep !== 0);
    }
  }, [currentStep, showTuto, setPlayTuto, audioRef])

  return (
    <div className={`ks-tuto-control ${showTuto && 'open'}`}>
      <div className={`ks-tuto-control-btn`}>
        <ArrowBtn className={`ks-tuto-control-left ks-clickable ${currentStep < 1 && 'ks-disabled'}`} onClick={() => {
          audioRef.current.stop();
          window.dispatchEvent(new Event('stopSound'));
          setCurrentStep((prev: number) => prev - 1)
        }} />
        {!playTuto ? (
          <PlayBtn className='ks-tuto-control-play ks-clickable' onClick={() => {
            audioRef.current.play();
            setPlayTuto(true)
          }} />
        ) : (
          <PauseBtn className='ks-tuto-control-pause ks-clickable' onClick={() => { 
            setPlayTuto(false); 
            window.dispatchEvent(new Event('stopSound'));
            AudioPlayer.audio.pause(); 
          }} />
        )}
        <ArrowBtn className={`ks-tuto-control-right ks-clickable ${(!tuto || currentStep === tuto.tutoSteps?.length - 1) && 'ks-disabled'}`} onClick={() => {
          audioRef.current.stop();
          window.dispatchEvent(new Event('stopSound'));
          setCurrentStep((prev: number) => prev + 1);
        }} />
      </div>
      <div className="ks-tuto-control-progress">TUTORIEL {currentStep + 1}/{(tuto && tuto.tutoSteps?.length) || 0}</div>
    </div>
  );
}

export default TutoControl;
