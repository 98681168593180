import React from "react";
import { SVGProps } from "Interfaces/SharedInterfaces";

const Send: React.FC<SVGProps> = (props: SVGProps) => {
  let {
    width = "52",
    height = "52",
    color = "#51aabc",
    disabled,
    onClick,
  } = props;
  let stroke = color;
  let fill = "#fff";
  disabled
    ? (color = "#B9C7CB") && (stroke = "dbe2e4") && (fill = "#DBE2E4")
    : (color = "#51aabc");
  return (
    <svg
      id="music-and-multimedia_1_"
      data-name="music-and-multimedia"
      width={width}
      height={height}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      fill="none"
      onClick={disabled ? null : onClick}
      viewBox="0 0 146 146"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SEND" transform="translate(-1641.361 -684)">
        <g
          id="Ellipse_104"
          data-name="Ellipse 104"
          transform="translate(1645.361 688)"
          fill={fill}
          stroke={stroke}
          strokeWidth={disabled ? "0" : "4"}
        >
          <circle cx="69" cy="69" r="69" stroke="none" />
          <circle cx="69" cy="69" r="71" fill="none" />
        </g>
        <g id="Group_6317" data-name="Group 6317">
          <g id="paper-plane" transform="translate(1675.986 717.35)">
            <path
              id="Path_2144"
              data-name="Path 2144"
              d="M60.707,59.225a2.272,2.272,0,0,1-.717-.118L1.5,39.117a2.222,2.222,0,0,1-.314-4.072L67.82.25a2.225,2.225,0,0,1,3.228,2.3L62.9,57.332a2.224,2.224,0,0,1-2.2,1.892ZM7.836,36.586,58.9,54.039l7.11-47.831Z"
              transform="translate(0 0.003)"
              fill={color}
            />
            <path
              id="Path_2145"
              data-name="Path 2145"
              d="M10.971,68.111A2.22,2.22,0,0,1,8.75,65.89V45.9a2.235,2.235,0,0,1,.6-1.516L50.063.706a2.223,2.223,0,0,1,3.252,3.032L13.192,46.775V59.118L20.6,49.029a2.222,2.222,0,0,1,3.58,2.633L12.763,67.208a2.234,2.234,0,0,1-1.792.9Z"
              transform="translate(17.162 0)"
              fill={color}
            />
          </g>
          <g
            id="Group_1512"
            data-name="Group 1512"
            transform="translate(-82.293 54.439) rotate(-8)"
          >
            <path
              id="Path_2146"
              data-name="Path 2146"
              d="M.542,441.376a1.851,1.851,0,0,0,2.617,0l12.218-12.217a1.851,1.851,0,1,0-2.618-2.618L.542,438.758a1.851,1.851,0,0,0,0,2.618Zm0,0"
              transform="translate(1645.361 548.559)"
              fill={color}
            />
            <path
              id="Path_2147"
              data-name="Path 2147"
              d="M112.759,426.541l-12.217,12.218a1.851,1.851,0,0,0,2.618,2.617l12.217-12.217a1.851,1.851,0,1,0-2.618-2.618Zm0,0"
              transform="translate(1563.871 548.559)"
              fill={color}
            />
            <path
              id="Path_2148"
              data-name="Path 2148"
              d="M.542,341.373a1.852,1.852,0,0,0,2.617,0l12.218-12.217a1.851,1.851,0,1,0-2.618-2.618L.542,338.755a1.851,1.851,0,0,0,0,2.618Zm0,0"
              transform="translate(1645.361 630.051)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Send;
