import { Trans } from 'react-i18next';
import './ImageText.scss';

function ImageText(props: { imgSrc: string, titleI18nKey?: string, descI18nKey: string, className?: string }) {

  return (
    <>
      <div className={`imageText ${props.className || ''}`}>
        <img src={props.imgSrc} alt={props.imgSrc} />
        <div className='imageText-text'>
          {props.titleI18nKey ? (
            <h2><Trans i18nKey={props.titleI18nKey} /></h2>
          ) : (<></>)}
          <p><Trans i18nKey={props.descI18nKey} /></p>
        </div>
      </div>
    </>
  );
}

export default ImageText;
