import { UserContext } from 'components/utils/UserContext';
import { useContext, useEffect } from 'react';
import TitledRoute from './TitledRoute';

const SecuredRoute = (props: any) => {
  const { isUserAuthenticated } = useContext(UserContext);
  useEffect(() => {
    if(isUserAuthenticated !== undefined && isUserAuthenticated === false) {
      document.location.href = '/';
    }
  })

  return (
    <>
      {isUserAuthenticated ? (<TitledRoute {...props}/>) : (<></>)}
    </>
  );
}

export default SecuredRoute;