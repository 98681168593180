import './Certificate.scss';
import { ReactComponent as CertificateTemplate } from '../../assets/certificate_template.svg';
import { ReactComponent as DownloadBtn } from '../../assets/certificate_download_btn.svg';
import { ReactComponent as PrintBtn } from '../../assets/certificate_print_btn.svg';
import { ReactComponent as IconClose } from '../../assets/icon_close_grey.svg';
import Modal from 'react-modal';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import printJS from "print-js";
import { useEffect, useState } from 'react';
import Sounds from '../utils/Sounds';
import { useGA4React } from 'ga-4-react';

function CertificateModal(props: any) {
  const ga: any = useGA4React();
  const { onModalClose, modalIsOpen, setIsOpen, chapterDetails, userInfo } = props;
  const [loading, setLoading] = useState<boolean>();

  function closeModal() {
    console.log(setIsOpen)
    setIsOpen(false);
    console.log('closeModal');
    if (onModalClose) onModalClose();
  }
  useEffect(() => {
    if(ga) ga.event('awlad', 'Certificate Displayed', 'engagement');
  }, [ga]);

  return (<>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      portalClassName={'ReactModalPortal ks-certificate'}
      contentLabel="Certificate"
      parentSelector={() => document.body}
      appElement={document.body}
    >
      <IconClose className='ks-certificate-close ks-clickable' onClick={() => {Sounds.playButtonSound(); closeModal()}}/>
      <div className='ks-certificateFrame'>
        <div id='certificate' className='ks-certificateMain'>
          <CertificateTemplate />
          <div className='ks-certificate-userName' >{userInfo?.name || userInfo?.profilKey}</div>
          <div className='ks-certificate-dateTime' >Décerné le {new Date(chapterDetails.completedDateTime).toLocaleDateString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric' })}</div>
        </div>
        <div className={'ks-certificateBtn' + (loading ? ' ks-disabled' : '')}>
          <div className='ks-printBtn ks-clickable' onClick={() => {
            if(loading) return;
            if(ga) ga.event('awlad', 'Certificate Printed', 'engagement');
            Sounds.playButtonSound();
            setLoading(true);
            const toPrint: any = document.querySelector('#certificate');

            html2canvas(toPrint, { scale: 3 }).then(canvas => {
              let pdf = new jsPDF('l', 'mm', 'a4');
              pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 298, 211, undefined, 'FAST');
              printJS(pdf.output("bloburi").toString());
              setLoading(false);
            });
          }}><PrintBtn /><div>IMPRIMER</div></div>
          <div className='ks-dwonloadBtn ks-clickable' onClick={() => {
            if(loading) return;
            if(ga) ga.event('awlad', 'Certificate Downloaded', 'engagement');
            Sounds.playButtonSound();
            setLoading(true);
            const toPrint: any = document.querySelector('#certificate');

            html2canvas(toPrint, { scale: 3 }).then(canvas => {
              let doc = new jsPDF('l', 'mm', 'a4');
              doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 298, 211, undefined, 'FAST');
              doc.save('certificate.pdf');
              setLoading(false);
            });
          }}><DownloadBtn /><div>TÉLÉCHARGER</div></div>
        </div>
      </div>
    </Modal>
  </>)
}

export default CertificateModal;
