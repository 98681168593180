import NotCorrectedCurrentLetter from "./NotCorrected";
import CorrectedCurrentLetter from "./Corrected";
import useAlphabets from "components/phases/Contexts/hooks/useAlphabets";

export default function CurrentLetter() {
  const { isCorrected } = useAlphabets();

  return (
    <>
      {isCorrected ? <CorrectedCurrentLetter /> : <NotCorrectedCurrentLetter />}
    </>
  );
}
