import { useGA4React } from "ga-4-react";
import { useEffect, useState } from "react";
import UserDS from "../../datastore/UserDS";
import { TUserInfo } from "../../types";
import Sounds from "../utils/Sounds";
import './ProfilForm.scss'

const ProfilForm = (props: { user?: TUserInfo, history: any, userRoot: boolean }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ga: any = useGA4React();
  const { user, history } = props;

  const [formData, setFormData] = useState({
    name: user ? (user.name || user.profilKey) : '',
    gender: user && user.gender ? user.gender : '',
    age: user && user.age ? user.age : ''
  });
  const handleChange = (e: any, prop: string) => {
    Sounds.playButtonSound();
    setFormData({
      ...formData,
      [prop]: e.target ? e.target.value : e
    });
  };

  const isValidForm = () => {
    return formData.name !== ''
  }

  useEffect(() => {
    setFormData({
      name: user ? user.profilKey.trim() : '',
      gender: user && user.gender ? user.gender : '',
      age: user && user.age ? user.age : ''
    });
  }, [user])

  return (
    <div className='ks-profilForm'>
      <div className="ks-profil">
        <form className='ks-form'>
          <div className="ks-form-group">
            <label className="form-label">Nom d’utilisateur :</label>
            <input type='text' placeholder="Tapez ici votre nom" onChange={(e) => handleChange(e, 'name')}
              className="ks-form-control" value={formData.name} disabled={user !== undefined && user.profilKey !== undefined}
            />
          </div>
          <div className="ks-form-group">
            <label className="ks-form-label">Genre :</label>
            <div className="ks-form-control-choice">
              <div className={`ks-btnChoice ks-clickable ${formData.gender === 'male' ? 'ks-selected' : ''}`} onClick={() => handleChange('male', 'gender')}>Masculin</div>
              <div className={`ks-btnChoice ks-clickable ${formData.gender === 'female' ? 'ks-selected' : ''}`} onClick={() => handleChange('female', 'gender')}>Féminin</div>
            </div>
          </div>
          <div className="ks-form-group">
            <label className="ks-form-label">Âge :</label>
            <div className="ks-form-control-choice">
              <div className={`ks-btnChoice ks-clickable ${formData.age === 'lessThan10' ? 'ks-selected' : ''}`} onClick={() => handleChange('lessThan10', 'age')}>- de 10 ans</div>
              <div className={`ks-btnChoice ks-clickable ${formData.age === 'moreThan10' ? 'ks-selected' : ''}`} onClick={() => handleChange('moreThan10', 'age')}>+ de 10 ans</div>
              <div className={`ks-btnChoice ks-clickable ${formData.age === 'adult' ? 'ks-selected' : ''}`} onClick={() => handleChange('adult', 'age')}>ADULTE</div>
            </div>
          </div>
        </form>
          <button className='mainBtn' disabled={!isValidForm() || loading} onClick={async () => {
            if(loading) return;
            if(!props.userRoot && ga && !user) ga.event('awlad', 'New profile', 'engagement');
            Sounds.playButtonSound();
            setLoading(true);
            await UserDS.saveProfil(formData.name, formData.gender, formData.age);
            setLoading(false);
            history.goBack();
          }}>VALIDER</button>
      </div>
    </div>
  );
}
export default ProfilForm;