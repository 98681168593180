import ImageText from 'components/elements/ImageText';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Section4.scss';

function Section4() {
  useTranslation();

  return (
    <>
      <section className="section4">
        <div className='desktop'>
          {[1, 2, 3].map(int => (<React.Fragment key={'imageText_' + int}>
            <ImageText
              imgSrc={`./images/preview${int}.png`}
              titleI18nKey={`homepage-preview${int}-title`}
              descI18nKey={`homepage-preview${int}-desc`}
              className={`preview${int}`} />
          </React.Fragment>))}
        </div>
      </section>
    </>
  );
}

export default Section4;
