import { SVGProps } from "Interfaces/SharedInterfaces";
import React from "react";

const Check: React.FC<SVGProps> = (props: SVGProps) => {
  const { width = "42", height = "42", color = "#ce6f3d" } = props;

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 72 72"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="FAUX" transform="translate(0.373 0.266)">
        <circle
          id="Ellipse_41"
          data-name="Ellipse 41"
          cx="36"
          cy="36"
          r="36"
          transform="translate(-0.373 -0.266)"
          fill={color}
        />
        <path
          id="Path_14670"
          data-name="Path 14670"
          d="M5.225-11.352,10-6.536a4.232,4.232,0,0,1,1.548,2.645A4.161,4.161,0,0,1,10-1.269,4.244,4.244,0,0,1,7.375.258,4.229,4.229,0,0,1,4.752-1.29L.022-6.063-4.752-1.29A4.227,4.227,0,0,1-7.353.258,4.315,4.315,0,0,1-9.976-1.269a4.147,4.147,0,0,1-1.569-2.623A4.232,4.232,0,0,1-10-6.536l4.773-4.816L-10-16.168a4.232,4.232,0,0,1-1.548-2.645,4.217,4.217,0,0,1,1.569-2.644,4.3,4.3,0,0,1,2.623-1.548,4.227,4.227,0,0,1,2.6,1.548L.022-16.641l4.73-4.816a4.229,4.229,0,0,1,2.623-1.548A4.229,4.229,0,0,1,10-21.457a4.232,4.232,0,0,1,1.548,2.644A4.232,4.232,0,0,1,10-16.168Z"
          transform="translate(35.627 47.734)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default Check;
