import React, { forwardRef, useState } from 'react';
import './WaladCard.scss';
import { ReactComponent as EmptyCard } from '../../assets/empty_walad_card.svg';
import SVG from '../elements/SVG';
import ImageModal from '../elements/ImageModal';
import { ClockLoader as Loader } from 'react-spinners';

const WaladCard = forwardRef((props: any, ref: any) => {
  const { cardDef, modalClassName } = props;
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const calculateLoaderSize = (optimalSize: number) => {
    const screen = document.getElementsByClassName('ks-mainFrame');
    return screen && screen.length ? optimalSize * screen[0].clientWidth / 1527 : optimalSize;
  }

  return (<>
    <div className='ks-waladCard'>
      {cardDef && cardDef.thumbnail ? (<>
        <SVG src={cardDef.thumbnail} onClick={() => setIsOpen(true)} style={{ display: isLoading ? 'none' : 'block' }}
          className='ks-clickable ks-waladCard-thumbnail' afterInjection={() => setIsLoading(false)} />
        {isLoading ? (<div className='ks-cardLoader ks-waladCard-thumbnail'><Loader size={calculateLoaderSize(75)} /></div>) : (<></>)}
        <ImageModal imageAudio={cardDef.imageAudio ? cardDef.imageAudio : ''} closeBtnName='FERMER'
          modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} onModalClose={() => { }} className={modalClassName} showRepeatBtn />
      </>) : (
          <EmptyCard />
        )}
    </div>

  </>)
})

export default WaladCard;