import React, { useState, useEffect, useCallback, useImperativeHandle } from 'react';
import { ReactComponent as IconSound } from '../../assets/icon_sound.svg';
import FileDS from '../../datastore/FileDS';
import SVG from './SVG';
import './SVGWithSoundIcon.scss'
import { BeatLoader } from 'react-spinners';
import ImageComponent from './ImageComponent';
import { TImageAudio } from '../../types';
import AudioPlayer from '../utils/AudioPlayer';

type SVGProps = {
  className?: string,
  disabled?: boolean,
  imageAudio: TImageAudio | number | string | undefined,
  onClick?: Function,
  autoPlay?: boolean,
  onAudioEnded?: Function,
  style?: any,
  hideAudioIcon?: boolean,
  forcePlay?: boolean,
  preload?: boolean,
  rootClassName?: string,
  afterInjection?: Function,
  onPlay?: Function
}

let retries = 0;

const SVGWithSoundIcon = React.forwardRef((props: SVGProps, ref: any) => {
  const { onAudioEnded, autoPlay, forcePlay, preload, onPlay } = props;
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [playing, setPlaying] = useState<boolean>(false);

  const { onClick } = props;

  useEffect(() => {
    if (props.imageAudio && (props.imageAudio as TImageAudio).audio) {
      setAudioUrl(FileDS.getURLFile((props.imageAudio as TImageAudio).audio));
    }
  }, [props.imageAudio, preload, ref]);

  const playSound = useCallback(() => {
    if ((forcePlay || !props.disabled) && audioUrl) {
      setPlaying(true);
      if (onPlay) onPlay();
      AudioPlayer.audio.onended = () => { setPlaying(false); if (onAudioEnded) onAudioEnded(); }
      AudioPlayer.play(audioUrl, (err: any) => {
        retries++;
        if (retries < 5) {
          console.log('Retries => ' + retries);
          playSound();
        } else {
          console.log('End of Retries');
          setPlaying(false); if (onAudioEnded) onAudioEnded();
          retries = 0
        }
      })
    }
  }, [props.disabled, forcePlay, audioUrl, onAudioEnded, onPlay]);


  useEffect(() => {
    if (props.imageAudio && (props.imageAudio as TImageAudio).audio && audioUrl !== '' && autoPlay) {
      playSound();
    } else if (autoPlay) {
      if (onAudioEnded) onAudioEnded();
    }
  }, [audioUrl, autoPlay, props.imageAudio, playSound, onAudioEnded]);

  const image: any = props.imageAudio && (props.imageAudio as TImageAudio).image ? (props.imageAudio as TImageAudio).image : (typeof props.imageAudio === 'string' || props.imageAudio instanceof String ? props.imageAudio : undefined);

  const onClickAction = (e: any) => {
    let stopEvent: boolean = false;
    if (onClick) {
      console.log('Play sound => ' + (props.imageAudio as TImageAudio).audio);
      stopEvent = onClick(e);
    }
    if (stopEvent !== true && !props.disabled) {
      playSound();
    }
  }

  useImperativeHandle(ref, () => ({
    play() {
      console.log('play => ' + audioUrl);
      playSound();
    },
    replay() {
      console.log('replay => ' + audioUrl);
      playSound();
    },
    click() {
      onClickAction(new Event('click'));
    },
    hasSound() {
      return audioUrl;
    },
    isPlaying() {
      console.log('playing => ' + playing);
      return playing;
    }
  }));

  const hasAudio = props.imageAudio && (props.imageAudio as TImageAudio).audio && (props.imageAudio as TImageAudio).audio !== '';

  return (
    <div ref={ref} style={{ position: 'relative', ...props.style }} onClick={onClickAction} className={(props.disabled ? 'ks-disabled' : (hasAudio ? ' ks-clickable' : '') + ' ' + props.rootClassName)}>
      {image && image.indexOf('.svg') > -1 ? (
        <SVG src={image} className={(props.className ? props.className : '') + ' ks-letter'} preload={preload} afterInjection={(error: any, svg: any) => {
          if (props.afterInjection) props.afterInjection(svg)
        }} />
      ) : (<>
        <ImageComponent imageKey={image} className={(props.className ? props.className : '')} />
      </>)}
      {hasAudio && !props.hideAudioIcon && (
        <div className="ks-icon-sound">
          {!playing ? (
            <IconSound className="ks-audio-playBtn" onClick={playSound} />
          ) : (
            <div className="ks-audio-playing">
              <BeatLoader />
            </div>
          )}
        </div>
      )}
    </div>
  );
})

export default SVGWithSoundIcon;
