import "./styles.scss";
import AlphabetLetter from "assets/Icons/AlphabetLetter";

import { Letter as LetterProps } from "components/phases/Contexts/interfaces";
import Polygon from "assets/Icons/Polygon";
export default function Letter({
  setLetter,
  currentLetter,
  letter,
}: {
  setLetter: any;
  currentLetter: LetterProps;
  letter: LetterProps;
}) {
  const { name, isRecorded, xy, isSent } = letter;
  const current = name === currentLetter.name;
  const handleClick = () => {
    if (!isSent) {
      setLetter(letter);
    }
  };
  return (
    <div
      className={`notcorrected-container ${isRecorded ? "isRecorded" : ""} ${
        current ? "current" : ""
      }`}
      onClick={handleClick}
    >
      <div className={`letter ${isSent ? "disabled" : ""}`}>
        <AlphabetLetter
          letter={name}
          xy={xy}
          color={isRecorded ? "white" : current ? "#51AABC" : "black"}
        />
      </div>
      {name === currentLetter.name ? (
        <Polygon className="current-arrow" color="#51AABC" />
      ) : null}
    </div>
  );
}
