import FileDS from 'datastore/FileDS';
import { useEffect, useState } from 'react';

function AsyncLink(props: { fileKey: string, children: any }) {
  const { fileKey, children } = props;
  const [fileUrl, setFileUrl] = useState<string>();

  useEffect(() => {
    if (fileKey) {
      setFileUrl(FileDS.getURLFile(fileKey));
    }
  }, [fileKey]);

  return (
    <a href={fileUrl}>{children}</a>
  );
}

export default AsyncLink;