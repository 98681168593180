
import { Player } from '@lottiefiles/react-lottie-player';
import animationBalloonData from 'assets/animations/balloon.json';
import { ReactComponent as AppIosIcon } from 'assets/standalone/app-ios.svg';
import { ReactComponent as HomepageImage } from 'assets/standalone/mobile-homepage-img.svg';
import { ReactComponent as AwladLogo } from 'assets/v3/awlad-school-logo.svg';
import { useLocation, useRouteMatch } from 'react-router-dom';
import './UserAnnouncement.scss';

function UserAnnouncement() {

  const routeMatchAudio = useRouteMatch("/audio");
  const routerMatchUnsubscribeProgressionEmails = useRouteMatch("/emails/progression/unsubscribe");
  const routerMatchUnsubscribeContact = useRouteMatch("/contactus");

  const isDisabled = routeMatchAudio || routerMatchUnsubscribeProgressionEmails || routerMatchUnsubscribeContact;

  const location = useLocation();

  return !isDisabled && (
    <div className={'hp-userAnnouncements ' + location.pathname.replace('/', '')}>
      <AwladLogo className='awladLogo' />
      <h2> Apprendre l’arabe de façon < br /> ludique et autonome. 100 % gratuit!</h2>
      <div className='importanteNote'>
        <HomepageImage />
        <div>
          <h3>NOTE IMPORTANTE !</h3>
          <p>La méthode en ligne est disponible uniquement sur ordinateur et tablette.<br />Merci de revenir nous voir mieux équipé : )</p>
        </div>
      </div>
      <h4>Cependant, si vous le souhaitez,<br />vous pouvez…</h4>
      <div className='links'>
        <a href="https://apple.co/3d41NUp" target="_blank" className='col' rel="noreferrer">
          <AppIosIcon />
          <p>TÉLÉCHARGER<br />LA VERSION<br />IPHONE</p>
        </a>
        <a href="https://awladschool.org" target="_blank" className='col col2' rel="noreferrer">
          <Player src={animationBalloonData} loop autoplay />
          <p>Visiter le site<br />De la fondation<br />awlad !</p>
        </a>
      </div>
    </div>
  ) || <></>;
}

export default UserAnnouncement;
