import { SVGProps } from "Interfaces/SharedInterfaces";
import React from "react";

const Check: React.FC<SVGProps> = (props: SVGProps) => {
  const { width = "42", height = "42", color = "#ce6f3d" } = props;

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 72 72"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="FAUX" transform="translate(0.373 0.266)">
        <circle
          id="Ellipse_41"
          data-name="Ellipse 41"
          cx="36"
          cy="36"
          r="36"
          transform="translate(-0.373 -0.266)"
          fill={color}
        />
        <g
          id="link_2_"
          data-name="link (2)"
          transform="translate(14.409 14.517)"
        >
          <g
            id="Group_6321"
            data-name="Group 6321"
            transform="translate(14.269 0)"
          >
            <g
              id="Group_6320"
              data-name="Group 6320"
              transform="translate(0 0)"
            >
              <path
                id="Path_14656"
                data-name="Path 14656"
                d="M197.412,2.913a9.945,9.945,0,0,0-14.065,0l-8.312,8.312a10.04,10.04,0,0,0,0,14.065,9.466,9.466,0,0,0,2,1.517l1.517-1.517c1-1,.645-2.159.627-3a4.8,4.8,0,0,1-.627-.518,5.026,5.026,0,0,1,0-7.033l8.312-8.312a4.973,4.973,0,0,1,7.033,7.033L188.4,18.956a14.312,14.312,0,0,1,.622,6.4c.024-.024.053-.039.076-.062l8.312-8.312A9.946,9.946,0,0,0,197.412,2.913Z"
                transform="translate(-172.159 0)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="Group_6323"
            data-name="Group 6323"
            transform="translate(0 14.982)"
          >
            <g id="Group_6322" data-name="Group 6322" transform="translate(0)">
              <path
                id="Path_14657"
                data-name="Path 14657"
                d="M25.935,182.293a9.467,9.467,0,0,0-2-1.517l-1.517,1.517c-1,1-.645,2.158-.627,3a4.791,4.791,0,0,1,.627.518,5.026,5.026,0,0,1,0,7.033L13.462,201.8a4.973,4.973,0,1,1-7.033-7.033l6.139-6.139a14.311,14.311,0,0,1-.622-6.4c-.024.024-.053.039-.077.063L2.913,191.25a9.946,9.946,0,1,0,14.065,14.065l8.956-8.956A10.034,10.034,0,0,0,25.935,182.293Z"
                transform="translate(0 -180.776)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Check;
