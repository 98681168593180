import { SVGProps } from "Interfaces/SharedInterfaces";
import React from "react";

const Home: React.FC<SVGProps> = (props: SVGProps) => {
  const { width = "24", height = "24", color = "#000", className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="home_3_"
        data-name="home (3)"
        d="M31.036,13.874l0,0L18.023.86a2.936,2.936,0,0,0-4.153,0l-13,13-.013.013a2.935,2.935,0,0,0,1.954,5q.045,0,.091,0h.519V28.46A3.44,3.44,0,0,0,6.852,31.9h5.09a.935.935,0,0,0,.934-.934V23.455a1.57,1.57,0,0,1,1.568-1.568h3a1.57,1.57,0,0,1,1.568,1.568v7.507a.934.934,0,0,0,.934.934h5.09a3.44,3.44,0,0,0,3.437-3.436V18.885h.481a2.937,2.937,0,0,0,2.078-5.012Zm0,0"
        transform="translate(0.001)"
      />
    </svg>
  );
};

export default Home;
