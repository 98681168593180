import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fr from './translations/fr.json'
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
    order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupLocalStorage: 'lng'
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: options,
        fallbackLng: 'fr',
        resources: {
            fr: {
                translation: fr
            }
        },
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;