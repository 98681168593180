import { forwardRef, useEffect, useRef, useState } from 'react';
import { ReactComponent as WaladCardsTitle } from '../../assets/walad_cards_title.svg';
import { ReactComponent as CloseBtn } from '../../assets/croix_carte_tresor.svg';
import { ReactComponent as NextBtn } from '../../assets/walad_cards_nextBtn.svg';
import { ReactComponent as PreviousBtn } from '../../assets/walad_cards_previousBtn.svg';
import Sounds from '../utils/Sounds';
import './WaladCardsModal.scss';
import WaladCard from './WaladCard';
import { TSession } from '../../types';
import BaseDS from 'datastore/BaseDS';
import { unblockedUsers } from 'utils/Constants';

const removeAllDash = (value: string) => {
  return value ? value.replace(/-/g, '') : value;
}

const WaladCardsModal = forwardRef((props: any, ref: any) => {
  const { waladCardsIsOpen, setWaladCardsIsOpen, chaptersDef, userProgress } = props;
  const [encouragements, setEncouragements] = useState<any[]>([]);
  const [challenges, setChallenges] = useState<any[]>([]);
  const [treasureHunt, setTreasureHunt] = useState<any[]>([]);
  const encouragementsCards = useRef<any>();
  const challengesCards = useRef<any>();
  const treasureHuntCards = useRef<any>();
  const [chapters, setChapters] = useState<any>(chaptersDef);

  useEffect(() => {
    if (!chapters) {
      BaseDS.getMainConfig().then((data) => {
        if (data.chapter) {
          const chaptersPublished = data.chapter.filter(c => c.publicationDate);
          chaptersPublished.sort((a, b) => (parseInt(removeAllDash(a.publicationDate)) - parseInt(removeAllDash(b.publicationDate))));
          setChapters(chaptersPublished);
        }
      });
    }
  }, [chapters]);


  useEffect(() => {
    if (userProgress && chapters) {
      const newEncouragements: any[] = [];
      const newChallenges: any[] = [];
      const newTreasureHunt: any[] = [];
      const firstChapterId = chapters[0].id;
      const secondChapterId = chapters[1].id;
      if ((unblockedUsers.indexOf(userProgress.profilKey) > -1 || unblockedUsers.indexOf(userProgress.name) > -1 || userProgress[firstChapterId]) && chapters[0]) {
        chapters[0].session.forEach((sessionDef: TSession) => {
          if (sessionDef.encouragementThumbnail &&
            (unblockedUsers.indexOf(userProgress.profilKey) > -1 || unblockedUsers.indexOf(userProgress.name) > -1 || (userProgress[firstChapterId][sessionDef.sessionKey]
              && userProgress[firstChapterId][sessionDef.sessionKey].length
              && userProgress[firstChapterId][sessionDef.sessionKey].filter((i: any) => i.state === 'in progress').length === 0))) {
            newEncouragements.push({
              thumbnail: sessionDef.encouragementThumbnail,
              imageAudio: sessionDef.mainEncouragement
            });
          }
          if (sessionDef.bonusThumbnail
            && (unblockedUsers.indexOf(userProgress.profilKey) > -1 || unblockedUsers.indexOf(userProgress.name) > -1 || (userProgress[firstChapterId]['bonus']
              && userProgress[firstChapterId]['bonus'][sessionDef.sessionKey]))) {
            newChallenges.push({
              thumbnail: sessionDef.bonusThumbnail,
              imageAudio: sessionDef.bonusImageAudio
            })
          }
        })
      }
      if ((unblockedUsers.indexOf(userProgress.profilKey) > -1 || unblockedUsers.indexOf(userProgress.name) > -1 || userProgress[secondChapterId]) && chapters[1]) {
        chapters[1].session.forEach((sessionDef: TSession) => {
          if (sessionDef.encouragementThumbnail
            && (unblockedUsers.indexOf(userProgress.profilKey) > -1 || unblockedUsers.indexOf(userProgress.name) > -1 || (userProgress[secondChapterId][sessionDef.sessionKey]
              && userProgress[secondChapterId][sessionDef.sessionKey].length
              && userProgress[secondChapterId][sessionDef.sessionKey].filter((i: any) => i.state === 'in progress').length === 0))) {
            newEncouragements.push({
              thumbnail: sessionDef.encouragementThumbnail,
              imageAudio: sessionDef.mainEncouragement
            });
          }
          if (sessionDef.bonusThumbnail
            && (unblockedUsers.indexOf(userProgress.profilKey) > -1 || unblockedUsers.indexOf(userProgress.name) > -1 || (userProgress[secondChapterId]['bonus']
              && userProgress[secondChapterId]['bonus'][sessionDef.sessionKey]))) {
            newTreasureHunt.push({
              thumbnail: sessionDef.bonusThumbnail,
              imageAudio: sessionDef.bonusGoodAnswerImageAudio
            })
            newTreasureHunt.push({
              thumbnail: sessionDef.bonusThumbnailB,
              imageAudio: sessionDef.bonusBadAnswerImageAudio
            })
          }
        })
      }
      setEncouragements(newEncouragements);
      setChallenges(newChallenges);
      setTreasureHunt(newTreasureHunt);
    }
  }, [userProgress, chapters]);

  const calculateLoaderSize = (optimalSize: number) => {
    const screen = document.getElementsByClassName('ks-mainFrame');
    return screen && screen.length ? optimalSize * screen[0].clientWidth / 1527 : optimalSize;
  }

  const scrollLeft = (scrollableInput: any) => {
    scrollableInput.current.scroll({
      left: scrollableInput.current.scrollLeft + calculateLoaderSize(975),
      behavior: 'smooth'
    });
  }

  const scrollRight = (scrollableInput: any) => {
    scrollableInput.current.scroll({
      left: scrollableInput.current.scrollLeft - calculateLoaderSize(975),
      behavior: 'smooth'
    });
  }

  return (<>
    {waladCardsIsOpen && (
      <div className='ks-waladCards-panel'>
        <CloseBtn className='ks-bonusEnigma-closeBtn ks-clickable' onClick={() => { Sounds.playButtonSound(); setWaladCardsIsOpen(false) }} />
        <div className='ks-waladCards-title'><WaladCardsTitle /></div>
        <div className='ks-waladCards-section'>
          <div className='ks-waladCards-section-title'><label>Les encouragements</label></div>
          <div className='ks-waladCards-section-cards'>
            <div className='ks-waladCards-section-cards-arrowPrevious ks-clickable' onClick={() => scrollRight(encouragementsCards)}><PreviousBtn /></div>
            <div ref={encouragementsCards} className='ks-waladCards-section-cardsWrapper'>
              {Array(28).fill(1).map((inc, index) => (
                <div key={'encouragement_' + index} className='ks-waladCards-section-card'>
                  <WaladCard key={'encouragement_' + index} cardDef={encouragements[index]} modalClassName='ks-sessionImageModal' />
                </div>
              ))}
            </div>
            <div className='ks-waladCards-section-cards-arrowNext ks-clickable' onClick={() => scrollLeft(encouragementsCards)}><NextBtn /></div>
          </div>
        </div>
        <div className='ks-waladCards-section'>
          <div className='ks-waladCards-section-title'><label>LES DÉFIS</label></div>
          <div className='ks-waladCards-section-cards'>
            <div className='ks-waladCards-section-cards-arrowPrevious ks-clickable' onClick={() => scrollRight(challengesCards)}><PreviousBtn /></div>
            <div ref={challengesCards} className='ks-waladCards-section-cardsWrapper'>
              {Array(10).fill(1).map((inc, index) => (
                <div key={'defi_' + index} className='ks-waladCards-section-card'>
                  <WaladCard key={'defi_' + index} cardDef={challenges[index]} modalClassName='ks-challenge' />
                </div>
              ))}
            </div>
            <div className='ks-waladCards-section-cards-arrowNext ks-clickable' onClick={() => scrollLeft(challengesCards)}><NextBtn /></div>
          </div>
        </div>
        <div className='ks-waladCards-section'>
          <div className='ks-waladCards-section-title'><label>LA CARTE AU trésor</label></div>
          <div className='ks-waladCards-section-cards'>
            <div className='ks-waladCards-section-cards-arrowPrevious ks-clickable' onClick={() => scrollRight(treasureHuntCards)}><PreviousBtn /></div>
            <div ref={treasureHuntCards} className='ks-waladCards-section-cardsWrapper'>
              {Array(30).fill(1).map((inc, index) => (
                <div key={'treasure_' + index} className='ks-waladCards-section-card'>
                  <WaladCard cardDef={treasureHunt[index]} modalClassName='ks-treasure' />
                </div>
              ))}
            </div>
            <div className='ks-waladCards-section-cards-arrowNext ks-clickable' onClick={() => scrollLeft(treasureHuntCards)}><NextBtn /></div>
          </div>
        </div>

      </div>
    )}
  </>)
})

export default WaladCardsModal;