// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.2.2 on 2020-06-21T03:35:23.743Z

const awsconfig = {
  aws_project_region: process.env.REACT_APP_AWS_REGION_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION_ID,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  Storage: {
    AWSS3: {
      region: process.env.REACT_APP_AWS_REGION_ID,
      bucket: process.env.REACT_APP_ASSETS_BUCKET_NAME,
      level: 'public',
    },
  },
  Analytics: {
    disabled: true,
  },
  aws_cloud_logic_custom: [
    {
      name: 'ApiGatewayRestApi',
      endpoint: process.env.REACT_APP_API_ENPOINT_BASE_URL,
      region: process.env.REACT_APP_AWS_REGION_ID,
    },
    {
      name: 'PlatformRestApi',
      endpoint: process.env.REACT_APP_HOOPOW_API_ENPOINT_BASE_URL,
      region: process.env.REACT_APP_AWS_REGION_ID,
    }
  ],
};

if (document.domain === "awlad-school.com") {
  awsconfig.cookieStorage = {
    domain: "awlad-school.com"
  }
} else if (process.env.REACT_APP_DOMAIN_NAME) {
  awsconfig.cookieStorage = {
    domain: process.env.REACT_APP_DOMAIN_NAME
  }
}

export default awsconfig;
