import React, { useReducer, createContext, Reducer } from "react";

import CurrentLetterReducer, {
  INITIAL_STATE,
} from "components/phases/Contexts/reducers/CurrentLetterReducer";

import type {
  DispatchCurrentLetter,
  StateCurrentLetter,
  CurrentLetterActions,
} from "components/phases/Contexts/interfaces";

type LetterProviderProps = { children: React.ReactNode };

export const CurrentLetterContext = createContext<
  StateCurrentLetter | undefined
>(undefined);
export const CurrentLetterDispatchContext = createContext<
  DispatchCurrentLetter | undefined
>(undefined);

const CurrentLetterProvider = ({ children }: LetterProviderProps) => {
  const [currentLetter, dispatchCurrentLetter] = useReducer<
    Reducer<StateCurrentLetter, CurrentLetterActions>
  >(CurrentLetterReducer, INITIAL_STATE);

  return (
    <CurrentLetterContext.Provider value={currentLetter}>
      <CurrentLetterDispatchContext.Provider value={dispatchCurrentLetter}>
        {children}
      </CurrentLetterDispatchContext.Provider>
    </CurrentLetterContext.Provider>
  );
};

export default CurrentLetterProvider;
