import React, { useReducer, createContext, Reducer } from "react";

import AlphabetsReducer, {
  INITIAL_STATE,
} from "components/phases/Contexts/reducers/LetterReducer";

import type {
  DispatchLetter,
  StateAlphabets,
  LetterActions,
} from "components/phases/Contexts/interfaces";

type LetterProviderProps = { children: React.ReactNode };

export const LetterContext = createContext<StateAlphabets | undefined>(
  undefined
);
export const LetterDispatchContext = createContext<DispatchLetter | undefined>(
  undefined
);

const LetterProvider = ({ children }: LetterProviderProps) => {
  const [letter, dispatchLetter] = useReducer<
    Reducer<StateAlphabets, LetterActions>
  >(AlphabetsReducer, INITIAL_STATE);

  return (
    <LetterContext.Provider value={letter}>
      <LetterDispatchContext.Provider value={dispatchLetter}>
        {children}
      </LetterDispatchContext.Provider>
    </LetterContext.Provider>
  );
};

export default LetterProvider;
