import { Auth } from "aws-amplify";
import { Letter } from "components/phases/Contexts/interfaces";
import { TFinalTestReport, TReport, TSession, TUserInfo } from "../types";
import BaseDS from "./BaseDS";
import FileDS from "./FileDS";

const localStorage: Storage = window.localStorage;

export default class UserDS {
  static setCurrentUserProfil = (profilKey: string) => {
    localStorage.setItem("currentProfil", profilKey);
  };

  static getProfils = () => {
    return BaseDS.getAllProfils();
  };

  static completeLastInterfaceAndEnableNext = (
    profilKey: string,
    sessionDef: TSession,
    interfaceKey: string,
    practiseResult?: TReport[] | TFinalTestReport
  ) => {
    const body = {
      chapterId: sessionDef.chapterId,
      sessionKey: sessionDef.sessionKey,
      interfaceKey: interfaceKey,
      practiseResult: practiseResult,
    };
    return BaseDS.completeInterfaceAndEnableNext(profilKey, body);
  };

  static completeBonus = (
    profilKey: string,
    chapterId: string,
    sessionId: string,
    body: any
  ) => {
    return BaseDS.completeBonus(profilKey, chapterId, sessionId, body);
  };

  static unblockNexChapter = (
    profilKey: string,
    chapterId: string
  ): Promise<TUserInfo> => {
    return BaseDS.unblockNexChapter(profilKey, chapterId);
  };

  static saveProfil = (
    profilKey: string,
    name: string,
    gender: string,
    level?: string,
    language?: string,
    age?: string,
    avatarId?: string
  ) => {
    return BaseDS.saveProfil(profilKey, name, gender, level, language, age, avatarId);
  };

  static deleteProfil = (
    profilKey: string
  ) => {
    return BaseDS.deleteProfil(profilKey);
  };

  static getCurrentUserProfil = (callback: Function) => {
    if (
      !localStorage.getItem("currentProfil") ||
      localStorage.getItem("currentProfil") === "null" ||
      localStorage.getItem("currentProfil") === ""
    ) {

      try {
        BaseDS.getRootProfil().then((rootProfil) => {
          console.log("getRootProfil no error ", rootProfil.profilKey);
          localStorage.setItem("currentProfil", rootProfil.profilKey);
          callback(rootProfil);
        }).catch((error) => {
          console.log("getRootProfil error ", error);
          callback(null, error)
        });
      } catch (error) {
        console.log("before getRootProfil error ", error);
      }
    } else {
      const profilKey: any = localStorage.getItem("currentProfil");
      BaseDS.getProfil(profilKey)
        .then((profil: any) => {
          if (profil) {
            callback(profil);
          } else {
            BaseDS.getRootProfil().then((rootProfil) => {
              localStorage.setItem("currentProfil", rootProfil.profilKey);
              callback(rootProfil);
            });
          }
        })
        .catch(() => {
          localStorage.removeItem("currentProfil");
          UserDS.getCurrentUserProfil(callback);
        });
    }
  };

  static getCurrentProfilKey = (callback: Function) => {
    if (
      !localStorage.getItem("currentProfil") ||
      localStorage.getItem("currentProfil") === "null" ||
      localStorage.getItem("currentProfil") === ""
    ) {

      try {
        BaseDS.getRootProfil().then((rootProfil) => {
          console.log("getRootProfil no error ", rootProfil.profilKey);
          localStorage.setItem("currentProfil", rootProfil.profilKey);
          callback(rootProfil.profilKey);
        }).catch((error) => {
          console.log("getRootProfil error ", error);
          callback(null, error)
        });
      } catch (error) {
        console.log("before getRootProfil error ", error);
      }
    } else {
      callback(localStorage.getItem("currentProfil"));
    }
  };

  static resetCurrentProfil = () => {
    return localStorage.removeItem("currentProfil");
  };

  static resetProfilSession = (
    profilKey: string,
    chapterId: string,
    sessionKey: number
  ) => {
    return BaseDS.resetProfilSession(profilKey, chapterId, sessionKey);
  };

  static getAllVoiceRecord = async (profilKey: string): Promise<any> => {
    return BaseDS.getAllVoiceRecord(profilKey);
  };

  static saveVoiceRecord = (letterDetails: Letter, callback: Function) => {
    UserDS.getCurrentUserProfil((profilUser: string | TUserInfo) => {
      const profilKey: any =
        typeof profilUser === "string" || profilUser instanceof String
          ? profilUser
          : profilUser.profilKey;
      fetch(letterDetails.audioKey).then((result) => {
        result.blob().then((file) => {
          const audioKey: string =
            new Date().toISOString() +
            "_" +
            profilKey +
            "_" +
            letterDetails.recordKey +
            ".mp3";
          const recordKey: string = letterDetails.recordKey;
          const revision: number | undefined = letterDetails.revision;
          Auth.currentUserCredentials().then((userCredentials: any) => {
            BaseDS.saveVoiceRecord(
              profilKey,
              audioKey,
              recordKey,
              revision,
              userCredentials.identityId
            ).then((data) => callback(data));
            FileDS.uploadFile(file, audioKey).then((audioKeyKey: any) => {
              console.log(audioKeyKey);
            });
          });
        });
      });
    });
  };
}
