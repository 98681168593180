import type {
  StateAlphabets,
  LetterActions,
} from "components/phases/Contexts/interfaces";
import produce from "immer";

export const INITIAL_STATE: StateAlphabets = {
  isCorrected: false,
  isLoading: false,
  isSending: false,
  allSent: false,
  loading: false,
  letters: [],
  currentRevision: 1,
  correctedLetters: [],
  isRemake: false,
  notRecordedLetters: [],
};

const AlphabetsReducer = (
  state: typeof INITIAL_STATE,
  action: LetterActions
): any => {
  switch (action.type) {
    case "SET_CORRECTED":
      return produce(state, (draft) => {
        draft.isCorrected = action.payload;
      });
    case "SET_LOADING":
      return produce(state, (draft) => {
        draft.loading = action.payload;
      });
    case "SET_CURRENT_REVISION":
      return produce(state, (draft) => {
        draft.currentRevision = action.payload;
      });
    case "SET_ALL_SENT":
      return produce(state, (draft) => {
        draft.allSent = true;
      });
    case "SET_IS_REMAKE":
      return produce(state, (draft) => {
        draft.isRemake = action.payload;
      });
    case "SET_NOT_RECORDED_LETTERS":
      return produce(state, (draft) => {
        draft.notRecordedLetters = action.payload;
      });
    case "UPDATE_NOT_RECORDED_LETTER":
      return produce(state, (draft) => {
        const index = draft.notRecordedLetters.findIndex(
          (letter) => letter.id === action.payload
        );
        draft.notRecordedLetters.splice(index, 1);
      });
    case "SET_LETTERS":
      return produce(state, (draft) => {
        draft.letters = action.payload;
      });
    case "SET_CORRECTED_LETTERS":
      return produce(state, (draft) => {
        draft.correctedLetters = action.payload;
      });
    case "RECORD_LETTER":
      return produce(state, (draft) => {
        const index = draft.letters.findIndex(
          (letter) => letter.id === action.payload.id
        );
        draft.letters[index].isRecorded = true;
        draft.letters[index].audioKey = action.payload.audioURL;
      });
    case "SEND_LETTER":
      return produce(state, (draft) => {
        const index = draft.letters.findIndex(
          (letter) => letter.id === action.payload
        );
        draft.letters[index].isSent = true;
      });
    case "SENDING_TRUE":
      return produce(state, (draft) => {
        draft.isSending = true;
      });
    case "SENDING_FALSE":
      return produce(state, (draft) => {
        draft.isSending = false;
      });
    default:
      throw new Error();
  }
};

export default AlphabetsReducer;
