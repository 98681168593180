import React, { useState } from 'react';

import './App.scss';
import ChapterMapComponent from './components/ChapterMap';
import MainLayout from './components/MainLayout';
import ProfilManagementCmp from './components/ProfilManagement';
import SessionLayout from './components/SessionLayout';
import Phase3Component from './components/phases/Phase3';
import Phase3V2Component from './components/phases/Phase3V2';

import { Redirect, BrowserRouter as Router, Switch } from "react-router-dom";
import LoadingPage from './components/elements/LoadingPage';

import AlphabetChapterLeftArea from 'components/AlphabetChapterLeftArea';
import ChapterTheme from 'components/ChapterTheme';
import HomepageV3 from 'components/HomepageV3';
import ReadWriteChapterLeftArea from 'components/ReadWriteChapterLeftArea';
import SessionLayoutV2 from 'components/SessionLayoutV2';
import SessionLayoutV2LeftArea from 'components/SessionLayoutV2LeftArea';
import AboutUs from 'components/elements/AboutUs';
import ContactUs from 'components/elements/ContactUs';
import {ContactUsMobile} from 'components/elements/ContactUsMobile';
import NewsletterRegistration from 'components/elements/NewsletterRegistration';
import { UnsubscribeProgressionEmailsPage } from 'components/elements/UnsubscribeProgressionEmailsPage';
import SecuredRoute from 'components/elements/common/internalrouter/SecuredRoute';
import UserAnnouncement from 'components/elements/common/phoneblocked/UserAnnouncement';
import HomepageLeftArea from 'components/layout/HomepageLeftArea';
import CurrentLetterProvider from "components/phases/Contexts/providers/CurrentLetterContext";
import LetterProvider from "components/phases/Contexts/providers/LetterContext";
import Phase3LeftArea from 'components/phases/Phase3LeftArea';
import Phase3V2LeftArea from 'components/phases/Phase3V2LeftArea';
import VoiceRecorderLeftArea from 'components/phases/VoiceRecorderLeftArea';
import { AudioPage } from 'components/screens/AudioPage';
import BlockerContextProvider from 'components/utils/BlockerContext';
import ChapterContextProvider from 'components/utils/ChapterContext';
import DialoguerContextProvider from 'components/utils/DialoguerContext';
import SessionContextProvider from 'components/utils/SessionContext';
import TutoContextProvider from 'components/utils/TutoContext';
import UserContextProvider from 'components/utils/UserContext';
import TitledRoute from './components/elements/common/internalrouter/TitledRoute';
import LayoutV2 from './components/layout/LayoutV2';
import VoiceRecorderComponent from './components/phases/VoiceRecorder';

export const phaseId = '1622868529412';

function App() {

  const [loadPageDisplayed, setLoadPageDisplayed] = useState<boolean>(true);
  const [showWaladCards, setShowWaladCards] = useState<boolean>(false);

  return (<>
    <UserContextProvider>
      <BlockerContextProvider>
        <ChapterContextProvider>
          <div className='ks-mainFrame'>
            <React.Suspense fallback={<LoadingPage />}>
              <Router>
                <UserAnnouncement />
                <Switch>
                  <TitledRoute path="/" pageName='Accueil' exact render={HomepageNewDesign} />
                  <TitledRoute path="/aboutus" pageName='A propos' exact render={AboutUsCmp} />
                  <TitledRoute path="/contactus" pageName='Nous contacter' exact render={ContactUsCmp} />
                  <TitledRoute path="/newsletter" pageName="Souscrire à la newsletter" exact render={NewsletterRegistrationCmp} />
                  <TitledRoute path="/audio/:path" pageName='Audio' exact render={Audio} />
                  <TitledRoute path="/emails/progression/unsubscribe" pageName='Se désabonner des e-mails de suivi' exact render={UnsubscribeProgressionEmails} />
                  <SecuredRoute path="/profils" pageName='Profiles' exact render={ProfilManagement} />
                  <SecuredRoute path="/phase3" pageName='Dialoguer' exact render={Phase3} />
                  <SecuredRoute path="/dialoguer" pageName='Dialoguer' exact render={(props: any) => Phase3V2(props, "lessons")} />
                  <SecuredRoute path="/dialoguer/lessons" pageName='Dialoguer' exact render={(props: any) => Phase3V2(props, "lessons")} />
                  <SecuredRoute path="/dialoguer/vocabulary" pageName='Dialoguer' exact render={(props: any) => Phase3V2(props, "vocabulary")} />
                  <SecuredRoute path="/v2/echange-audio" pageName='Echange audio' exact render={VoiceRecorder} />
                  <SecuredRoute path="/phase3/:chapterKey" pageName='Dialoguer' exact render={Phase3} />
                  <SecuredRoute path="/phase3/:chapterId/session/:sessionKey" pageName='Dialoguer' exact render={(props: any) => SessionV2(props, loadPageDisplayed, setLoadPageDisplayed)} />
                  <SecuredRoute path="/phase3/:chapterId/session/:sessionKey/:interfaceKey" pageName='Dialoguer' exact render={(props: any) => SessionV2(props, loadPageDisplayed, setLoadPageDisplayed)} />

                  <SecuredRoute path="/phase4/:chapterId/session/:sessionKey" pageName='Custom' exact render={(props: any) => SessionV2(props, loadPageDisplayed, setLoadPageDisplayed)} />

                  <SecuredRoute path="/chapter/:chapterId/session/:sessionKey" pageName='Session' exact render={Session} />
                  <SecuredRoute path="/alphabet" pageName="Chapitre L'Alphabet" exact render={(props: any) => ChapterAlphabetMap(props, loadPageDisplayed, showWaladCards, setShowWaladCards)} />
                  <Redirect from="/chapter/1609229669522-0" to="/alphabet" exact />
                  <SecuredRoute path="/readwrite" pageName='Chapitre Lire et Écrire' exact render={(props: any) => ChapterReadWriteMap(props, loadPageDisplayed, showWaladCards, setShowWaladCards)} />
                  <Redirect from="/chapter/1609229669522-1" to="/readwrite" exact />
                </Switch>
              </Router>
            </React.Suspense>
          </div>
        </ChapterContextProvider>
      </BlockerContextProvider>
    </UserContextProvider>
  </>);
}

export default App;

const Audio = () => {
  return (
    <AudioPage />
  )
}

const ProfilManagement = (props: any) => (<>
  <LoadingPage key='profilManagement' />
  <MainLayout className="ks-member">
    <ProfilManagementCmp {...props} />
  </MainLayout>
</>);

const HomepageNewDesign = (props: any) => (<>
  <LayoutV2 className="ks-homepageV2" location={props.location} isAuthenticated={true} leftArea={<HomepageLeftArea />}>
    <HomepageV3 {...props} />
  </LayoutV2>
</>);


const AboutUsCmp = (props: any) => (<>
  <LayoutV2 className="ks-aboutus ks-homepageV2" location={props.location} isAuthenticated={true} leftArea={<HomepageLeftArea />}>
    <AboutUs />
  </LayoutV2>
</>);

const ContactUsCmp = (props: any) => (<>
  <div className="md:hidden">
    <ContactUsMobile />
  </div>
  <div className="hidden md:block">
    <LayoutV2 className="ks-homepageV2" location={props.location} isAuthenticated={true} leftArea={<HomepageLeftArea />}>
      <ContactUs />
    </LayoutV2>
  </div>
</>);


const NewsletterRegistrationCmp = (props: any) => (<>
    <NewsletterRegistration />
</>);

const UnsubscribeProgressionEmails = (props: any) => (<>
    <UnsubscribeProgressionEmailsPage />
</>);

const Phase3 = (props: any) => (<>
  <LoadingPage key='phase3' />
  <ChapterTheme chapterId={phaseId + "-" + props.match.params.chapterKey}>
    <LayoutV2 className="ks-phase3" isAuthenticated={true} location={props.location} leftArea={<Phase3LeftArea {...props} />}>
      <Phase3Component {...props} />
    </LayoutV2>
  </ChapterTheme>
</>);

const Phase3V2 = (props: any, sectionType: string) => (<>
  <LoadingPage key='phase3' />
  <DialoguerContextProvider key={sectionType} sectionType={sectionType} >
    <ChapterTheme chapterId={sectionType}>
      <LayoutV2 className="ks-phase3 ks-phase3V2" isAuthenticated={true} location={props.location} leftArea={<Phase3V2LeftArea {...props} sectionType={sectionType} />}>
        <Phase3V2Component {...props} sectionType={sectionType} />
      </LayoutV2>
    </ChapterTheme>
  </DialoguerContextProvider>
</>);

const VoiceRecorder = (props: any) => (<>
  <LoadingPage key='voice-recorder' />
  <LetterProvider>
    <CurrentLetterProvider>
      <LayoutV2
        location={props.location}
        className="ks-phase3"
        isAuthenticated={true}
        leftArea={<VoiceRecorderLeftArea />}
      >
        <VoiceRecorderComponent {...props} />
      </LayoutV2>
    </CurrentLetterProvider>
  </LetterProvider>
</>);

const ChapterAlphabetMap = (props: any, loadPageDisplayed: boolean, showWaladCards: boolean, setShowWaladCards: Function) => (
  <LayoutV2 className="ks-chapterMap" isAuthenticated={true} location={props.location}
    leftArea={<AlphabetChapterLeftArea loadPageDisplayed={loadPageDisplayed} {...props} showWaladCards={showWaladCards} setShowWaladCards={setShowWaladCards} />}>
    <ChapterMapComponent {...props} chapterId="1609229669522-0" showWaladCards={showWaladCards} setShowWaladCards={setShowWaladCards} />
  </LayoutV2>
);

const ChapterReadWriteMap = (props: any, loadPageDisplayed: boolean, showWaladCards: boolean, setShowWaladCards: Function) => (
  <LayoutV2 className="ks-chapterMap" isAuthenticated={true} location={props.location}
    leftArea={<ReadWriteChapterLeftArea loadPageDisplayed={loadPageDisplayed} {...props} showWaladCards={showWaladCards} setShowWaladCards={setShowWaladCards} />}>
    <ChapterMapComponent {...props} chapterId="1609229669522-1" showWaladCards={showWaladCards} setShowWaladCards={setShowWaladCards} />
  </LayoutV2>
);

const Session = (props: any) => (
  <MainLayout className="ks-session">
    <TutoContextProvider>
      <SessionContextProvider>
        <SessionLayout {...props} />
      </SessionContextProvider>
    </TutoContextProvider>
  </MainLayout>
);


const SessionV2 = (props: any, loadPageDisplayed: boolean, setLoadPageDisplayed: Function) => {

  return (<>
    <ChapterTheme chapterId={props.match.params.chapterId}>
      <TutoContextProvider>
        <SessionContextProvider>
          <LayoutV2 className="ks-session" isAuthenticated={true} location={props.location}
            leftArea={<SessionLayoutV2LeftArea loadPageDisplayed={loadPageDisplayed} {...props} />}>
            <SessionLayoutV2 {...props} isV2 externalLoadPageDisplayed={loadPageDisplayed} />
          </LayoutV2>
        </SessionContextProvider>
      </TutoContextProvider>
    </ChapterTheme>
  </>
  )
};