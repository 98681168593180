import AlphabetLetter from "assets/Icons/AlphabetLetter";
import "./styles.scss";
import AudioActions from "components/elements/Audio";
import useCurrentLetter from "components/phases/Contexts/hooks/useCurrentLetter";
import { ClipLoader } from "react-spinners";
export default function NotCorrectedCurrentLetter() {
  const { currentLetter } = useCurrentLetter();
  return (
    <div className="notcorrected-wrapper">
      {!currentLetter?.name ? (
        <ClipLoader color="black" size={60} />
      ) : (
        <>
          <div
            className={`container ${
              currentLetter?.name === undefined ? "loading" : null
            }`}
          >
            {currentLetter?.name !== undefined ? (
              <div className="letter">
                <AlphabetLetter
                  letter={currentLetter?.name}
                  color="black"
                  xy={currentLetter?.xy}
                />
              </div>
            ) : (
              <ClipLoader color="black" size={60} />
            )}
          </div>
          <AudioActions currentLetter={currentLetter} />
        </>
      )}
    </div>
  );
}
