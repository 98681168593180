import { SVGProps } from "Interfaces/SharedInterfaces";
import React from "react";

const Micro: React.FC<SVGProps> = (props: SVGProps) => {
  let {
    width = "52",
    height = "52",
    color = "#51aabc",
    disabled,
    onClick,
  } = props;
  let stroke = color;
  let fill = "#fff";
  disabled
    ? (color = "#B9C7CB") && (stroke = "dbe2e4") && (fill = "#DBE2E4")
    : (color = "#51aabc");
  return (
    <svg
      id="music-and-multimedia_1_"
      data-name="music-and-multimedia"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 146 146"
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      onClick={disabled ? null : onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="MICRO" transform="translate(-1641.361 -684)">
        <g
          id="Ellipse_104"
          data-name="Ellipse 104"
          transform="translate(1645.361 688)"
          fill={fill}
          stroke={stroke}
          strokeWidth="4"
        >
          <circle cx="69" cy="69" r="69" stroke="none" />
          <circle cx="69" cy="69" r="71" fill="none" />
        </g>
        <g id="microphone" transform="translate(1687.693 713.663)">
          <path
            id="Path_2141"
            data-name="Path 2141"
            d="M21.353,54.509h0A16.061,16.061,0,0,1,5,38.157v-21.8A16.4,16.4,0,0,1,21.353,0h0A16.4,16.4,0,0,1,37.706,16.353v21.8A16.767,16.767,0,0,1,21.353,54.509Z"
            transform="translate(5.902)"
            fill={color}
          />
          <path
            id="Path_2142"
            data-name="Path 2142"
            d="M52.059,5V18.627c0,10.357-9.812,19.078-20.714,19.078h-2.18c-10.9,0-20.714-8.722-20.714-19.078V5A5.15,5.15,0,0,0,3,10.451v8.176c0,11.992,9.812,22.349,21.8,23.984V54.059c-16.353,0-13.627,10.9-13.627,10.9H49.333s2.725-10.9-13.627-10.9V42.612c11.992-2.18,21.8-11.992,21.8-23.984V10.451A5.15,5.15,0,0,0,52.059,5Z"
            transform="translate(-3 22.255)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default Micro;
