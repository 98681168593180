import { useContext } from "react";
import { LetterDispatchContext } from "components/phases/Contexts/providers/LetterContext";

const useDispatchLetter = () => {
  const context = useContext(LetterDispatchContext);

  if (context === undefined) {
    throw new Error("useDispatchUser must be used within a UserProvider");
  }

  return context;
};

export default useDispatchLetter;
