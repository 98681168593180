import React from "react";
import { SVGProps } from "Interfaces/SharedInterfaces";

const SendIcon: React.FC<SVGProps> = (props: SVGProps) => {
  let { width = "52", height = "52", color = "#fff" } = props;

  return (
    <svg
      id="music-and-multimedia_1_"
      data-name="music-and-multimedia"
      width={width}
      height={height}
      // viewBox="0 0 146 146"
      viewBox="0 0 46.137 57.734"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Group_6317"
        data-name="Group 6317"
        transform="translate(-1675.986 -717.349)"
      >
        <g id="paper-plane" transform="translate(1675.986 717.349)">
          <path
            id="Path_2144"
            data-name="Path 2144"
            d="M39.408,38.446a1.475,1.475,0,0,1-.465-.077L.977,25.393a1.443,1.443,0,0,1-.2-2.643L44.025.163a1.444,1.444,0,0,1,2.1,1.492L40.834,37.217a1.444,1.444,0,0,1-1.426,1.228ZM5.087,23.75,38.235,35.08,42.851,4.03Z"
            transform="translate(0 0.002)"
            fill={color}
          />
          <path
            id="Path_2145"
            data-name="Path 2145"
            d="M10.192,44.214A1.441,1.441,0,0,1,8.75,42.772V29.8a1.451,1.451,0,0,1,.386-.984L35.568.458a1.443,1.443,0,1,1,2.111,1.968L11.633,30.364v8.012l4.812-6.549a1.442,1.442,0,0,1,2.324,1.709L11.355,43.628a1.45,1.45,0,0,1-1.163.586Z"
            transform="translate(8.07 0.001)"
            fill={color}
          />
        </g>
        <g
          id="Group_1512"
          data-name="Group 1512"
          transform="translate(1678.664 752.95) rotate(-8)"
        >
          <path
            id="Path_2146"
            data-name="Path 2146"
            d="M.352,9.982a1.2,1.2,0,0,0,1.7,0L9.982,2.051a1.2,1.2,0,1,0-1.7-1.7L.352,8.282a1.2,1.2,0,0,0,0,1.7Zm0,0"
            transform="translate(0 12.016)"
            fill={color}
          />
          <path
            id="Path_2147"
            data-name="Path 2147"
            d="M8.283.352.352,8.283a1.2,1.2,0,0,0,1.7,1.7L9.982,2.051a1.2,1.2,0,0,0-1.7-1.7Zm0,0"
            transform="translate(12.016 12.016)"
            fill={color}
          />
          <path
            id="Path_2148"
            data-name="Path 2148"
            d="M.352,9.982a1.2,1.2,0,0,0,1.7,0L9.982,2.052a1.2,1.2,0,1,0-1.7-1.7L.352,8.283a1.2,1.2,0,0,0,0,1.7Zm0,0"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default SendIcon;
