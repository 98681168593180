import { Letter } from "components/phases/Contexts/interfaces";
import { DispatchCurrentLetter } from "components/phases/Contexts/interfaces";

type CurrentLetterArgs = {
  dispatchCurrentLetter: DispatchCurrentLetter;
  letter: Letter;
};
type UpdateCurrentLetterArgs = {
  dispatchCurrentLetter: DispatchCurrentLetter;
  payload: Letter;
};
export const setCurrentLetter = ({
  dispatchCurrentLetter,
  letter,
}: CurrentLetterArgs) => {
  try {
    dispatchCurrentLetter({ type: "SET_CURRENT_LETTER", payload: letter });
  } catch (err: any) {
    console.log("error", err?.response?.data);
  }
};

export const updateCurrentLetter = ({
  dispatchCurrentLetter,
  payload,
}: UpdateCurrentLetterArgs) => {
  try {
    dispatchCurrentLetter({
      type: "UPDATE_CURRENT_LETTER",
      payload: payload,
    });
  } catch (err: any) {
    console.log("error", err?.response?.data);
  }
};
