import React, { lazy } from 'react';
import './css/Screen.scss'
import { useEffect } from 'react';
import { useState } from 'react';
import BaseDS from '../../datastore/BaseDS';
import Modal from 'react-modal';
import ImageModal from '../elements/ImageModal';
import { TTutoStep } from 'types';

const importView = (subreddit: string) =>
  lazy(() =>
    import(`./${subreddit}`)
  );

const viewPerScreenType: any = {};
const alias: any = {
  'RecogniseLetter_screen-splitted': 'RecogniseLetter',
  'AssessmentRecogniseLetter': 'RecogniseLetter',
  'RecogniseWord': 'RecogniseLetter',
  'WriteName': 'WriteLetter',
  'FullAlphabetWithVowel_3inline': 'FullAlphabetWithVowel',
  'QamariaLetter': 'ShamsiaQamariaLetter',
  'ShamsiaLetter': 'ShamsiaQamariaLetter',
  'AssessmentRecogniseLetterInWord': 'RecogniseLetterInWord',
  'AssessmentFinalScreen': 'FinalScreen'
}

const tutoByName: any = {};

function Screen(props: any) {
  const { screenType, interfaceDef, setTuto, setCurrentScreenInfo, setShowTuto, tutoOpen, showScreenList, setCurrentScreen, goNextActivity } = props;
  const [screens, setScreens] = useState<any[]>();
  const [currentScreenIndex, setCurrentScreenIndex] = useState<number>(0);
  const [countCompleted, setCountCompleted] = useState<number>(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalEndIsOpen, setEndIsOpen] = useState(false);
  const [tutoLoading, setTutoLoading] = useState(false);

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  useEffect(() => {
    if (interfaceDef && interfaceDef.sessionId) {
      BaseDS.getAllScreensByInterfaceId(interfaceDef.sessionId, interfaceDef.interfaceKey)
        .then((data: any[]) => {
          setScreens(data);
        });
    }
  }, [interfaceDef]);

  const componentPromises = (properties: any) => {
    if (!screenType) return (<></>);
    const screenTypeTmp = alias[screenType] ? alias[screenType] : screenType
    let View: any = undefined;
    if (!viewPerScreenType[screenTypeTmp]) viewPerScreenType[screenTypeTmp] = importView(screenTypeTmp);
    View = viewPerScreenType[screenTypeTmp];
    return <View {...properties} />;
  }

  const onScreenChange = (screenIndex: number) => {
    if (screens && screens[screenIndex] && !tutoLoading) {
      setCurrentScreen(screenIndex);
      if (setCurrentScreenInfo) setCurrentScreenInfo(screens[screenIndex]);
      let tutoName = screens[screenIndex].tutoName;
      if (!tutoName) {
        for (let i = screenIndex; i >= 0; i--) {
          if (screens[i].tutoName) {
            tutoName = screens[i].tutoName;
            break;
          }
        }
      }
      if (!tutoName) {
        setTutoLoading(false);
        return;
      }
      if (!tutoByName[tutoName]) {
        setTutoLoading(true);
        BaseDS.getTutoByName(screens[screenIndex].tutoName)
          .then((data: any) => {
            const steps = data.step.length ? data.step.filter((s: TTutoStep) => s.name.indexOf("mobile_") === -1) : 0;
            const tuto = { tutoSteps: steps, displayTutoOnLoad: screens[screenIndex].displayTutoOnLoad, arrowType: data.arrowType };
            setTuto(tuto);
            if (screens[screenIndex].displayTutoOnLoad) {
              setShowTuto(undefined);
              console.log('loggs => tutoName', tutoName);
              setTutoLoading(false);
            } else {
              setTutoLoading(false);
            }
            tutoByName[tutoName] = tuto;
            setTuto(tuto);
          });
      } else {
        console.log('loggs => tutoName from cache', tutoName);
        setTutoLoading(false);
        setTuto(tutoByName[tutoName]);
      }
    }
  }

  const interfaceFinished = (...parameters: any) => {
    if (countCompleted < 2 && interfaceDef.repeatable) {
      setIsOpen(true);
      setCountCompleted(prev => ++prev);
    } else if (interfaceDef.type !== 'assessment') {
      const originalCallback = parameters[1];
      parameters[1] = () => {
        if (originalCallback) originalCallback();
        if (!parameters[2]) {
          if (interfaceDef.endMessageImageAudio && interfaceDef.endMessageImageAudio !== '') {
            setEndIsOpen(true);
          } else {
            goNextActivity();
          }
        }
      }
      console.log('currentDebug parameters', parameters)
      props.interfaceFinished(...parameters)

    } else {
      if (!parameters[1]) parameters[1] = goNextActivity;
      props.interfaceFinished(...parameters)
    }
  }

  return (<>
    {screens && (<>
      <React.Suspense fallback='loading' >
        {componentPromises({ ...props, tutoLoading, tutoOpen, screens, onScreenChange, currentScreenIndex, setCurrentScreenIndex, interfaceFinished, showScreenList })}
      </React.Suspense >
      {showScreenList && screens && (
        <div className='ks-screensList'>
          {screens.map((screenTemp: any, index: number) => (
            <div key={index} className={(index === currentScreenIndex ? 'ks-screenSelected' : '')}
              onClick={() => setCurrentScreenIndex(index)}>{index}</div>
          ))}
        </div>
      )}
      {tutoLoading && (
        <div className='ks-blockAllActions' onClick={(e) => { e.stopPropagation(); }}></div>
      )}
      <ImageModal key='repeatImageAudio' imageAudio={interfaceDef.repeatableImageAudio} modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen} onModalClose={() => setCurrentScreenIndex(0)} showRepeatBtn className='ks-sessionImageModal' />
      {interfaceDef.type !== 'exercise' ? (
        <ImageModal key='endImageAudio' imageAudio={interfaceDef.endMessageImageAudio ? interfaceDef.endMessageImageAudio : 'images/end_message.svg'}
          modalIsOpen={modalEndIsOpen} setIsOpen={setIsOpen} onModalClose={goNextActivity} className='ks-sessionImageModal' />
      ) : (
        <ImageModal key='endImageAudio' imageAudio={interfaceDef.endMessageImageAudio ? interfaceDef.endMessageImageAudio : ''}
          modalIsOpen={modalEndIsOpen} setIsOpen={setIsOpen} onModalClose={goNextActivity} className='ks-sessionImageModal' />
      )}
    </>)}
  </>);
}

export default Screen;
