import { useEffect, useState } from "react";
import AvatarSelector from "./AvatarSelector";
import PasswordField from "../auth/PasswordField";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserForm = (props: any) => {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: props.userData?.email || '',
    name: props.userData?.name || '',
    oldPassword: '',
    avatarId: props.userData?.avatarId || 'images/man_avatar-sample.svg',
    password: ''
  });

  const handleInputChange = (e: any, prop: string) => {
    setFormData({
      ...formData,
      [prop]: e.target.value
    });
  };

  const avatarSelected = (newAvatarId: string) => {
    setFormData({
      ...formData,
      avatarId: newAvatarId
    });
  }

  useEffect(() => {
    // Configuring the notification 
    toast.configure();
  }, []);

  return (<>
    <div className="ks-form-left">
      <form className='ks-form'>
        <div className="ks-form-group">
          <label className="form-label">Nom d'utilisateur :</label>
          <input type='text' autoComplete="off" placeholder="Tapez ici votre nom" value={formData.name || ''} onChange={(e: any) => handleInputChange(e, 'name')}
            className="ks-form-control"
          />
        </div>
        <div className="ks-form-group">
          <label className="ks-form-label">E-mail :</label>
          <input type='email' name='email' autoComplete="email" disabled={props.changePasswordField} placeholder="Tapez ici votre email" value={formData.email || ''} onChange={(e: any) => handleInputChange(e, 'email')}
            className="ks-form-control"
          />
        </div>
        <div className="ks-form-group">
          <label className="ks-form-label">{props.changePasswordField ? "Nouveau mot de passe :" : "Mot de passe :"}</label>
          <PasswordField placeholder="Tapez ici votre mot de passe" autoComplete="new-password" onChange={(e: any) => handleInputChange(e, 'password')} className="ks-form-control" />
        </div>
        {props.changePasswordField && (
          <div className="ks-form-group">
            <label className="ks-form-label">Ancien mot de passe :</label>
            <PasswordField placeholder="Tapez ici votre ancien mot de passe" autoComplete="password" onChange={(e: any) => handleInputChange(e, 'oldPassword')} className="ks-form-control" />
          </div>
        )}
      </form>
      <div className="ks-formBtn">
        <button className="mainBtn" onClick={() => {
          setLoading(true);
          props.submit(formData, (error: string) => {
            setLoading(false);
            if (error) {
              toast.error(error, { position: toast.POSITION.TOP_CENTER });
            } else {
              document.location.reload();
            }
          })
        }} type="submit" disabled={!props.isValidForm(formData) || loading}>
          VALIDER
        </button>
      </div>
    </div>
    {props.showAvatar && (
      <div className="ks-form-right">
        <AvatarSelector setAvatarId={avatarSelected} avatarId={formData.avatarId} />
      </div>
    )}
  </>
  );
}
export default UserForm;