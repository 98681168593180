import './css/MainLayout.scss';
import { ErrorBoundary } from 'react-error-boundary';
import { useEffect, useState } from 'react';
import AudioPlayer from './utils/AudioPlayer';
import Sounds from './utils/Sounds';

function ErrorFallback(props: { error: any }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{props.error.message}</pre>
    </div>
  )
}

function MainLayout(props: { children: any, className: string }) {
  const [blockActions, setBlockActions] = useState<boolean>();
  const [audioLoaded, setAudioLoaded] = useState<boolean>();

  useEffect(() => {
    const blockActionsState = (e: any) => {
      setBlockActions(e.type === 'playingSound');
    }
    window.addEventListener('playingSound', blockActionsState);
    window.addEventListener('stopSound', blockActionsState);
    Sounds.init();
    return () => {
      window.removeEventListener('playingSound', blockActionsState);
      window.removeEventListener('stopSound', blockActionsState);
    }
  }, []);

  useEffect(() => {
    const touchstart = () => {
      if (!audioLoaded) {
        console.log('First playing');
        AudioPlayer.play('dummy.mp3');
        setAudioLoaded(true);
      }
    }
    window.addEventListener('touchstart', touchstart);
    window.addEventListener('click', touchstart);
    return () => {
      window.removeEventListener('touchstart', touchstart);
      window.removeEventListener('click', touchstart);
    }
  }, [audioLoaded]);

  return (
    <>
      <div className={`ks-content ${props.className}`}>
        {blockActions && (
          <div className='ks-blockAllActions' onClick={(e) => { e.stopPropagation(); }}></div>
        )}
        <ErrorBoundary FallbackComponent={ErrorFallback} >
          {props.children}
        </ErrorBoundary>
      </div>
    </>
  );
}

export default MainLayout;
