import './css/HomepageNotAuthenticated.scss';
import { ReactComponent as AwladLogo } from 'assets/v3/awlad-school-logo.svg';
import { Player } from '@lottiefiles/react-lottie-player';
import animationBoatData from 'assets/animations/boat-awlad.json';
import animationWavesData from 'assets/animations/waves.json';
import { useContext } from 'react';
import { BlockerContext, steps } from './utils/BlockerContext';
import { Link } from 'react-router-dom';

function HomepageNotAuthenticated() {
  const { setBlock, setUnblockStep } = useContext(BlockerContext);

  return (<div className='hp-homepageNotAuthenticated'>
    <AwladLogo className={'ks-awlad-logo'} />
    <p>Apprendre l’arabe de façon<br />ludique et autonome</p>
    <button onClick={() => {
      setUnblockStep(steps.SIGNUP)
      setBlock(true);
    }}>S’INSCRIRE</button>
    <button onClick={() => {
      setUnblockStep(steps.SIGNIN)
      setBlock(true);
    }}>SE CONNECTER</button>
    <h1>100% gratuit!</h1>
    <h2>Awlad School est accessible à tous<br />grâce au soutien de nos partenaires !</h2>
    <Link to='/aboutus' className='ks-awlad-more' >EN SAVOIR PLUS</Link>
    <div className='boat'><Player loop src={animationBoatData} autoplay /></div>
    <div className='waves'><Player loop src={animationWavesData} autoplay /></div>
    <div className='water'></div>
  </div>);
}

export default HomepageNotAuthenticated;
