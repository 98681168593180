import React, { useContext, useEffect, useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { TSession } from '../../types';
import { ReactComponent as Stars } from '../../assets/v2/sessions/stars.svg';
import './css/Phase3.scss';
import { Link } from 'react-router-dom';
import SVG from 'components/elements/SVG';
import { ReactComponent as DefaultImage } from '../../assets/v2/sessions/default-homepage-image.svg';
import { ReactComponent as QuestionMark } from '../../assets/v3/question-mark-rounded.svg';
import { ReactComponent as NavArrow } from '../../assets/v2/sessions/nav-arrow.svg';
import { ReactComponent as NavArrowRight } from '../../assets/v2/sessions/nav-arrow-right.svg';
import { ReactComponent as IconRevision } from '../../assets/v2/sessions/icon-revision.svg';
import { ReactComponent as IconRevisionBlocked } from '../../assets/v2/sessions/icon-revision-blocked.svg';
import useResponsiveSize from 'components/utils/useResponsiveSize';
import { svgPerChapter } from 'components/ChapterTheme';
import { DialoguerContext } from 'components/utils/DialoguerContext';
import UnlockLesson from 'components/elements/Modal/UnlockLesson';
import IndexOverview from 'components/elements/Modal/IndexOverview';

function Phase3V2(props: any) {

  const [openUnlockLesson, setOpenUnlockLesson] = useState<boolean>(false);
  const [sessionToUnlock, setSessionToUnlock] = useState<string>();
  const { sessions, openIndexOverview, setOpenIndexOverview } = useContext(DialoguerContext);
  const scrollableDivRef = useRef<any>();
  const loaderSize = useResponsiveSize(80);

  const scrollToCurrentSession = (currentSessionId: string) => {
    scrollableDivRef.current?.scroll({
      left: (document.getElementById('sessionFrame_' + currentSessionId)?.offsetLeft || 110) - 110,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (sessions) {
      let currentSessionId = sessions[0].id;
      sessions.forEach((session: TSession) => {
        if (!session.isLocked) {
          currentSessionId = session.id
        }
      });
      scrollToCurrentSession(currentSessionId);
    }
  }, [sessions]);

  const calculateLoaderSize = (optimalSize: number) => {
    const screenDiv = document.getElementById('mainContentScreen');
    return screenDiv ? optimalSize * screenDiv.clientWidth / 1039 : optimalSize;
  }

  const scrollLeft = () => {
    scrollableDivRef.current.scroll({
      left: scrollableDivRef.current.scrollLeft - calculateLoaderSize(750),
      behavior: 'smooth'
    });
  };

  const scrollRight = () => {
    scrollableDivRef.current.scroll({
      left: scrollableDivRef.current.scrollLeft + calculateLoaderSize(750),
      behavior: 'smooth'
    });
  };

  return (
    <>
      {sessions && sessions?.length > 2 && (<>
        <NavArrow className="ks-chapter3-homepage-nav-left ks-clickable" onClick={scrollLeft} />
        <NavArrowRight className="ks-chapter3-homepage-nav-right ks-clickable" onClick={scrollRight} />
      </>)}
      <div ref={scrollableDivRef} id='ks-chapter3-homepage' className={'ks-chapter3-homepage scrollable'}>
        <div id='ks-chapter3-homepage-container' className={'ks-chapter3-homepage-container ' + ((!sessions) && 'loading')}>
          {sessions ? (<>
            {sessions?.map((sessionTemp: TSession) => (
              <div id={'sessionFrame_' + sessionTemp.id} key={sessionTemp.id} className={`ks-chapter3-homepage-session ${sessionTemp.sessionType === 'revision' && 'ks-session-revision'} ${(sessionTemp.isLocked || !sessionTemp.isPublished) && 'locked'}`}>
                <Stars className={`ks-chapter3-homepage-session-stars star-enabled-${sessionTemp.starsCount}`} />
                {sessionTemp.sessionType === 'revision' && (<>
                  {sessionTemp.isLocked || !sessionTemp.isPublished ? (
                    <IconRevisionBlocked className="ks-chapter3-homepage-session-revision-icon" />
                  ) : (<>
                    <IconRevision className="ks-chapter3-homepage-session-revision-icon" />
                  </>)}
                </>)}
                <SVG src={sessionTemp.desktopTitleImage} className="ks-chapter3-homepage-session-title" />
                {sessionTemp.sessionType !== 'revision' && (<>
                  {sessionTemp.desktopHomepageImage ? (
                    <SVG src={sessionTemp.desktopHomepageImage} className="ks-chapter3-homepage-session-image" />
                  ) : (
                    <DefaultImage className="ks-chapter3-homepage-session-image" />
                  )}
                </>)}
                {sessionTemp.isLocked && (svgPerChapter(props.match.params.chapterKey || "none")[1])}
                <div className='ks-chapter3-homepage-session-text'>
                  <div className='ks-chapter3-homepage-session-label'>{sessionTemp.objectifTitle}</div>
                  <div className='ks-chapter3-homepage-session-desc'>{sessionTemp.objectifDescription}</div>
                </div>
                <div className='ks-chapter3-homepage-session-btn-qm'>
                  <Link to={`/phase3/${sessionTemp.chapterId}/session/${sessionTemp.sessionKey}`} className={`mainBtn`} >
                    {!sessionTemp.isPublished ? 'Prochainement' : (sessionTemp.isLocked ? 'Bloquée' : 'Commencer')}
                  </Link>
                  {sessionTemp.isLocked && sessionTemp.isPublished ? (
                    <div className='ks-chapter3-homepage-session-qm' onClick={() => {
                      setSessionToUnlock(sessionTemp.id);
                      setOpenUnlockLesson(true);
                    }}>
                      <QuestionMark className='ks-chapter3-homepage-session-qmicon' />
                    </div>
                  ) : (<></>)}
                </div>
              </div>
            ))}
          </>) : (<><ClipLoader color='white' size={loaderSize} /></>)}
        </div>
      </div>
      {svgPerChapter()[0]}
      <UnlockLesson openUnlockLesson={openUnlockLesson} setOpenUnlockLesson={setOpenUnlockLesson} sessionId={sessionToUnlock} />
      <IndexOverview setSessionToUnlock={setSessionToUnlock} setOpenUnlockLesson={setOpenUnlockLesson} openIndexOverview={openIndexOverview} setOpenIndexOverview={setOpenIndexOverview} sessions={sessions} />
    </>
  );
}

export default Phase3V2;
