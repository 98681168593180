import { useContext, useEffect, useState } from 'react';
import './css/SessionLayoutV2LeftArea.scss';
import { SessionContext } from './utils/SessionContext';
import InterfaceNumbers from './elements/Screen/InterfaceNumbers';
import TimerDisplay from './elements/Screen/TimerDisplay';
import { ReactComponent as HelpBtn } from '../assets/v2/sessions/help-btn.svg';
import { ReactComponent as CloseHelpBtn } from '../assets/v2/sessions/close-tutos-btn.svg';
import { ReactComponent as NotionBtn } from '../assets/v2/sessions/notion-btn.svg';
import { ReactComponent as CloseNotionBtn } from '../assets/v2/sessions/notion-close-btn.svg';
import { ReactComponent as BackBtn } from '../assets/v2/sessions/back-btn.svg';
import { useHistory } from 'react-router';
import TutoControl from './elements/Screen/TutoControl';
import { TutoContext } from './utils/TutoContext';
import InnerHtml from './elements/InnerHtml';
import SVG from './elements/SVG';
import LessonNumbers from './elements/Screen/LessonNumbers';
import { ChapterContext } from './utils/ChapterContext';

function SessionLayoutV2LeftArea(props: any) {

  const { currentInterfaceIndex, tuto, sessionDefinition, currentScreenInfo } = useContext(SessionContext);
  const { showTuto, setShowTuto, closeTuto } = useContext(TutoContext);
  const { chaptersById } = useContext(ChapterContext);
  const [notionHelp, setNotionHelp] = useState<string>();
  const [notionImage, setNotionImage] = useState<string>();
  const [showNotion, setShowNotion] = useState<boolean>();

  const history = useHistory();
  useEffect(() => {
    console.log("currentScreenInfo", currentScreenInfo)
    if (currentScreenInfo && currentScreenInfo.currentNotion > -1 && sessionDefinition.interface[currentInterfaceIndex].notionsHelp) {
      setNotionHelp(sessionDefinition.interface[currentInterfaceIndex].notionsHelp[currentScreenInfo.currentNotion]);
      setNotionImage(sessionDefinition.interface[currentInterfaceIndex].notionsImage[currentScreenInfo.currentNotion]);
    } else if (currentScreenInfo?.notionsImageFull) {
      setNotionHelp(undefined);
      setNotionImage(currentScreenInfo.notionsImageFull);
    } else {
      setNotionHelp(undefined);
      setNotionImage(undefined);
      setShowNotion(false);
    }
  }, [sessionDefinition, currentInterfaceIndex, currentScreenInfo]);

  return (<>
    <div className="ks-session-left-area">
      {currentInterfaceIndex > -1 && (<>

        {tuto && tuto.tutoSteps && (
          <div className={`ks-session-left-area-black-overlay ${showTuto && 'tutoOpen'}`}>&nbsp;</div>
        )}
        {sessionDefinition.desktopLessonTitleImage ? (
          <SVG src={sessionDefinition.desktopLessonTitleImage} className="ks-session-title" />
        ) : (
          <SVG src={sessionDefinition.desktopTitleImage} className="ks-session-title" />
        )}
        {sessionDefinition.sessionType === 'revision' ? (<>
          <LessonNumbers />
        </>) : (<>
          <InterfaceNumbers />
          <TimerDisplay {...props} loadPageDisplayed={false} />
        </>)}
        <TutoControl />
        <div className="ks-session-left-bottom-btn">
          {notionImage && (<>
            {showNotion
              ? (<CloseNotionBtn className={`ks-session-help-btn`} onClick={() => setShowNotion(false)} />)
              : (<NotionBtn className={`ks-session-help-btn`} onClick={() => setShowNotion(true)} />
              )}
          </>)}
          {tuto && tuto.tutoSteps && (<>
            {showTuto
              ? (<CloseHelpBtn className={`ks-session-help-btn`} onClick={() => closeTuto()} />)
              : (<HelpBtn className={`ks-session-help-btn`} onClick={() => setShowTuto(true)} />
              )}
          </>)}
          <BackBtn className="ks-session-back-btn" onClick={() => {
            const chapterId = sessionDefinition.chapterId.split('-')[1];
            if (process.env.REACT_APP_INDEXATION === '1') {
              console.log("chapterId", chaptersById);
              const path = chaptersById["1622868529412-" + chapterId]?.type === 'interlude' ? 'vocabulary' : 'lessons';
              history.push('/dialoguer/' + path);
            } else {
              history.push('/phase3/' + chapterId);
            }
          }} />
        </div>
      </>)}
    </div>
    {showNotion && (
      <div className="ks-session-notion-popup">
        <div className={"ks-session-notion-frame " + (notionHelp || "ks-session-notion-frame-full")}>
          <SVG src={notionImage} className={(notionHelp ? "ks-session-notion-image" : "ks-session-notion-image-full")} />
          {notionHelp && (
            <InnerHtml text={notionHelp || ''} className="ks-session-notion-text" />
          )}
          <button className="mainBtn" type="submit" onClick={() => setShowNotion(false)}>
            FERMER
          </button>
        </div>
      </div>
    )}
  </>);
}

export default SessionLayoutV2LeftArea;
