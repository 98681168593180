import Lock from "assets/Icons/Lock";
import "./styles.scss";
import Home from "assets/Icons/Home";
import SendIcon from "assets/Icons/SendIcon";
const CustomButton = ({
  title,
  disabled,
  alphabets,
  allSent,
  home,
}: {
  title: string;
  disabled?: boolean;
  alphabets?: boolean;
  allSent?: boolean;
  home?: boolean;
}) => {
  return (
    <div
      className={`custom-button ${
        home || allSent || disabled ? "hasIcon" : ""
      } ${disabled ? "ks-disabled" : ""} ${home ? "ks-home" : ""} ${
        alphabets ? "alphabets" : ""
      } ${allSent ? "allSent" : ""}`}
    >
      {allSent ? <SendIcon className="send" /> : ""}
      {disabled ? <Lock className="lock" /> : ""}
      {home ? <Home className="home" /> : ""}
      <span>{title}</span>
    </div>
  );
};
export default CustomButton;
