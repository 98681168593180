import { Storage } from "aws-amplify";

const signedUrl: any = {};

export default class FileDS {

  static getUserSecuredUrlFile = (key: string, callback: Function) => {
    Storage.get(key, {
      customPrefix: {
        public: "",
      },
      bucket: process.env.REACT_APP_USERS_ASSETS_BUCKET_NAME,
    }).then((url: any) => {
      signedUrl[key] = url;
      callback(url);
    });
  };

  static getURLFile = (key: string) => {
    return process.env.REACT_APP_IMAGES_API_ENPOINT_BASE_URL + "/" + key;
  };

  static getHoopowURLFile = (key: string) => {
    return process.env.REACT_APP_HOOPOW_IMAGES_API_ENPOINT_BASE_URL + "/" + key;
  };

  static uploadFile = async (file: any, fileName: string) => {
    if (!file) return;
    const options: any = {
      level: "private",
      bucket: process.env.REACT_APP_USERS_ASSETS_BUCKET_NAME,
    };
    return Storage.put(`${fileName}`, file, options);
  };
}
