import { ReactComponent as MicrophoneIcon } from "assets/v2/homepage/microphone_icon.svg";
import { ReactComponent as GameIcon } from "assets/v2/homepage/game_icon.svg";
import { ReactComponent as JechangeDes } from "assets/v2/homepage/jechangedes.svg";
import { InstructionsModal } from "components/common/Modals/instructions/index";
import { useModal } from "hooks/useModal";
import { Link } from "react-router-dom";
import CustomButton from "components/common/CustomButton";
import "components/phases/css/VoiceRecorder.scss";
import { useHistory } from "react-router-dom";
import ArrowUp from "assets/Icons/ArrowUp";
import ArrowDown from "assets/Icons/ArrowDown";
import useAlphabets from "components/phases/Contexts/hooks/useAlphabets";

function VoiceRecorderLeftArea() {
  const { allSent, notRecordedLetters } = useAlphabets();
  const history = useHistory();

  const {
    show: showWarning,
    hide: hideWarning,
    RenderModal: RenderWarningModal,
  } = useModal();
  const handleShowWarning = () => {
    if (!allSent || notRecordedLetters.length !== 0) {
      showWarning();
    } else {
      history.push("/");
    }
  };
  return (
    <>
      <RenderWarningModal>
        <InstructionsModal
          title={"Attention"}
          primaryButtonTitle={`REPRENDRE`}
          className="warning-modal"
          hasTwoButtons
          secondaryButtonTitle={`CONTINUER PLUS TARD`}
          description={`Tes audios seront partagés à notre équipe
          de correcteurs uniquement lorsque tu auras
          envoyés tous les enregistrements.
              `}
          onClose={hideWarning}
        />
      </RenderWarningModal>
      <Link to="#" className="audio-section ks-clickable">
        <JechangeDes />
        <span className="audios">AUDIOS</span>
        <MicrophoneIcon className="phase_icon" />
      </Link>
      <div className="etapes">
        <CustomButton title="Alphabet" alphabets allSent={allSent} />
        <CustomButton title="Premier mots" disabled />
        <CustomButton title="Dialogue 01" disabled />
        <CustomButton title="Dialogue 02" disabled />
        <CustomButton title="Dialogue 03" disabled />
        <CustomButton title="Dialogue 04" disabled />
        <CustomButton title="Dialogue 05" disabled />
        <CustomButton title="Dialogue 06" disabled />
      </div>
      <div className="list-arrows">
        <ArrowUp />
        <ArrowDown />
      </div>
      <div className="home" onClick={handleShowWarning}>
        <CustomButton title="ACCUEIL" home />
      </div>
      <Link to="#" className="game-section ks-clickable">
        <span>AWLAD GAMES</span>
        <GameIcon className="phase_icon" />
      </Link>
    </>
  );
}

export default VoiceRecorderLeftArea;
