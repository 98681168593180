import ImageText from 'components/elements/ImageText';
import { Trans, useTranslation } from 'react-i18next';
import './Section5.scss';

function Section5() {
  const { t } = useTranslation();

  return (
    <>
      <section className="section5">
        <div className='section5-frame'>
          <h1 className='desktop'><Trans i18nKey={'homepage-section5-title'} /></h1>
          <ImageText imgSrc='./images/section5-img.jpg' descI18nKey="homepage-section5-desc" />
          <div className='section5-cta desktop'>
            <a href="https://awladschool.org/donation" className='donation' rel="noreferrer" target="_blank">{t('homepage-donation-btn')}</a>
            <a href="https://awladschool.org/partner" className='partner' rel="noreferrer" target="_blank">{t('homepage-partner-btn')}</a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Section5;
