import './ContactUs.scss'
import { ReactComponent as ContactUsImg } from '../../assets/standalone/contactus-img.svg';
import { useRef, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import BaseDS from 'datastore/BaseDS';

const ContactUs = () => {

  const lottieRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>();
  const [success, setSuccess] = useState<boolean>();
  const [formData, setFormData] = useState<any>({
    name: '',
    email: '',
    message: '',
  });
  const hideEnvelope = () => {
    lottieRef.current.style.display = 'none';
  }
  const showEnvelope = () => {
    lottieRef.current.style.display = 'block'
  }

  const handleInputChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const sendBtnHandler = async () => {
    setLoading(true);
    try {
      await BaseDS.sendEmailContactUs(formData);
      setSuccess(true);
    } catch (err: any) {
      alert(err.message);
    }
    setLoading(false);

  }

  const isValid = () => {
    return !formData.name && !formData.email;
  }
  return (
    <div className='ks-contactus'>
      <ContactUsImg />
      <div className='ks-contactus-form-bkg'>
      </div>
      <div className='ks-contactus-form'>
        <label>Nom&nbsp;<span>(obligatoire)</span></label>
        <input onChange={handleInputChange} name='name' autoComplete='name' placeholder="Votre nom ici" />
        <label>Email&nbsp;<span>(obligatoire)</span></label>
        <input onChange={handleInputChange} name='email' autoComplete='email' type="email" placeholder="Votre email ici" />
        <label>Votre message</label>
        <textarea onChange={handleInputChange} name='message' placeholder="Votre message ici" value={formData.message} onBlur={showEnvelope} onFocus={hideEnvelope} />
        <div className='messageLottie' ref={ref => lottieRef.current = ref}>
          <div className={success ? 'display' : 'hide'}><Player src="/lotties/message-sent.json" autoplay loop /></div>
          <div className={success ? 'hide' : 'display'}><Player src="/lotties/message.json" autoplay loop /></div>
        </div>
        <div className='ks-contactus-btn-mention'>
          <button disabled={isValid() || loading || success} onClick={sendBtnHandler}>{!success ? "ENVOYER" : "ENVOYÉ"}</button>
          <p><i>* Vos coordonnées resteront 100% confidentielles et ne seront ni partagées, ni revendues, à un tiers.</i></p>
        </div>
      </div>
    </div>
  );
}
export default ContactUs;