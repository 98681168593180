import BaseDS from "datastore/BaseDS";
import UserDS from "datastore/UserDS";
import { createContext, useEffect, useState } from "react";
import { TSession } from "types";

export const DialoguerContext = createContext<any>("");

const DialoguerContextProvider = (props: any) => {
  const [sessions, setSessions] = useState<TSession[]>();
  const [starsInfo, setStarsInfo] = useState<any>();
  const [openIndexOverview, setOpenIndexOverview] = useState<boolean>(false);

  useEffect(() => {
    setSessions(undefined);
    UserDS.getCurrentProfilKey((profilKey: string) => {
      BaseDS.getFullDialogueLessons(profilKey, props.sectionType).then((data: any) => {
        setSessions(data.sessions);
        setStarsInfo({
          nbStarsTotal: data.nbStarsTotal,
          nbStarsUser: data.nbStarsUser
        })
      });
    });
  }, [props.sectionType]);

  return (
    <DialoguerContext.Provider value={{
      sessions, starsInfo, openIndexOverview, setOpenIndexOverview
    }} >
      {props.children}
    </DialoguerContext.Provider>
  );
};

export default DialoguerContextProvider;
