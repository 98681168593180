import './css/HomepageV3.scss';
import { ReactComponent as DialoguerBtn } from 'assets/v3/dialoguer-btn.svg';
import { ReactComponent as RWBtn } from 'assets/v3/readwrite-btn.svg';
import { ReactComponent as LettersBtn } from 'assets/v3/letters-btn.svg';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { ChapterContext } from './utils/ChapterContext';
import { UserContext } from './utils/UserContext';
import HomepageNotAuthenticated from './HomepageNotAuthenticated';

function HomepageV3() {

  const { currentProfil } = useContext(ChapterContext);
  const { isUserAuthenticated } = useContext(UserContext);

  const phaseStarted = (phaseIdOrChapterId: string) => {
    return currentProfil && Object.keys(currentProfil).filter(k => k.indexOf(phaseIdOrChapterId) > -1).length;
  }

  return (<>
    {isUserAuthenticated ? (<>
      <div className='hp-homepageV3'>
        <div className='hp-homepageV3-top'>
          <Link to="/alphabet" className='phase-btn'><LettersBtn /><span>{(phaseStarted("1609229669522-0") && "Continuer") || "Commencer"}</span></Link>
          <Link to="/readwrite" className='phase-btn'><RWBtn /><span>{(phaseStarted("1609229669522-1") && "Continuer") || "Commencer"}</span></Link>
          <Link to={process.env.REACT_APP_INDEXATION !== '1' ? "/phase3/1" : "/dialoguer"} className='phase3 phase-btn'><DialoguerBtn /><span>{(phaseStarted("1622868529412-1") && "Continuer") || "Commencer"}</span></Link>
        </div>
        <div className='hp-homepageV3-bottom'>
          Awlad School est accessible gratuitement grâce au soutien de nos partenaires
          <a href='https://awladschool.org/'>EN SAVOIR PLUS</a>
        </div>
      </div>
    </>) : (<HomepageNotAuthenticated />)}
  </>);
}

export default HomepageV3;
