import React, { useState } from "react";
import FileDS from "datastore/FileDS";
import Tooltip from "components/common/Tooltip";
import SongOn from "assets/Icons/SongOn";
import Stop from "assets/Icons/Stop";

const AudioPlayerComponent = (props: {
  children: any;
  audioKey: any;
  disabled: boolean;
  tooltipContent: string;
  className?: string;
}) => {
  const { children } = props;
  const [audio, setAudio] = useState(new Audio());
  const [playing, setPlaying] = useState(false);

  React.useEffect(() => {
    if (props.audioKey) {
      FileDS.getUserSecuredUrlFile(props.audioKey, (url: string) => {
        setAudio(new Audio(url));
      })
    } else {
      setAudio(new Audio());
    }
  }, [props.audioKey]);

  const startPause = () => {
    if (playing) {
      audio.pause();
      setPlaying(false);
    } else {
      audio.onended = () => setPlaying(false);
      audio.play();
      setPlaying(true);
    }
  };

  return (
    <>
      <div className={props.className}>
        <div>{children}</div>
        {props.audioKey && (
          <div>
            <Tooltip
              content={props.tooltipContent}
              disabled={
                props.disabled ? props.disabled : props.audioKey ? false : true
              }
              className="player"
              v2={audio}
            >
              {props.audioKey ? (
                playing ? (
                  <Stop
                    disabled={props.disabled}
                    onClick={() => startPause()}
                  />
                ) : (
                  <SongOn
                    disabled={props.disabled}
                    onClick={() => startPause()}
                  />
                )
              ) : (
                <SongOn disabled />
              )}
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
};

export default function AudioPlayerInput(props: any) {
  return <AudioPlayerComponent {...props} />;
}
