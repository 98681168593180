import { useEffect, useState } from "react";
import DropdownComponent from "components/common/DropdownComponent";
import SelectComponent from "components/common/SelectComponent";
import React from "react";
// import { TUserInfo } from "types";
import UserDS from "datastore/UserDS";
import "./styles.scss";
interface ModalProps {
  className?: string;
  onClose?: any;
}
export const ProfileCreationModal: React.FC<ModalProps> = ({
  className,
  onClose,
}) => {
  const [state, setState] = useState<any>({
    profileKey: "",
    age: "",
    gender: "",
    language: "",
    niveau: "",
  });
  const [loading, setLoading] = useState<boolean>();
  const [profileKey, setProfileKey] = useState<string>("");
  // const [profils, setProfils] = useState<TUserInfo[]>();
  const handleChangeSelect = (evt: any) => {
    setState({ ...state, [evt.target.name]: evt.target.value });
  };
  const handleSubmit = async () => {
    setLoading(true);
    await UserDS.saveProfil(
      profileKey,
      state.gender,
      state.level,
      state.language,
      state.age
    );
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    UserDS.getCurrentUserProfil((profil: any) => {
      setProfileKey(profil.profilKey);
    });
  }, [profileKey]);
  return (
    <div className={`modal-box`}>
      <div className="modal-body">
        <div className="modal-title">Définition du profil étudiant</div>
        <div className={` modal-content`}>
          <p>
            Avant de commencer, merci de remplir les champs ci-dessous,
            <br />
            afin que le correcteur puisse s'adapter à ton profile. <br />
          </p>
        </div>
        <div className="profile-options">
          <SelectComponent
            label="âge"
            value={state.age}
            handleChange={handleChangeSelect}
            options={age}
            type="age"
          />
          <SelectComponent
            label="gender"
            value={state.gender}
            handleChange={handleChangeSelect}
            options={gender}
            type="gender"
          />
          <DropdownComponent
            label="langue natale"
            type="language"
            options={language}
            value={state.language}
            handleChange={handleChangeSelect}
            selectLabel="choisir un pays"
          />
          <DropdownComponent
            label="niveau"
            type="niveau"
            value={state.niveau}
            handleChange={handleChangeSelect}
            options={niveau}
            selectLabel="niveau en arabe"
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          className={`primary ${
            loading ||
            !state.age ||
            !state.gender ||
            !state.language ||
            !state.niveau
              ? "disabled"
              : null
          }`}
          disabled={loading}
          onClick={handleSubmit}
        >
          VALIDER
        </button>
      </div>
    </div>
  );
};
const age = ["Entre 6 et 10 ans", "Entre 10 et 15 ans", "Plus de 16 ans"];
const gender = ["Masculin", "Féminin"];
const language = [
  { label: "choisir un pays", value: "" },
  { label: "Arabe", value: "Arabe" },
  { label: "Francais", value: "Francais" },
  { label: "Englais", value: "Englais" },
];
const niveau = [
  { label: "niveau en arabe", value: "" },
  {
    label: "Je comprends un peu le darija",
    value: "Je comprends un peu le darija",
  },
  { label: "intermédiaire", value: "intermédiaire" },
  { label: "avancé", value: "avancé" },
];
