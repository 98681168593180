import './AvatarSelector.scss'
import { ReactComponent as ManIcon } from 'assets/v3/avatar/man-icon.svg';
import { ReactComponent as WomanIcon } from 'assets/v3/avatar/woman-icon.svg';
import { ReactComponent as GirlIcon } from 'assets/v3/avatar/girl-icon.svg';
import { ReactComponent as BoyIcon } from 'assets/v3/avatar/boy-icon.svg';
import { useEffect, useState } from 'react';
import FileDS from 'datastore/FileDS';
import { ClipLoader } from 'react-spinners';
import HooPowDS from './common/internalrouter/HooPowDS';

const avatarCache: any = {
}

const AvatarSelector = (props: { avatarId: string | undefined, setAvatarId: Function }) => {
  const [selectedAvatarGender, setSelectedAvatarGender] = useState<string>('man');
  const [avatarList, setAvatarList] = useState<any[]>();

  useEffect(() => {
    setAvatarList(undefined);
    if (!avatarCache[selectedAvatarGender]) {
      HooPowDS.fecthAvatarListByGender(selectedAvatarGender).then((data) => {
        avatarCache[selectedAvatarGender] = data;
        setAvatarList(data);
      })
    } else {
      setAvatarList(avatarCache[selectedAvatarGender]);
    }
  }, [selectedAvatarGender])

  const selectGender = (genderKey: string) => {
    if (genderKey !== selectedAvatarGender) {
      setSelectedAvatarGender(genderKey);
      props.setAvatarId();
    }
  }

  return (
    <div className="ks-avatar-selector">
      <div className="ks-avatar-selector__title">Choisissez votre image de profil</div>
      <div className="ks-avatar-selector__gender">
        <ManIcon className={`${selectedAvatarGender === 'man' && 'selected'}`} onClick={() => selectGender('man')} />
        <WomanIcon className={`${selectedAvatarGender === 'woman' && 'selected'}`} onClick={() => selectGender('woman')} />
        <GirlIcon className={`${selectedAvatarGender === 'girl' && 'selected'}`} onClick={() => selectGender('girl')} />
        <BoyIcon className={`${selectedAvatarGender === 'boy' && 'selected'}`} onClick={() => selectGender('boy')} />
      </div>
      <div className="ks-avatar-selector__list">
        {!avatarList ? (<div className="loader"><ClipLoader size={50} /></div>) : (<>
          {avatarList.map((avatarDef: any, index: number) => (
            <div key={index} className={`ks-avatar-selector__list-item ${props.avatarId === avatarDef.avatarKey && 'selected'}`}>
              <img alt={avatarDef.thumbnailKey} loading="lazy" src={FileDS.getHoopowURLFile(avatarDef.thumbnailKey)}
                className={``} onClick={() => {
                  props.setAvatarId(avatarDef.avatarKey)
                }} />
            </div>
          ))}
        </>)}
      </div>
    </div>
  );
}
export default AvatarSelector;