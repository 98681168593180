import { useCallback, useContext, useEffect, useRef } from 'react';
import './InterfaceNumbers.scss'
import { SessionContext } from 'components/utils/SessionContext';
import useResponsiveSize from 'components/utils/useResponsiveSize';
import { numberPad } from 'components/ChapterTheme';
import { unblockedUsers } from 'utils/Constants';

function InterfaceNumbers() {

  const numberPadRef = useRef<any>();
  const enabledNumbers = useRef<any>();
  const unlockLessons = localStorage.getItem('unlockLessons');
  const loaderSize = useResponsiveSize(80);

  const { sessionDefinition, currentInterfaceIndex, setNextInterfaceUnlock,
    profilSession, setCurrentInterfaceIndex, setNbLoadIndex, currentProfil } = useContext(SessionContext);

  const interfaceEnabled = useCallback((interfaceKey: number) => {
    return interfaceKey === 1 || unlockLessons === '1' || unblockedUsers.indexOf(currentProfil.profilKey) > -1 || unblockedUsers.indexOf(currentProfil.name) > -1
      || (profilSession && profilSession.filter((session: any) => interfaceKey === session.interfaceKey).length > 0);
  }, [profilSession, unlockLessons, currentProfil]);

  const hasStar = useCallback((interfaceTmp: any) => {
    const currentUserInterface = profilSession && profilSession.filter((session: any) => interfaceTmp.interfaceKey === session.interfaceKey);
    const practiseResult = currentUserInterface && currentUserInterface.length > 0 && currentUserInterface[0].practiseResult
    return practiseResult && practiseResult?.filter((pr: any) => pr.result).length === practiseResult.length;
  }, [profilSession]);

  const goToInterface = useCallback((index: number) => {
    setCurrentInterfaceIndex(index);
    setNbLoadIndex((prev: number) => prev + 1);
    setNextInterfaceUnlock(false);
  }, [setCurrentInterfaceIndex, setNbLoadIndex, setNextInterfaceUnlock]);

  useEffect(() => {
    if (numberPadRef.current) {
      if (!enabledNumbers.current) {
        enabledNumbers.current
          = sessionDefinition.interface.filter((i: any) => interfaceEnabled(i.interfaceKey)).map((i: any) => i.id);
        if (!enabledNumbers.current || !enabledNumbers.current.length) {
          enabledNumbers.current = [sessionDefinition.interface[0].id];
        }
      }
      sessionDefinition && sessionDefinition.interface.forEach((interfaceTemp: any, index: number) => {
        const svgElement: any = numberPadRef.current.getElementById('number_' + (index + 1));
        svgElement.removeAttribute("class");
        if (interfaceEnabled(interfaceTemp.interfaceKey)) {
          if (index === currentInterfaceIndex) {
            svgElement.classList.add('ks-interface-selected');
          } else {
            svgElement.classList.add('ks-interface-unlocked');
            svgElement.onclick = () => {
              goToInterface(index);
            }
          }
          if (hasStar(interfaceTemp)) svgElement.classList.add('ks-interface-success');
          else svgElement.classList.add('ks-interface-fail');

          if (!enabledNumbers.current.filter((i: any) => i === interfaceTemp.id).length) {
            svgElement.classList.add('ks-interface-new-enabled');
            enabledNumbers.current.push(interfaceTemp.id);
          }
        } else {
          numberPadRef.current.getElementById('number_' + (index + 1)).classList.add('ks-interface-locked');
        }
      });
    }
  }, [sessionDefinition, profilSession, currentInterfaceIndex, numberPadRef, goToInterface, interfaceEnabled, hasStar])


  return (
    <>
      {numberPad(numberPadRef, loaderSize)}
    </>
  );
}

export default InterfaceNumbers;
