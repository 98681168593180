import FileDS from "datastore/FileDS";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

export const AudioPage = () => {
    const { path } = useParams<any>();
    const audioRef = useRef<any>(null);

    const onFirstClick = () => {
        audioRef.current.play()
    }

    const onCanPlayThrough = () => {
        audioRef.current.play().catch(() => {
            console.log("actually cant!")
            window.addEventListener('click', onFirstClick, { once: true })
        })
    }

    useEffect(() => {
        if (!audioRef.current) return

        audioRef.current.src = FileDS.getURLFile("audios/" + path);
        audioRef.current.addEventListener("canplaythrough", onCanPlayThrough);

        return () => {
            audioRef.current.removeEventListener("canplaythrough", onCanPlayThrough);
            window.removeEventListener("click", onFirstClick);
        }
    }, [path, audioRef])

    return (
        <div style={{ height: "100vh", width: "100vw", backgroundColor: "#000", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <audio ref={audioRef} autoPlay controls controlsList="nodownload">
                Your browser does not support the audio element.
            </audio>
        </div>
    )
}