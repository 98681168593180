import JWTApi from 'datastore/APIWrapper';
import { TMainConfig } from '../../../../types'

const apiName = 'PlatformRestApi';

export default class HooPowDS {

  static getPlatformDef = async (): Promise<TMainConfig> => {
    return JWTApi.get(apiName, '/main/first');
  };

  static fecthAvatarListByGender = async (genderKey: string): Promise<any[]> => {
    return JWTApi.get(apiName, '/avatar/' + genderKey);
  };


}

