import { useContext } from "react";
import { CurrentLetterDispatchContext } from "components/phases/Contexts/providers/CurrentLetterContext";

const useDispatchCurrentLetter = () => {
  const context = useContext(CurrentLetterDispatchContext);

  if (context === undefined) {
    throw new Error(
      "useDispatchCurrentLetter must be used within a CurrentLetterProvider"
    );
  }

  return context;
};

export default useDispatchCurrentLetter;
