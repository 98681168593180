import { ReactComponent as AwladLogo } from 'assets/v3/awlad-school-logo.svg';
import BaseDS from 'datastore/BaseDS';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ContactUsImg from '../../assets/standalone/contactus-img.svg';
import './ContactUsMobile.scss';


export const ContactUsMobile = () => {
  const [loading, setLoading] = useState<boolean>();
  const [success, setSuccess] = useState<boolean>();

  const [formData, setFormData] = useState<any>({
    name: '',
    email: '',
    message: '',
  });

  const handleInputChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const sendBtnHandler = async () => {
    setLoading(true);
    try {
      await BaseDS.sendEmailContactUs(formData);
      setSuccess(true);
    } catch (err: any) {
      alert(err.message);
    }
    setLoading(false);

  }

  const isValid = () => {
    return !formData.name && !formData.email;
  }
  return (<>
    <div className="mobile-ks-contactus">
      <div className='mobile-ks-contactus-container'>
        <div className='mobile-ks-contactus-form'>
          <Link to="/">
            <AwladLogo className="ks-awlad-logo" />
          </Link>
          <label>Nom&nbsp;<span>(obligatoire)</span></label>
          <input onChange={handleInputChange} name='name' autoComplete='name' placeholder="Votre nom ici" />
          <label>Email&nbsp;<span>(obligatoire)</span></label>
          <input onChange={handleInputChange} name='email' autoComplete='email' type="email" placeholder="Votre email ici" />
          <label>Votre message</label>
          <textarea onChange={handleInputChange} name='message' placeholder="Votre message ici" value={formData.message} />
          <div className='mobile-ks-contactus-btn-mention'>
            <button disabled={isValid() || loading || success} onClick={sendBtnHandler}>{!success ? "ENVOYER" : "ENVOYÉ"}</button>
            <p><i>* Vos coordonnées resteront 100% confidentielles et ne seront ni partagées, ni revendues, à un tiers.</i></p>
          </div>
        </div>
      </div>
      <img src={ContactUsImg} className="mobile-ks-contactus-illustration" alt="" />
    </div>
  </>);
}