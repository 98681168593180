import { useState, useCallback, useEffect } from "react";

import "./styles.scss";
const Tooltip = (props: any) => {
  const { className, v2, content, disabled } = props;
  const [active, setActive] = useState(false);

  const showTip = useCallback(() => {
    setActive(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      showTip();
    }, 1000);
  }, [showTip]);

  return (
    <div className="tooltip-wrapper">
      {props.children}
      {active && !disabled && (
        <div
          className={`${className ? `${className}` : ""} ${
            v2 ? `v2 ${className}` : ""
          } ${disabled ? "disabled" : ""} tip bottom`}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
