import React, { useEffect, useState } from 'react';
import { ReactComponent as KalimaLogo } from '../assets/kalimat_logo_vec.svg';
import { ReactComponent as HooPowLogo } from '../assets/hoopow_logo.svg';
import { TMainConfig, TUserInfo } from '../types';
import BaseDS from '../datastore/BaseDS';
import './css/ProfilManagement.scss';
import ExitProfilFullScreen from './elements/ExitProfilFullScreen';
import ImageComponent from './elements/ImageComponent';
import FileLoadEvent from './utils/FileLoadEvent';
import UserDS from '../datastore/UserDS';
import ProfilForm from './elements/ProfilForm';
import { useHistory } from 'react-router-dom';

function ProfilManagement() {
  const [mainConfig, setMainConfig] = useState<TMainConfig>();
  const [profils, setProfils] = useState<TUserInfo[]>();
  const [currentProfil, setCurrentProfil] = useState<TUserInfo>();
  const history = useHistory();

  useEffect(() => {
    FileLoadEvent.triggerFileToLoad('loadingPMmainconfig');
    BaseDS.getMainConfig().then((data) => {
      setMainConfig(data);
      setTimeout(() => FileLoadEvent.triggerFileLoaded('loadingPMmainconfig'), 500);
    });
  }, []);

  useEffect(() => {
    FileLoadEvent.triggerFileToLoad('loadingPMCurrentUserProfil');
    UserDS.getCurrentUserProfil((profil: TUserInfo) => {
      setCurrentProfil(profil);
      UserDS.getProfils().then(setProfils);
      setTimeout(() => FileLoadEvent.triggerFileLoaded('loadingPMCurrentUserProfil'), 500);
    })
  }, []);

  return (
    <>
      <div className="ks-logo-chapters">
        <div className="ks-hoopow-logo"><HooPowLogo /></div>
        <div className="ks-logo"><KalimaLogo /></div>
        <div className='ks-mapTitle'>j’apprends à lire et écrire l’arabe </div>
        <div className="ks-profils">
          <div className="ks-mgntProfilForm ks-boxShadow">
            <span>UTILISATEUR 1</span>
            <ProfilForm user={profils ? profils[0] : undefined } history={history} userRoot/>
          </div>
          <div className="ks-mgntProfilForm ks-boxShadow">
            <span>UTILISATEUR 2</span>
            <ProfilForm user={profils && profils.length > 1 ? profils[1] : undefined } history={history} userRoot={false}/>
          </div>
        </div>
        <ExitProfilFullScreen profilName={currentProfil?.name || currentProfil?.profilKey || ''} destination='/' />
      </div>
      <div className="ks-ad-banner">
        <ImageComponent profilName={currentProfil?.name || currentProfil?.profilKey || ''} imageKey={mainConfig?.advertImage} imageRatio={216 / 1526} />
      </div>
    </>
  );
}

export default ProfilManagement;
