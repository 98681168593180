import type {
  StateCurrentLetter,
  CurrentLetterActions,
} from "components/phases/Contexts/interfaces";
import produce from "immer";

export const INITIAL_STATE: StateCurrentLetter = {
  currentLetter: {},
};

const AlphabetsReducer = (
  state: typeof INITIAL_STATE,
  action: CurrentLetterActions
): any => {
  switch (action.type) {
    case "SET_CURRENT_LETTER":
      return produce(state, (draft) => {
        draft.currentLetter = action.payload;
      });
    case "UPDATE_CURRENT_LETTER":
      return produce(state, (draft) => {
        draft.currentLetter = action.payload;
      });
    default:
      throw new Error();
  }
};

export default AlphabetsReducer;
