import "./styles.scss";
import AlphabetLetter from "assets/Icons/AlphabetLetter";
import { Letter as LetterProps } from "components/phases/Contexts/interfaces";
import Polygon from "assets/Icons/Polygon";
export default function CorrectedLetter({
  setLetter,
  currentLetter,
  letter,
}: {
  setLetter: any;
  currentLetter: LetterProps;
  letter: LetterProps;
}) {
  const { name, status, xy } = letter;
  const correct = status === "approved";
  const color = correct ? "#5A9F6D" : "#CE6F3D";
  const current = name === currentLetter.name;
  return (
    <div
      className={`corrected-container ${correct ? "true" : "false"} ${
        current ? "isCurrent" : ""
      }`}
      onClick={() => setLetter(letter)}
    >
      <div className={`letter`}>
        <AlphabetLetter
          letter={name}
          xy={xy}
          color={current ? "white" : color}
        />
      </div>

      {name === currentLetter.name ? (
        <Polygon
          className="current-arrow"
          color={`${correct ? "#5A9F6D" : "#CE6F3D"}`}
        />
      ) : null}
    </div>
  );
}
