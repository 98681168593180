import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import './LoadingPage.scss';
import animationData from '../../assets/waiting.json';
import { ReactComponent as Logo } from '../../assets/kalimat_logo_vec.svg';
import { Player } from '@lottiefiles/react-lottie-player';

let fileToLoad: any = {};
let fileLoaded: any = {};

const LoadingPage = forwardRef((props: any, ref: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [imageFinishToLoad, setImageFinishToLoad] = useState<boolean>(false);
  const { setLoadPageDisplayed, afterLoad, maxLoadingTimeInSec } = props;

  useEffect(() => {
    console.log('set Timeout');
    const timer = setTimeout(() => { setLoading(false); console.log('From timeout !!!'); }, maxLoadingTimeInSec || 10000);

    if (!loading) {
      clearTimeout(timer)
    }

    return () => clearTimeout(timer);
  }, [loading, maxLoadingTimeInSec]);

  useEffect(() => {
    console.log('set Interval');
    const interval = setInterval(() => {
      console.log("fileToLoad => " + Object.keys(fileToLoad).length);
      console.log("fileLoaded => " + Object.keys(fileLoaded).length);
      if (Object.keys(fileToLoad).length <= Object.keys(fileLoaded).length && !imageFinishToLoad) {
        setLoading(false);
        console.log('From interval !!!');
      }
    }, 1000);
    if (!loading) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [loading, imageFinishToLoad]);

  useEffect(() => {
    if (imageFinishToLoad) {
      console.log('Images load finished');
      const timer = setTimeout(() => setLoading(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [imageFinishToLoad]);

  useEffect(() => {
    if (setLoadPageDisplayed) {
      setLoadPageDisplayed(loading);
    }
  }, [loading, setLoadPageDisplayed]);

  useEffect(() => {

    const fileLoadEvent = ((event: CustomEvent) => {
      console.log(event);
      fileToLoad[event.detail] = true;
    }) as EventListener;

    const fileLoadedEvent = ((event: CustomEvent) => {
      console.log(event);
      fileLoaded[event.detail] = true;
      setImageFinishToLoad(Object.keys(fileToLoad).length === Object.keys(fileLoaded).length);
    }) as EventListener;

    window.addEventListener('fileToLoad', fileLoadEvent);
    window.addEventListener('fileLoaded', fileLoadedEvent);

    return () => {
      window.removeEventListener("fileToLoad", fileLoadEvent);
      window.removeEventListener("fileLoaded", fileLoadedEvent);
    }

  }, []);

  useImperativeHandle(ref, () => ({
    isLoading() {
      return loading;
    }
  }));

  useEffect(() => {
    if (!loading) {
      if (afterLoad) {
        afterLoad();
      }
      console.log('Loading Page => pageLoaded');
      window.dispatchEvent(new Event('pageLoaded'));
    }
  }, [loading, afterLoad]);

  useEffect(() => {
    if (!loading) {
      fileToLoad = {};
      fileLoaded = {};
    }
  }, [loading]);

  return (
    <>
      <div id='ks-loadingPage' className={`ks-loadingPage ${props.className || ''}`} style={{ display: (loading ? 'block' : 'none') }}>
        <div className="ks-loadingPage-logoWrapper">
          <Logo />
        </div>
        <div className="ks-loadingPage-lottieWrapper">
          <Player loop src={animationData} autoplay={loading} />
        </div>
        <div className="ks-loadingPage-messageWrapper">
          <label>chargement en cours...</label>
        </div>
      </div>
    </>
  );
})

export default LoadingPage;
