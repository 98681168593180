import "./BlockedModal.scss";
import { ReactComponent as IconClose } from "assets/v3/icon-close.svg";
import { MutableRefObject, useCallback, useEffect } from "react";
import { ClockLoader as Loader } from "react-spinners";
import IFrame from "./IFrame";
import { steps } from "components/utils/BlockerContext";
import AwladBlocked from "./AwladBlocked";
import { routeToPlatformHomePage } from "../internalrouter/HooPowAppMenuV2";
import ProfilForm from "components/elements/ProfilFormV3";
import Login from "components/elements/Login";
import { AmplifyAuthenticator } from "@aws-amplify/ui-react";
import { AuthState } from "@aws-amplify/ui-components";
import EmbeddedAuthentication from "components/auth/EmbeddedAuthentication";

const extraStyle: steps[] = [steps.AWLAD_SCHOOL, steps.SIGNUP, steps.SIGNIN];

function BlockedModal(props: { open: boolean, setOpen: Function, unblockStep: steps, setUnblockStep: Function, nextStep: MutableRefObject<steps> }) {
  const { unblockStep, setUnblockStep, nextStep } = props;

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.origin === routeToPlatformHomePage()) {
        if (event.data.step === 'signedup' || event.data.step === 'signedin' || event.data.step === 'payment_done_successfully') {
          document.location.reload();
        }
      }
    });
  }, [nextStep, setUnblockStep]);

  const viewSelector = useCallback(() => {
    switch (unblockStep) {
      case steps.RELOAD:
        document.location.reload();
        return (<></>)
      case steps.SIGNUP:
        return (<>
          <EmbeddedAuthentication />
        </>)
      case steps.LOADING:
        return (
          <div style={{ position: "absolute", bottom: "50px", zIndex: 9 }} id="iframe-loader" className="hideDiv">
            <Loader size={60} color="black" />
          </div>);
      case steps.SIGNIN:
        return (<AmplifyAuthenticator usernameAlias="email" initialAuthState={AuthState.SignIn}>
          <Login />
        </AmplifyAuthenticator>);
      case steps.STUDENT_PROFIL:
        return (<ProfilForm />);
      case steps.DONATION:
        return (<IFrame key={'iframe-donation'} id='iframe-donation'
          className="iframe-steps"
          src={`${routeToPlatformHomePage()}/embedded/vw/checkout?appId=${process.env.REACT_APP_ID}&productId=${"donation_50"}&itemId=full`}
          title='Donation' />)
      case steps.AWLAD_SCHOOL:
      default:
        return (<AwladBlocked />);
    }
  }, [unblockStep]);

  const closeModal = () => {
    props.setOpen(false);
    setUnblockStep(steps.AWLAD_SCHOOL);
  }

  return (
    <>
      {props.open && (
        <div className={`hp-blockedModal-popup ${(unblockStep && steps[unblockStep]) || "AWLAD_SCHOOL"} ${extraStyle.indexOf(unblockStep) > -1 && "extra-step"}`}>
          <div className="hp-blockedModal-popup__wrapper">
            <IconClose className="hp-blockedModal-popup__close ms-clickable" onClick={closeModal} />
            {viewSelector()}
          </div>
        </div>
      )}
    </>);
}

export default BlockedModal;
