import "./styles.scss";

const SelectComponent = ({
  options,
  type,
  label,
  handleChange,
  value,
}: {
  options: any;
  label?: string;
  type?: string;
  handleChange: any;
  value?: any;
}) => {
  return (
    <div
      className={`select-component ${options.length === 2 ? "rightTwo" : ""}`}
    >
      <div className="select-label">{label}</div>
      <div className="select-buttons">
        {options.map((option: any, id: any) => (
          <input
            key={id}
            type="button"
            className={`select-button ${value === option ? "active" : ""}`}
            name={type}
            onClick={handleChange}
            readOnly
            value={option}
          />
        ))}
      </div>
    </div>
  );
};
export default SelectComponent;
