import { useContext } from 'react';
import { ReactComponent as HomeBtn } from '../../assets/v2/home-btn.svg';
import { ReactComponent as DialoguerIcon } from 'assets/v3/dialoguer-icon.svg';
import { ReactComponent as AwladLogo } from '../../assets/v3/awlad-school-logo.svg';
import { ReactComponent as Star } from '../../assets/v3/star.svg';
import { ReactComponent as List } from '../../assets/v3/list.svg';
import { ReactComponent as BDouinLogo } from '../../assets/standalone/bdouin-logo.svg';
import './css/Phase3.scss';
import { Link, useHistory } from 'react-router-dom';
import { ChapterContext } from 'components/utils/ChapterContext';
import FileDS from 'datastore/FileDS';
import useResponsiveSize from 'components/utils/useResponsiveSize';
import { ClipLoader } from "react-spinners";
import { DialoguerContext } from 'components/utils/DialoguerContext';

function Phase3V2LeftArea(props: any) {
  const history = useHistory();
  const { starsInfo, setOpenIndexOverview, openIndexOverview } = useContext(DialoguerContext);
  const { currentProfil } = useContext(ChapterContext);

  const loaderSize = useResponsiveSize(50);

  return (
    <>
      <div className={'ks-awlad-avatar-student'}>
        {currentProfil ? (<>
          <img src={FileDS.getHoopowURLFile(currentProfil?.iconAvatar)} alt={currentProfil?.name + " Avatar"} />
          <span>{currentProfil?.name || currentProfil?.profilKey}</span>
        </>) : (<><ClipLoader color='white' size={loaderSize} /></>)}
      </div>
      <AwladLogo className={'ks-awlad-logo'} onClick={() => history.push('/')} />
      <div className={'ks-phase3-chapterSelected'}>
        <DialoguerIcon />
      </div>

      <div className='ks-chapter-stars'>
        <Star />{starsInfo ? (starsInfo?.nbStarsUser + "/" + starsInfo?.nbStarsTotal) : (<><ClipLoader color='white' size={loaderSize} /></>)}
      </div>

      <div className='ks-chapter-list'>
        <Link key="LECONS" to={`/dialoguer/lessons`}
          className={'ks-phase3-chapterBtn lessons ' + (props.sectionType === 'lessons' && "selected")}>
          LEÇONS
        </Link>
        <Link key="VOCABULAIRE" to={`/dialoguer/vocabulary`}
          className={'ks-phase3-chapterBtn vocabulary ' + (props.sectionType === 'vocabulary' && "selected")}>
          VOCABULAIRE
        </Link>
        <button key="INDEX" onClick={() => setOpenIndexOverview(true)}
          className={'mainBtn ks-phase3-chapterBtn index ' + (openIndexOverview && 'open')}>
          <List />INDEX
        </button>
      </div>
      <Link to='/'><HomeBtn /></Link>

      <div className='ks-awlad-mention'>
        <BDouinLogo className='ks-bdouin-logo' />
        <p>Logiciel réalisé et illustré par le studio <a href="https://www.bdouin.com">BDouin</a></p>
      </div>
    </>
  );
}

export default Phase3V2LeftArea;
