import { useImperativeHandle, useState, useEffect, forwardRef } from 'react';

const formatNumber = (value: number) => {
  return value < 10 ? `0${value}` : value;
}

const Timer = forwardRef((props: any, ref: any) => {
  const { initialMinute = 0, initialSeconds = 0, onTimeover, pause } = props;

  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (pause) return;
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          setFinished(true);
          clearInterval(myInterval);
          if (onTimeover) {
            onTimeover();
          }
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  });

  useImperativeHandle(ref, () => ({
    reset() {
      console.log("Reset the timer");
      setMinutes(initialMinute);
      setSeconds(initialSeconds);
      setFinished(false);
    },
    isFinished() {
      return finished;
    }
  }));

  return (
    <>
      { minutes === 0 && seconds === 0
        ? <>
          00:00
        </>
        : <> {formatNumber(minutes)}:{formatNumber(seconds)}</>
      }
    </>
  )
})

export default Timer;