import { SVGProps } from "Interfaces/SharedInterfaces";
import React from "react";

const AllSent: React.FC<SVGProps> = (props: SVGProps) => {
  const { width = "106", height = "133", color = "#51aabc" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 106.467 133.23"
    >
      <g
        id="Group_6317"
        data-name="Group 6317"
        transform="translate(-1675.986 -717.349)"
      >
        <g id="paper-plane" transform="translate(1675.986 717.349)">
          <path
            id="Path_2144"
            data-name="Path 2144"
            d="M90.939,88.719a3.4,3.4,0,0,1-1.074-.177L2.254,58.6a3.329,3.329,0,0,1-.47-6.1L101.595.375a3.332,3.332,0,0,1,4.835,3.442l-12.2,82.067a3.332,3.332,0,0,1-3.292,2.835ZM11.738,54.8l76.5,26.146L98.884,9.3Z"
            transform="translate(0 0.006)"
            fill={color}
          />
          <path
            id="Path_2145"
            data-name="Path 2145"
            d="M12.077,102.032A3.325,3.325,0,0,1,8.75,98.7V68.761a3.349,3.349,0,0,1,.892-2.271l61-65.432A3.33,3.33,0,1,1,75.508,5.6L15.4,70.07v18.49l11.1-15.114a3.329,3.329,0,0,1,5.363,3.944l-17.11,23.289a3.347,3.347,0,0,1-2.684,1.353Z"
            transform="translate(30.066 0.001)"
            fill={color}
          />
        </g>
        <g
          id="Group_1512"
          data-name="Group 1512"
          transform="translate(1682.165 799.504) rotate(-8)"
        >
          <path
            id="Path_2146"
            data-name="Path 2146"
            d="M.812,23.035a2.773,2.773,0,0,0,3.921,0l18.3-18.3A2.773,2.773,0,1,0,19.113.812l-18.3,18.3a2.773,2.773,0,0,0,0,3.922Zm0,0"
            transform="translate(0 27.73)"
            fill={color}
          />
          <path
            id="Path_2147"
            data-name="Path 2147"
            d="M19.113.812l-18.3,18.3a2.773,2.773,0,1,0,3.922,3.921l18.3-18.3A2.773,2.773,0,0,0,19.113.812Zm0,0"
            transform="translate(27.729 27.73)"
            fill={color}
          />
          <path
            id="Path_2148"
            data-name="Path 2148"
            d="M.812,23.035a2.774,2.774,0,0,0,3.921,0l18.3-18.3A2.773,2.773,0,1,0,19.113.812l-18.3,18.3a2.773,2.773,0,0,0,0,3.922Zm0,0"
            transform="translate(0 0)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default AllSent;
