export interface Letter {
  id: number;
  name: string;
  recordKey: string;
  xy: number[];
  isRecorded: boolean;
  isSent: boolean;
  audioKey: string;
  fullAudioKey?: string;
  revision: number;
  correct: boolean;
  disabled: boolean;
  status: string | undefined;
}
type LettersType = {
  letters: Letter[];
};
export const letters: LettersType = {
  letters: [
    {
      id: 4,
      recordKey: "THA",
      name: "ث",
      xy: [32, 75],
      isRecorded: false,
      isSent: false,
      fullAudioKey: "",
      audioKey: "",
      correct: false,
      status: undefined,
      disabled: false,
      revision: 1,
    },
    {
      id: 6,
      recordKey: "HHA",
      name: "ح",
      xy: [38, 65],
      isRecorded: false,
      isSent: false,
      fullAudioKey: "",
      audioKey: "",
      correct: false,
      status: undefined,
      disabled: false,
      revision: 1,
    },
    {
      id: 18,
      recordKey: "AYN",
      name: "ع",
      xy: [40, 64],
      isRecorded: false,
      isSent: false,
      fullAudioKey: "",
      audioKey: "",
      correct: false,
      status: undefined,
      disabled: false,
      revision: 1,
    },
    {
      id: 19,
      recordKey: "RAYN",
      name: "غ",
      xy: [40, 64],
      isRecorded: false,
      isSent: false,
      fullAudioKey: "",
      audioKey: "",
      correct: false,
      status: undefined,
      disabled: false,
      revision: 1,
    },
    {
      id: 21,
      recordKey: "QAF",
      name: "ق",
      xy: [36, 72],
      isRecorded: false,
      isSent: false,
      fullAudioKey: "",
      audioKey: "",
      correct: false,
      status: undefined,
      disabled: false,
      revision: 1,
    },
    {
      id: 26,
      recordKey: "HA",
      name: "ه",
      xy: [44, 74],
      isRecorded: false,
      isSent: false,
      fullAudioKey: "",
      audioKey: "",
      correct: false,
      status: undefined,
      disabled: false,
      revision: 1,
    },
    {
      id: 15,
      recordKey: "DAD",
      name: "ض",
      xy: [26, 68],
      isRecorded: false,
      isSent: false,
      fullAudioKey: "",
      audioKey: "",
      correct: false,
      status: undefined,
      disabled: false,
      revision: 1,
    },
    {
      id: 16,
      recordKey: "TAE",
      name: "ط",
      xy: [35, 77],
      isRecorded: false,
      isSent: false,
      fullAudioKey: "",
      audioKey: "",
      correct: false,
      status: undefined,
      disabled: false,
      revision: 1,
    },
    {
      id: 17,
      recordKey: "ZA",
      name: "ظ",
      xy: [35, 77],
      isRecorded: false,
      isSent: false,
      fullAudioKey: "",
      audioKey: "",
      correct: false,
      status: undefined,
      disabled: false,
      revision: 1,
    },
    {
      id: 7,
      recordKey: "KHA",
      name: "خ",
      xy: [38, 65],
      isRecorded: false,
      isSent: false,
      fullAudioKey: "",
      audioKey: "",
      correct: false,
      status: undefined,
      disabled: false,
      revision: 1,
    },

    {
      id: 9,
      recordKey: "ZEL",
      name: "ذ",
      xy: [42, 75],
      isRecorded: false,
      isSent: false,
      fullAudioKey: "",
      audioKey: "",
      correct: false,
      status: undefined,
      disabled: false,
      revision: 1,
    },
    {
      id: 10,
      recordKey: "RA",
      name: "ر",
      xy: [42, 69],
      isRecorded: false,
      isSent: false,
      fullAudioKey: "",
      audioKey: "",
      correct: false,
      status: undefined,
      disabled: false,
      revision: 1,
    },

    {
      id: 14,
      recordKey: "SAD",
      name: "ص",
      xy: [26, 68],
      isRecorded: false,
      isSent: false,
      fullAudioKey: "",
      audioKey: "",
      correct: false,
      status: undefined,
      disabled: false,
      revision: 1,
    },
  ],
};
