import React, { useState, useEffect } from 'react';
import './FullScreenBtn.scss'
import { ReactComponent as IconFullScreen } from '../../assets/icon_fullScreen.svg';
import { ReactComponent as IconReduceScreen } from '../../assets/icon_reduceScreen.svg';
import Sounds from '../utils/Sounds';

function FullScreenBtn() {
  const documentEl: any = document;
  const initialFullScreen = documentEl.fullscreenElement || documentEl.webkitFullscreenElement || documentEl.mozFullScreenElement;
  const [fullScreen, setFullScreen] = useState<boolean>(initialFullScreen);
  const [isStandalone, setIsStandalone] = useState<boolean>();

  useEffect(() => {
    let navigator: any = window.navigator;
    console.log('isStandalone matches =>', window.matchMedia('(display-mode: standalone)').matches);
    setIsStandalone(((('standalone' in navigator) && (navigator.standalone)) || (window.matchMedia('(display-mode: standalone)').matches)));
  }, []);

  useEffect(() => {
    document.onfullscreenchange = function () {
      setFullScreen(documentEl.fullscreenElement || documentEl.webkitFullscreenElement || documentEl.mozFullScreenElement);
    }
    documentEl.onwebkitfullscreenchange = function () {
      setFullScreen(documentEl.fullscreenElement || documentEl.webkitFullscreenElement || documentEl.mozFullScreenElement);
    }
  }, [documentEl.fullscreenElement, documentEl.webkitFullscreenElement, documentEl.mozFullScreenElement, documentEl]);

  useEffect(() => {
    if (!fullScreen && !isStandalone) {
      document.getElementById('kalimatSchool')?.removeAttribute('class');
    } else if (!document.getElementById('kalimatSchool')?.classList.contains('standalone')) {
      document.getElementById('kalimatSchool')?.setAttribute('class', 'standalone');
    }
  }, [fullScreen, isStandalone]);

  return (<>
    {!fullScreen ? (
      <IconFullScreen className='ks-clickable ks-iconFullScreen' onClick={() => {
        Sounds.playButtonSound();
        const elem: any = document.documentElement;
        document.getElementById('kalimatSchool')?.setAttribute('class', 'standalone');
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
      }} />
    ) : (
        <IconReduceScreen className='ks-clickable ks-iconFullScreen' onClick={() => {
          Sounds.playButtonSound();
          const elem: any = document;
          if (!isStandalone) {
            console.log('isStandalone => ', isStandalone);
            document.getElementById('kalimatSchool')?.removeAttribute('class');
          }
          if (elem.exitFullscreen) {
            elem.exitFullscreen();
          } else if (elem.cancelFullScreen) {
            elem.cancelFullScreen();
          } else if (elem.webkitCancelFullScreen) {
            elem.webkitCancelFullScreen();
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
          }
        }} />
      )}
  </>);
}

export default FullScreenBtn;
