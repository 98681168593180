import { useContext } from 'react';
import './css/AlphabetChapterLeftArea.scss';
import { ReactComponent as AlphabetTitle } from '../assets/v3/alphabet-icon.svg';
import { ReactComponent as WaladBtn } from '../assets/v2/walad-btn.svg';
import { ReactComponent as HomepageBtn } from '../assets/v2/homepage-btn.svg';
import { ReactComponent as DownloadBtn } from '../assets/v2/download-btn.svg';
import { ReactComponent as AwladLogo } from '../assets/v3/awlad-school-logo.svg';
import { ReactComponent as BDouinLogo } from '../assets/standalone/bdouin-logo.svg';
import { Link, useHistory } from 'react-router-dom';
import { ChapterContext } from './utils/ChapterContext';
import FileDS from 'datastore/FileDS';

function AlphabetChapterLeftArea(props: any) {
  const history = useHistory();
  const { setShowWaladCards } = props;
  const { downloadFile, downloading } = useContext(ChapterContext);
  const { currentProfil } = useContext(ChapterContext);

  return (
    <div className="ks-alphabet-homepage">

      <div className={'ks-awlad-avatar-student'}>
        <img src={FileDS.getHoopowURLFile(currentProfil?.iconAvatar)} alt={currentProfil?.name + " Avatar"} />
        <span>{currentProfil?.name || currentProfil?.profilKey}</span>
      </div>
      <AwladLogo className={'ks-awlad-logo'} onClick={() => history.push('/')} />
      <AlphabetTitle className="ks-alphabet-homepage-title" />
      <WaladBtn className="ks-walad-btn" onClick={() => setShowWaladCards((prev: any) => !prev)} />
      <DownloadBtn className={`${downloading && 'ks-disabled'} ks-download-btn`} onClick={() => {
        if (!downloading) {
          downloadFile()
        }
      }} />
      <Link to='/'><HomepageBtn className="ks-homepage-btn" /></Link>

      <div className='ks-awlad-mention'>
        <BDouinLogo className='ks-bdouin-logo' />
        <p>Logiciel réalisé et illustré par le studio <a href="https://www.bdouin.com">BDouin</a></p>
      </div>
    </div>);
}

export default AlphabetChapterLeftArea;
