import { FC, useState, useEffect } from "react";
import SongOn from "assets/Icons/SongOn";
import Tooltip from "components/common/Tooltip";
import Stop from "assets/Icons/Stop";

export const AudioPlayer: FC<any> = ({ audioKey, disabled, setDisabled }) => {
  const [playing, setPlaying] = useState(false);
  const [audio, setAudio] = useState<HTMLAudioElement>(new Audio(audioKey));

  const toggle: any = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    if (audioKey) {
      setAudio(new Audio(audioKey));
    }
  }, [audioKey]);
  useEffect(() => {
    if (playing) {
      setDisabled(true);
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
      setDisabled(false);
    }
  }, [audio, playing, setDisabled]);

  useEffect(() => {
    audio.onended = function () {
      setPlaying(false);
    };
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, [audio]);

  return (
    <div>
      <Tooltip
        content={`ÉCOUTER`}
        disabled={disabled ? disabled : audioKey ? false : true}
        className="player"
        v2={audio && !playing}
      >
        {audioKey ? (
          playing ? (
            <Stop onClick={() => toggle()} disabled={disabled} />
          ) : (
            <SongOn onClick={() => toggle()} disabled={disabled} />
          )
        ) : (
          <SongOn disabled />
        )}
      </Tooltip>
    </div>
  );
};
