import { SVGProps } from "Interfaces/SharedInterfaces";
import React from "react";

const Lock: React.FC<SVGProps> = (props: SVGProps) => {
  const { width = "32", height = "32", color = "#fff", className } = props;

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      className={className}
      viewBox="0 0 24.574 32.765"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="padlock_2_"
        data-name="padlock (2)"
        d="M24.5,12.287H23.478v-4.1a8.191,8.191,0,1,0-16.382,0v4.1H6.072A3.075,3.075,0,0,0,3,15.359V29.693a3.075,3.075,0,0,0,3.072,3.072H24.5a3.075,3.075,0,0,0,3.072-3.072V15.359A3.075,3.075,0,0,0,24.5,12.287ZM9.826,8.191a5.461,5.461,0,1,1,10.922,0v4.1H9.826Zm6.826,14.638v3.11a1.365,1.365,0,1,1-2.73,0v-3.11a2.73,2.73,0,1,1,2.73,0Z"
        transform="translate(-3)"
        fill={color}
      />
    </svg>
  );
};

export default Lock;
