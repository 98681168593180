import AllSent from "assets/Icons/AllSent";
import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";
interface ModalProps {
  title: string;
  className?: string;
  hasTwoButtons?: boolean;
  primaryButtonTitle?: string;
  secondaryButtonTitle?: string;
  description: string;
  hasIcon?: boolean;
  allCorrected?: boolean;
  description2?: string;
  description3?: string;
  onClose?: any;
}
export const InstructionsModal: React.FC<ModalProps> = ({
  title,
  className,
  primaryButtonTitle,
  secondaryButtonTitle,
  description,
  description2,
  description3,
  onClose,
  hasIcon,
  allCorrected,
  hasTwoButtons,
  children,
}) => {
  return (
    <div
      className={`${className ? className : ""} ${
        hasIcon ? "hasIcon" : ""
      } modal-box`}
    >
      <div className="modal-body">
        <div className="modal-title">{title}</div>
        <div className={`${allCorrected ? "allCorrected" : ""} modal-content`}>
          <p>
            {description} <br />
            {description2 ? <br /> && description2 : ""} <br />
            {description3 ? <br /> && description3 : ""}
          </p>
        </div>
      </div>
      {hasIcon ? (
        <div className="modal-icon">
          <AllSent />
        </div>
      ) : null}
      <div className="modal-footer">
        {primaryButtonTitle ? (
          <button className="primary" onClick={onClose}>
            {primaryButtonTitle}
          </button>
        ) : null}
        {hasTwoButtons ? (
          <Link to="/">
            <button className="secondary">{secondaryButtonTitle}</button>
          </Link>
        ) : null}
      </div>
    </div>
  );
};
