import { createContext, useCallback, useRef, useState } from "react";
export const TutoContext = createContext<any>("");

const TutoContextProvider = (props: any) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [playTuto, setPlayTuto] = useState<boolean>(true);
  const [showTuto, setShowTuto] = useState<boolean>();
  const audioRef = useRef<any>();

  const closeTuto = useCallback(() => {
    setShowTuto(false);
    setTimeout(() => setCurrentStep(0), 300);
  }, [setShowTuto, setCurrentStep])

  return (
    <TutoContext.Provider value={{
      currentStep, setCurrentStep,
      playTuto, setPlayTuto,
      showTuto, setShowTuto,
      audioRef, closeTuto
    }} >
      {props.children}
    </TutoContext.Provider>
  );
};

export default TutoContextProvider;
