import { SVGProps } from "Interfaces/SharedInterfaces";
import React from "react";

const ArrowUp: React.FC<SVGProps> = (props: SVGProps) => {
  const { width = "28", height = "28", color = "#fff", className } = props;

  return (
    <svg
      width={width}
      className={className}
      height={height}
      fill="none"
      viewBox="0 0 29 18.825"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Polygon_8"
        data-name="Polygon 8"
        d="M12.123,3.086a3,3,0,0,1,4.753,0l8.4,10.909A3,3,0,0,1,22.9,18.825H6.1a3,3,0,0,1-2.377-4.831Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowUp;
