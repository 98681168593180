import { SVGProps } from "Interfaces/SharedInterfaces";
import React from "react";

const SongOn: React.FC<SVGProps> = (props: SVGProps) => {
  let {
    width = "52",
    height = "52",
    color = "#51aabc",
    disabled,
    onClick,
  } = props;
  let fill = "#fff";
  disabled ? (color = "#B9C7CB") && (fill = "#DBE2E4") : (color = "#51aabc");
  return (
    <svg
      id="music-and-multimedia_1_"
      data-name="music-and-multimedia"
      width={width}
      height={height}
      fill="none"
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      onClick={disabled ? null : onClick}
      viewBox="0 0 146 146"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="LISTEN" transform="translate(-1641.361 -684)">
        <g
          id="Ellipse_104"
          data-name="Ellipse 104"
          transform="translate(1645.361 688)"
          fill={fill}
          stroke={color}
          strokeWidth={disabled ? "0" : "4"}
        >
          <circle cx="69" cy="69" r="69" stroke="none" />
          <circle cx="69" cy="69" r="71" fill="none" />
        </g>
        <g
          id="music-and-multimedia_1_"
          data-name="music-and-multimedia (1)"
          transform="translate(1678.424 723.336)"
        >
          <path
            id="Path_1342"
            data-name="Path 1342"
            d="M41.425,16.226a2.369,2.369,0,0,0-2.514.285L15.8,35H4.752A4.76,4.76,0,0,0,0,39.754V58.763a4.757,4.757,0,0,0,4.752,4.752H15.8l23.11,18.491a2.379,2.379,0,0,0,2.519.28,2.371,2.371,0,0,0,1.345-2.138V18.369A2.384,2.384,0,0,0,41.425,16.226Z"
            transform="translate(0 -15.99)"
            fill={color}
          />
          <path
            id="Path_1343"
            data-name="Path 1343"
            d="M345.8,122.936a2.377,2.377,0,1,0-3.341,3.384,18.954,18.954,0,0,1,0,26.84,2.374,2.374,0,1,0,3.341,3.374,23.7,23.7,0,0,0,0-33.6Z"
            transform="translate(-291 -106.472)"
            fill={color}
          />
          <path
            id="Path_1344"
            data-name="Path 1344"
            d="M390.919,77.814a2.377,2.377,0,1,0-3.35,3.374,28.356,28.356,0,0,1,0,40.246,2.377,2.377,0,0,0,3.35,3.374,33.117,33.117,0,0,0,0-46.994Z"
            transform="translate(-329.416 -68.046)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default SongOn;
