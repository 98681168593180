import { Player } from '@lottiefiles/react-lottie-player';
import Testimony from 'components/elements/Testimony';
import { Trans, useTranslation } from 'react-i18next';
import './Section3.scss';

function Section3() {
  useTranslation();
  
  return (
    <>
      <section className="section3">
        <h2><Trans i18nKey={'homepage-nb-subscriber'} /></h2>
        <div className='scrollable-frame'>
          <div className='testimony-list'>
            {[1, 2, 3, 4, 5].map(int => (
              <div className="testimony" key={'testimony_' + int}>
                <Testimony testimonyI18nKey={'homepage-testimony' + int} studentI18nKey={'homepage-student' + int} />
              </div>
            ))}
          </div>
        </div>
        <div className='awlad-boat'>
          <div className='boat'><Player autoplay loop src="./lotties/boat.json" /></div>
          <div className='waves'><Player autoplay loop src="./lotties/waves.json" /></div>
        </div>
      </section>
    </>
  );
}

export default Section3;
