import React, { useState, useEffect, useCallback, useContext } from 'react';
import './css/SessionLayout.scss';
import { ReactComponent as AssessmentBG } from '../assets/assessment_bg.svg';
import { ReactComponent as InterfaceBG } from '../assets/interface_bg.svg';
import { ReactComponent as Padlock } from '../assets/padlock.svg';
import { ReactComponent as IconClose } from '../assets/icon_close.svg';
import { ReactComponent as BtnOpenTuto } from '../assets/button_open_tuto.svg';
import { ReactComponent as ArrowNotification } from '../assets/arrow_notification.svg';
import { ReactSVG } from 'react-svg';
import { TReport, TSession, TUserInfo, TFinalTestReport } from '../types';
import BaseDS from '../datastore/BaseDS';
import TextDecoder from './utils/TextDecoder';
import SVG from './elements/SVG';
import UserDS from '../datastore/UserDS';
import Timer from './elements/Timer';
import Tuto from './elements/Tuto';
import Screen from './screens/Screen';
import { useHistory } from "react-router-dom";
import LoadingPage from './elements/LoadingPage';
import ExitProfilFullScreen from './elements/ExitProfilFullScreen';
import Sounds from './utils/Sounds';
import FileLoadEvent from './utils/FileLoadEvent';
import { SessionContext } from './utils/SessionContext';
import { TutoContext } from './utils/TutoContext';
import { chapterIdToRoot } from './utils/ChapterContext';
import { unblockedUsers } from 'utils/Constants';
import FileDS from 'datastore/FileDS';

const ukToFR: any = {
  'look': 'regarde',
  'listen': 'écoute',
  'repeat': 'répète',
  'select': 'sélectionne',
  'write': 'écris'
}

const bkgPerScreenType: any = {
  'LetterWrittenInName': 'images/02-01-lesson_bkg.svg',
  'WriteName': 'images/02-01-lesson_bkg.svg',
  'RecogniseLetterKeyboard': 'images/02-03-exercise_bkg.svg',
  'LetterWrittenInExpression': 'images/02-02-lesson-bg.svg',
  'WriteExpression': 'images/02-02-lesson-bg.svg'
}
const goBackPath = (history: any, chapterId: string) => {
  if (process.env.REACT_APP_FEATURE_FREEMIUM === '1') {
    return history.location.state && history.location.state.from
      ? history.location.state.from
      : chapterIdToRoot[chapterId].replace(':chapterId', chapterId);
  } else {
    return history.location.state && history.location.state.from ? history.location.state.from : '/chapter/' + chapterId;
  }
}

const interfaceFinished = (practiseResult: TReport[] | TFinalTestReport | undefined, sessionDefinition: TSession, currentProfil: TUserInfo | undefined,
  currentInterfaceIndex: number, setCurrentProfil: Function, setProfilSession: Function, setNextInterfaceUnlock?: Function, callback?: Function) => {
  console.log('interfaceFinished');
  if (currentProfil) {
    console.log(currentProfil[sessionDefinition.chapterId][sessionDefinition.sessionKey][currentInterfaceIndex]);
  }
  if (sessionDefinition && currentInterfaceIndex < sessionDefinition.interface.length) {
    if (currentProfil
      && (!currentProfil[sessionDefinition.chapterId][sessionDefinition.sessionKey][currentInterfaceIndex] ||
        currentProfil[sessionDefinition.chapterId][sessionDefinition.sessionKey][currentInterfaceIndex].state !== 'completed'
        || sessionDefinition.interface[currentInterfaceIndex].type === 'assessment')) {
      const interfaceKey = currentProfil[sessionDefinition.chapterId][sessionDefinition.sessionKey][currentInterfaceIndex];
      console.log("Complete current interface and open next one.");
      UserDS.completeLastInterfaceAndEnableNext(currentProfil.profilKey, sessionDefinition, interfaceKey.interfaceKey, practiseResult).then((data: any) => {
        currentProfil[sessionDefinition.chapterId][sessionDefinition.sessionKey] = data[sessionDefinition.chapterId][sessionDefinition.sessionKey];
        console.log("Profil updated correctly.");
        setCurrentProfil(currentProfil);
        setProfilSession(data[sessionDefinition.chapterId][sessionDefinition.sessionKey]);
        if (setNextInterfaceUnlock) setNextInterfaceUnlock(true);
        console.log("currentDebug", callback);
        if (callback) callback();
      });
    } else if (callback) {
      callback();
    }
    console.log('User finish the interface.');
  }
};

const goNextActivity = (currentInterfaceIndex: number, sessionDefinition: TSession, setCurrentInterfaceIndex: Function,
  currentProfil: TUserInfo, setTimeFinished: Function, history: any) => {
  if (currentInterfaceIndex < sessionDefinition.interface.length - 1) {
    setTimeFinished(false);
    setCurrentInterfaceIndex(currentInterfaceIndex + 1)
  } else {
    const nbSession = Object.keys(currentProfil[sessionDefinition.chapterId]).filter(k => !isNaN(parseInt(k))).length;
    console.log('currentProfil', currentProfil[sessionDefinition.chapterId]);
    console.log('nbSession => ' + nbSession);
    console.log('sessionDefinition.sessionKey  =>  ' + sessionDefinition.sessionKey);
    if (sessionDefinition.isLast) {
      history.push(`/`);
    } else {
      history.push(goBackPath(history, sessionDefinition.chapterId));
    }
  }
}

function SessionLayout(props: any) {

  const { sessionDefinition, setSessionDefinition, currentInterfaceIndex, setCurrentInterfaceIndex, nextInterfaceUnlock,
    setNextInterfaceUnlock, nbLoadIndex, setNbLoadIndex, currentInterfaceState, setCurrentInterfaceState,
    currentProfil, setCurrentProfil, profilSession, setProfilSession, screen, setScreen, setCurrentScreen,
    showHelp, setShowHelp, tuto, setTuto, currentTutoIndex, setCurrentTutoIndex, helpText,
    timeFinished, setTimeFinished, timerRef } = useContext(SessionContext);

  const { showTuto, setShowTuto } = useContext(TutoContext);

  const history = useHistory<any>();
  const urlParams = new URLSearchParams(props.location.search);
  const forcedTimer: any = urlParams.get('timer');
  const showScreenList: any = urlParams.get('screensList');
  const chapterId = props.match.params.chapterId;
  const sessionKey = props.match.params.sessionKey;

  const [loadPageDisplayed, setLoadPageDisplayed] = useState<boolean>(true);

  const goToInterface = useCallback((index: number) => {
    setCurrentInterfaceIndex(index);
    setNbLoadIndex((prev: number) => prev + 1);
    setNextInterfaceUnlock(false);
  }, [setCurrentInterfaceIndex, setNbLoadIndex, setNextInterfaceUnlock]);

  useEffect(() => {
    console.log('Load current user profil');
    FileLoadEvent.triggerFileToLoad('loadingCurrentProfil');
    UserDS.getCurrentUserProfil((profil: TUserInfo) => {
      setCurrentProfil(profil);
      setTimeout(() => FileLoadEvent.triggerFileLoaded('loadingCurrentProfil'), 500);
    })
  }, [setCurrentProfil]);

  useEffect(() => {
    console.log('Load session');
    if (chapterId && (sessionKey || sessionKey === 0)) {
      FileLoadEvent.triggerFileToLoad('Load session');
      BaseDS.getFullSessionById(chapterId, sessionKey).then((data: TSession) => {
        setSessionDefinition(data);
        setTimeout(() => FileLoadEvent.triggerFileLoaded('Load session'), 500);
      });
    }
  }, [chapterId, sessionKey, setSessionDefinition]);

  useEffect(() => {
    const currentChapterId = sessionDefinition?.chapterId;
    const currentSessionKey = sessionDefinition?.sessionKey;
    if (currentProfil && currentChapterId && currentSessionKey
      && currentProfil[currentChapterId] && currentProfil[currentChapterId][currentSessionKey]) {
      const currentInterface = currentProfil[currentChapterId][currentSessionKey][currentInterfaceIndex];
      setCurrentInterfaceState(currentInterface ? currentInterface.state : '');
    } else {
      setCurrentInterfaceState('');
    }
  }, [currentInterfaceIndex, sessionDefinition, currentProfil, setCurrentInterfaceState])

  useEffect(() => {
    setShowTuto(undefined);
    setTuto(undefined);
  }, [currentInterfaceIndex, setShowTuto, setTuto])

  useEffect(() => {
    if (currentProfil && sessionDefinition) {
      console.log('Calculate the session state for the user');
      const currentChapterId = sessionDefinition?.chapterId;
      const currentSessionKey = sessionDefinition?.sessionKey;
      if (currentProfil[currentChapterId]
        && currentProfil[currentChapterId][currentSessionKey]
        && currentProfil[currentChapterId][currentSessionKey].length) {
        setProfilSession(currentProfil[currentChapterId][currentSessionKey])
      } else {
        const defaultInterface = sessionDefinition?.interface[0];
        if (!currentProfil[currentChapterId]) currentProfil[currentChapterId] = {};
        currentProfil[currentChapterId][currentSessionKey] = [defaultInterface];
        setProfilSession([defaultInterface]);
        setCurrentProfil(currentProfil);
      }
      const interfaceIndex = currentProfil[currentChapterId][currentSessionKey].findIndex((i: any) => i.state !== 'completed');
      setCurrentInterfaceIndex(interfaceIndex > -1 ? interfaceIndex : 0);
    }
  }, [sessionDefinition, currentProfil, setCurrentInterfaceIndex, setCurrentProfil, setProfilSession]);

  const openTuto = tuto && tuto.tutoSteps && ((tuto.displayTutoOnLoad && currentInterfaceState !== 'completed' && showTuto === undefined) || showTuto) && tuto.tutoSteps.length;

  useEffect(() => {
    if (currentProfil && sessionDefinition && currentInterfaceIndex > -1) {
      console.log("interfaceIndex from screen render => " + currentInterfaceIndex);
      const screenType = sessionDefinition.interface[currentInterfaceIndex].screenType;
      console.log('Set screen type and helper');
      console.log('Go to next interface => ' + screenType);
      setScreen(
        <Screen key={currentInterfaceIndex} interfaceDef={sessionDefinition?.interface[currentInterfaceIndex]}
          screenType={sessionDefinition.interface[currentInterfaceIndex].screenType}
          sessionDef={sessionDefinition}
          currentProfil={currentProfil}
          history={history}
          setShowHelp={setShowHelp}
          setTuto={setTuto}
          currentTutoIndex={currentTutoIndex}
          setShowTuto={setShowTuto}
          setCurrentScreen={setCurrentScreen}
          loadingProfil={!currentProfil || (loadPageDisplayed && !props.isV2) || (props.externalLoadPageDisplayed && props.isV2)}
          tutoOpen={openTuto}
          showScreenList={showScreenList}
          loadPageDisplayed={(loadPageDisplayed && !props.isV2) || (props.externalLoadPageDisplayed && props.isV2)}
          sessionProfil={currentProfil[sessionDefinition.chapterId][sessionDefinition.sessionKey]}
          interfaceFinished={(practiseResult?: TReport[], callback?: Function) => {
            if (!timerRef || !timerRef.current || timerRef.current.isFinished()) {
              interfaceFinished(practiseResult, sessionDefinition, currentProfil, currentInterfaceIndex,
                setCurrentProfil, setProfilSession, setNextInterfaceUnlock, callback)
              return true;
            } else {
              return false;
            }
          }}
          goNextActivity={() => goNextActivity(currentInterfaceIndex, sessionDefinition, goToInterface,
            currentProfil, setTimeFinished, history)}
          timerRef={timerRef} />
      )
    }
  }, [setCurrentProfil, setCurrentScreen, setScreen, setShowHelp, setShowTuto, setTimeFinished, setTuto, timerRef, currentInterfaceIndex, showScreenList, currentTutoIndex, sessionDefinition, currentProfil, setNextInterfaceUnlock, setProfilSession, goToInterface, history, openTuto, props.isV2, props.externalLoadPageDisplayed, loadPageDisplayed]);

  const interfaceEnabled = (interfaceKey: number) => {
    return interfaceKey === 1 || unblockedUsers.indexOf(currentProfil.name) > -1 || unblockedUsers.indexOf(currentProfil.profilKey) > -1
      || (profilSession && profilSession.filter((session: any) => interfaceKey === session.interfaceKey).length > 0);
  }

  const isAssessmentFinalScreen = () => {
    return sessionDefinition && sessionDefinition.interface[currentInterfaceIndex].screenType === 'AssessmentFinalScreen';
  }

  useEffect(() => {
    setTimeFinished(false);
    if (timerRef && timerRef.current) {
      timerRef.current?.reset();
    }
  }, [currentInterfaceIndex, setTimeFinished, timerRef]);

  const finishInterface = () => {
    if (sessionDefinition) {
      interfaceFinished(undefined, sessionDefinition, currentProfil, currentInterfaceIndex,
        setCurrentProfil, setProfilSession, setNextInterfaceUnlock);
      setTimeFinished(true);
    }
  }

  return (
    <>
      <LoadingPage key={nbLoadIndex} setLoadPageDisplayed={setLoadPageDisplayed} />
      {currentInterfaceIndex > -1 && (<>
        <div id='screen' className={`ks-sessionLayout ${sessionDefinition?.interface[currentInterfaceIndex].type} ${sessionDefinition?.interface[currentInterfaceIndex].screenType} ks-${sessionDefinition?.phaseType}`}>
          {tuto && tuto.tutoSteps.length && (
            <Tuto steps={tuto.tutoSteps} setCurrentTutoIndex={setCurrentTutoIndex} open={openTuto} loadingProfil={!currentProfil || (loadPageDisplayed && !props.isV2) || (props.externalLoadPageDisplayed && props.isV2)} />
          )}
          {!props.isV2 && (
            <div className='ks-leftPanel'>
              {currentProfil?.name ? (
                <img src={FileDS.getHoopowURLFile(currentProfil?.iconAvatar)} alt={currentProfil?.name + " Avatar"} className='ks-avatar' />
              ) : (
                <SVG src={currentProfil?.iconAvatar} className='ks-avatar' />
              )}
              <div className='ks-usnername'>{currentProfil?.name || currentProfil?.profilKey || ''}</div>
              {sessionDefinition && sessionDefinition.interface && sessionDefinition.interface[currentInterfaceIndex] && (
                <>
                  <div className='ks-counter'>
                    {(sessionDefinition.interface[currentInterfaceIndex].timerMinutes > 0
                      || sessionDefinition.interface[currentInterfaceIndex].timerSeconds > 0) &&
                      (!timeFinished ? (
                        <div className='ks-minutesSeconds'>
                          <Timer ref={timerRef} key={currentInterfaceIndex} pause={openTuto || !currentProfil || (loadPageDisplayed && !props.isV2) || (props.externalLoadPageDisplayed && props.isV2)} initialMinute={forcedTimer ? 0 : (sessionDefinition.interface[currentInterfaceIndex].timerMinutes > 0 ? sessionDefinition.interface[currentInterfaceIndex].timerMinutes : 0)}
                            initialSeconds={forcedTimer ? forcedTimer : (sessionDefinition.interface[currentInterfaceIndex].timerSeconds > 0 ? sessionDefinition.interface[currentInterfaceIndex].timerSeconds : 0)}
                            onTimeover={finishInterface} />
                        </div>
                      ) : (
                        <div className='ks-minutesSeconds ks-timerFinished'>00:00</div>
                      )
                      )}
                  </div>
                  {sessionDefinition?.interface[currentInterfaceIndex].targets
                    && sessionDefinition?.interface[currentInterfaceIndex].targets.map((target: string, index: number) => (
                      <div key={index} style={{ textAlign: 'center' }}>
                        <ReactSVG className='ks-targetIcon' src={`/icon_${target}.svg`} />
                        <span className='ks-targetTitle'>{ukToFR[target]}</span>
                      </div>
                    ))}
                </>
              )}
              <div className={'ks-showTutoBtn ' + (openTuto ? ' ks-tutoOpen' : '')}>
                <BtnOpenTuto className='ks-clickable' onClick={() => { Sounds.playNextSound(() => setShowTuto(!openTuto)) }} />
              </div>
            </div>
          )}
          <div className='ks-mainPanel'>
            {!props.isV2 && (<>
              {sessionDefinition && sessionDefinition.interface && sessionDefinition.interface[currentInterfaceIndex].background ? (
                <SVG src={sessionDefinition.interface[currentInterfaceIndex].background} className='ks-session-bg' />
              ) : (<>
                {sessionDefinition && sessionDefinition.background ? (
                  <SVG src={sessionDefinition.background} className='ks-session-bg' />
                ) : (<>
                  {sessionDefinition && bkgPerScreenType[sessionDefinition.interface[currentInterfaceIndex].screenType] ? (<>
                    <SVG src={bkgPerScreenType[sessionDefinition.interface[currentInterfaceIndex].screenType]} className='ks-session-bg' />
                  </>) : (
                    <>{sessionDefinition && sessionDefinition.interface
                      && sessionDefinition.interface[currentInterfaceIndex].type === 'assessment' ? (
                      <AssessmentBG className="ks-session-bg" />
                    ) : (
                      <InterfaceBG className="ks-session-bg" />
                    )}</>
                  )}
                </>)}
              </>)}
            </>)}
            <div className={`ks-help-panel ${showHelp ? 'show' : ''}`}>
              <IconClose className="ks-help-closeBtn" onClick={() => { Sounds.playButtonSound(); setShowHelp(false) }} />
              <div className="ks-help-title">NOTE EXPLICATIVE</div>
              <div className="ks-help-text">{TextDecoder.formatText(helpText)}</div>
              <div className="ks-help-exit" onClick={() => { Sounds.playButtonSound(); setShowHelp(false) }}>FERMER</div>
            </div>
            {!props.isV2 && !isAssessmentFinalScreen() && (
              <div className="ks-session-top-navigation">
                {sessionDefinition && sessionDefinition.title && (
                  <div className="ks-session-title">{sessionDefinition.title}</div>
                )}
                {sessionDefinition && sessionDefinition.interface.map((interfaceTemp: any, index: number) => (
                  <React.Fragment key={'parent_' + index}>
                    {(interfaceTemp.screenType !== 'AssessmentFinalScreen' || process.env.REACT_APP_ENVIRONMENT_APP !== 'production') && (<>
                      {interfaceEnabled(interfaceTemp.interfaceKey) ? (
                        <React.Fragment key={index}>
                          <div key={index} className={`ks-interface-number ks-clickable ${index === currentInterfaceIndex ? 'ks-interface-selected' : (nextInterfaceUnlock && index === currentInterfaceIndex + 1 ? ' ks-interface-unlocked' : '')}`}
                            onClick={() => { Sounds.playButtonSound(); goToInterface(index) }}>{index + 1}

                            {nextInterfaceUnlock && index === currentInterfaceIndex + 1 && (
                              <ArrowNotification className='ks-arrowNewInterface' />
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        <div key={index} className="ks-interface-number ks-unallowed"><Padlock /></div>
                      )}
                    </>)}
                  </React.Fragment>
                ))}
              </div>
            )}
            <div className={`ks-mainScreen ${isAssessmentFinalScreen() ? 'ks-full-screen' : ''}`}>
              <>
                {screen}
              </>
            </div>
            {nextInterfaceUnlock && sessionDefinition && sessionDefinition?.interface.length - 1 === currentInterfaceIndex
              && sessionDefinition?.interface[currentInterfaceIndex].screenType === 'RevisionQamariaShamsia' && (
                <ArrowNotification className='ks-arrowNewInterface ks-arrowNewInterfaceLast' />
              )}
          </div>
          {!props.isV2 && (
            <ExitProfilFullScreen profilName={currentProfil?.name || currentProfil?.profilKey || ''} destination={goBackPath(history, chapterId)} />
          )}
        </div>
      </>)}
    </>
  );
}

export default SessionLayout;
