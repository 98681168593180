import { useContext } from 'react';
import './TimerDisplay.scss';
import Timer from '../Timer';
import { ReactComponent as Watch } from '../../../assets/v2/stopwatch.svg';
import { SessionContext } from 'components/utils/SessionContext';
import { TutoContext } from 'components/utils/TutoContext';

function TimerDisplay(props: any) {

  const urlParams = new URLSearchParams(props.location.search);
  const forcedTimer: any = urlParams.get('timer');

  const { sessionDefinition, interfaceFinished, currentInterfaceIndex, setNextInterfaceUnlock,
    currentProfil, setCurrentProfil, setProfilSession, timeFinished,
    setTimeFinished, timerRef } = useContext(SessionContext);


  const { showTuto } = useContext(TutoContext);

  const finishInterface = () => {
    if (sessionDefinition) {
      interfaceFinished(undefined, sessionDefinition, currentProfil, currentInterfaceIndex,
        setCurrentProfil, setProfilSession, setNextInterfaceUnlock);
      setTimeFinished(true);
    }
  }
  console.log(props.loadPageDisplayed);
  return (
    <div className='ks-timer-display'>
      {currentInterfaceIndex > -1 && (<>
        <Watch />
        {sessionDefinition && sessionDefinition.interface && sessionDefinition.interface[currentInterfaceIndex] ? (
          <>
            <div className='ks-counter'>
              {(sessionDefinition.interface[currentInterfaceIndex].timerMinutes > 0
                || sessionDefinition.interface[currentInterfaceIndex].timerSeconds > 0) ?
                (!timeFinished ? (
                  <div className='ks-minutesSeconds'>
                    <Timer ref={timerRef} key={currentInterfaceIndex} pause={showTuto || !currentProfil || props.loadPageDisplayed} initialMinute={forcedTimer ? 0 : (sessionDefinition.interface[currentInterfaceIndex].timerMinutes > 0 ? sessionDefinition.interface[currentInterfaceIndex].timerMinutes : 0)}
                      initialSeconds={forcedTimer ? forcedTimer : (sessionDefinition.interface[currentInterfaceIndex].timerSeconds > 0 ? sessionDefinition.interface[currentInterfaceIndex].timerSeconds : 0)}
                      onTimeover={finishInterface} />
                  </div>
                ) : (
                  <div className='ks-minutesSeconds ks-timerFinished'>00:00</div>
                )
                ) : (<div className='ks-minutesSeconds ks-timerFinished'>00:00</div>)}
            </div>
          </>
        ) : (<div className='ks-minutesSeconds ks-timerFinished'>00:00</div>)}
      </>)}
    </div>
  );
}

export default TimerDisplay;
