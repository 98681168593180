import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import BaseDS from '../../datastore/BaseDS';
import { TChapter, TSession, TUserInfo } from '../../types';
import FileLoadEvent from '../utils/FileLoadEvent';
import { ReactComponent as Stars } from '../../assets/v2/sessions/stars.svg';
import './css/Phase3.scss';
import { Link, useHistory } from 'react-router-dom';
import SVG from 'components/elements/SVG';
import { ReactComponent as DefaultImage } from '../../assets/v2/sessions/default-homepage-image.svg';
import { ReactComponent as DownloadBtn } from '../../assets/v2/sessions/download-btn.svg';
import { ReactComponent as RedoTest } from '../../assets/v2/sessions/btn-redo-test.svg';
import { ReactComponent as NavArrow } from '../../assets/v2/sessions/nav-arrow.svg';
import { ReactComponent as NavArrowRight } from '../../assets/v2/sessions/nav-arrow-right.svg';
import { ReactComponent as IconRevision } from '../../assets/v2/sessions/icon-revision.svg';
import { ReactComponent as IconRevisionBlocked } from '../../assets/v2/sessions/icon-revision-blocked.svg';
import UserDS from 'datastore/UserDS';
import AsyncLink from 'components/elements/AsyncLink';
import useResponsiveSize from 'components/utils/useResponsiveSize';
import { phaseId } from 'App';
import { svgPerChapter } from 'components/ChapterTheme';
import { unblockedUsers } from 'utils/Constants';

const removeAllDash = (value: string) => {
  return value ? value.replace(/-/g, '') : value;
}

function Phase3(props: any) {
  const [chapterDetails, setChapterDetails] = useState<TChapter>();
  const [selectedChaper, setSelectedChapter] = useState<string>('1');
  const [currentSessionId, setCurrentSessionId] = useState<string>();
  const [currentProfil, setCurrentProfil] = useState<TUserInfo>();
  const scrollableDivRef = useRef<any>();
  const unlockLessons = localStorage.getItem('unlockLessons');
  const history = useHistory();
  const loaderSize = useResponsiveSize(80);

  useEffect(() => {
    if (props.match.params.chapterKey) {
      setSelectedChapter(props.match.params.chapterKey);
    }
  }, [props.match.params.chapterKey])

  const scrollToCurrentSession = useCallback(() => {
    scrollableDivRef.current?.scroll({
      left: (document.getElementById('sessionFrame_' + currentSessionId)?.offsetLeft || 110) - 110,
      behavior: 'smooth'
    });
  }, [currentSessionId]);

  const sessionDisabled = useCallback((userInfo: TUserInfo, sessionInfo: TSession) => {
    return (!userInfo || !userInfo[sessionInfo.chapterId] || !userInfo[sessionInfo.chapterId][sessionInfo.sessionKey])
      && sessionInfo.sessionKey !== 1 && unlockLessons !== '1' && unblockedUsers.indexOf(userInfo?.profilKey) === -1 && unblockedUsers.indexOf(userInfo?.name) === -1;
  }, [unlockLessons])

  useEffect(() => {
    if (selectedChaper) {
      setChapterDetails(undefined);
      FileLoadEvent.triggerFileToLoad('loadingphase3chapter' + selectedChaper);
      UserDS.getCurrentUserProfil((profil: TUserInfo) => {
        setCurrentProfil(profil);
        setTimeout(() => FileLoadEvent.triggerFileLoaded('loadingCurrentProfil'), 500);
        BaseDS.getFullChapterById(phaseId + '-' + selectedChaper).then((data: TChapter) => {
          setChapterDetails(data);
          if (data && data.session) {
            let firstDisabledLessonIndex: number = 0;
            for (let i = 0; i < data.session.length; i++) {
              if (sessionDisabled(profil, data.session[i])) {
                firstDisabledLessonIndex = i;
                break;
              }
            }
            setCurrentSessionId(data.session[(firstDisabledLessonIndex > 1 ? firstDisabledLessonIndex - 1 : 0)].id);
          }
          setTimeout(scrollToCurrentSession, 500);
          setTimeout(() => FileLoadEvent.triggerFileLoaded('loadingphase3chapter'), 500);
        });
      })
    }
  }, [selectedChaper, scrollToCurrentSession, sessionDisabled]);

  const hasStar = (practiseResult: any) => {
    return practiseResult && practiseResult?.filter((pr: any) => pr.result).length === practiseResult.length;
  }

  const calculateNbStars = (sessionTemp: TSession) => {
    return currentProfil && currentProfil[sessionTemp.chapterId] && currentProfil[sessionTemp.chapterId][sessionTemp.sessionKey]
      ? currentProfil[sessionTemp.chapterId][sessionTemp.sessionKey].filter((t: any) => hasStar(t.practiseResult)).length
      : 0
  }

  const allTestDone = (sessionTemp: TSession) => {
    return currentProfil && currentProfil[sessionTemp.chapterId] && currentProfil[sessionTemp.chapterId][sessionTemp.sessionKey]
      ? currentProfil[sessionTemp.chapterId][sessionTemp.sessionKey].filter((t: any) => t.practiseResult).length === 3
      : 0
  }

  const calculateLoaderSize = (optimalSize: number) => {
    const screenDiv = document.getElementById('mainContentScreen');
    return screenDiv ? optimalSize * screenDiv.clientWidth / 1039 : optimalSize;
  }

  const scrollLeft = () => {
    scrollableDivRef.current.scroll({
      left: scrollableDivRef.current.scrollLeft - calculateLoaderSize(750),
      behavior: 'smooth'
    });
  };

  const scrollRight = () => {
    scrollableDivRef.current.scroll({
      left: scrollableDivRef.current.scrollLeft + calculateLoaderSize(750),
      behavior: 'smooth'
    });
  };

  const isPublished = useCallback((sessionInfo: TSession) => {
    return sessionInfo.publicationDate
      && parseInt(removeAllDash(sessionInfo.publicationDate)) <= parseInt(removeAllDash(new Date().toISOString()));
  }, [])

  return (
    <>
      {chapterDetails && chapterDetails.session && chapterDetails.session?.length > 2 && (<>
        <NavArrow className="ks-chapter3-homepage-nav-left ks-clickable" onClick={scrollLeft} />
        <NavArrowRight className="ks-chapter3-homepage-nav-right ks-clickable" onClick={scrollRight} />
      </>)}
      <div ref={scrollableDivRef} id='ks-chapter3-homepage' className={'ks-chapter3-homepage chapter' + chapterDetails?.id}>
        <div id='ks-chapter3-homepage-container' className={'ks-chapter3-homepage-container ' + ((!currentProfil || !chapterDetails) && 'loading')}>
          {currentProfil && chapterDetails ? (<>
            {chapterDetails.session?.map((sessionTemp) => (
              <div id={'sessionFrame_' + sessionTemp.id} key={sessionTemp.id} className={`ks-chapter3-homepage-session ${sessionTemp.sessionType === 'revision' && 'ks-session-revision'} ${(sessionDisabled(currentProfil, sessionTemp) || !isPublished(sessionTemp)) && 'locked'}`}>
                <Stars className={`ks-chapter3-homepage-session-stars star-enabled-${calculateNbStars(sessionTemp)}`} />
                {sessionTemp.sessionType === 'revision' && (<>
                  {sessionDisabled(currentProfil, sessionTemp) || !isPublished(sessionTemp) ? (
                    <IconRevisionBlocked className="ks-chapter3-homepage-session-revision-icon" />
                  ) : (<>
                    <IconRevision className="ks-chapter3-homepage-session-revision-icon" />
                  </>)}
                </>)}
                <SVG src={sessionTemp.desktopTitleImage} className="ks-chapter3-homepage-session-title" />
                {sessionTemp.sessionType !== 'revision' && (<>
                  {sessionTemp.desktopHomepageImage ? (
                    <SVG src={sessionTemp.desktopHomepageImage} className="ks-chapter3-homepage-session-image" />
                  ) : (
                    <DefaultImage className="ks-chapter3-homepage-session-image" />
                  )}
                </>)}
                {sessionDisabled(currentProfil, sessionTemp) && (svgPerChapter(props.match.params.chapterKey || "none")[1])}
                <Link to={`/phase3/${sessionTemp.chapterId}/session/${sessionTemp.sessionKey}`} className={`mainBtn`} >
                  {!isPublished(sessionTemp) ? 'Prochainement' : (sessionDisabled(currentProfil, sessionTemp) ? 'Bloquée' : 'Commencer')}
                </Link>
                <AsyncLink fileKey={sessionTemp.pdfFile || ''}><DownloadBtn className={`ks-chapter3-homepage-pdf-btn ${sessionTemp.pdfFile ? 'ks-clickable' : 'ks-disabled'}`} /></AsyncLink>
                <RedoTest className={`ks-chapter3-homepage-redo-btn ks-clickable ${!allTestDone(sessionTemp) && 'ks-disabled'}`} onClick={() => {
                  history.push(`/phase3/${sessionTemp.chapterId}/session/${sessionTemp.sessionKey}/6`);
                }} />
              </div>
            ))}
          </>) : (<><ClipLoader color='white' size={loaderSize} /></>)}
        </div>
      </div>
      {svgPerChapter()[0]}
    </>
  );
}

export default Phase3;
