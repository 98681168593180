import { SVGProps } from "Interfaces/SharedInterfaces";
import React from "react";

const AlphabetLetter: React.FC<SVGProps> = (props: SVGProps) => {
  const { width, height, color, letter, xy = [44, 78] } = props;

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 48 115"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text
        id={letter}
        transform={`translate(${xy[0]} ${xy[1]})`}
        fill={color}
        text-align="right"
        fontSize="68"
        fontFamily="BahijMyriadArabic-Bold, Bahij Myriad Arabic"
        fontWeight="700"
      >
        <tspan x="-31.228" y="0">
          {letter}
        </tspan>
      </text>
    </svg>
  );
};

export default AlphabetLetter;
