import React, { useState } from 'react';
import './ExitProfilFullScreen.scss'
import { useHistory } from "react-router-dom";
import { ReactComponent as IconExit } from '../../assets/icon_exit.svg';
import { ReactComponent as IconProfilArrow } from '../../assets/icon_profil_arrow.svg';
import { ReactComponent as IconHome } from '../../assets/icon_home.svg';
import FullScreenBtn from './FullScreenBtn';
import { Auth } from 'aws-amplify';
import { TUserInfo } from '../../types';
import Sounds from '../utils/Sounds';

function ExitProfilFullScreen(props: { home?: string, destination?: string, profilName: string, profils?: TUserInfo[], selectProfil?: Function }) {
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const logout = async () => {
    Sounds.playButtonSound();
    await Auth.signOut();
		window.location.reload();
  }

  return (<>
    <div className={'ks-topNavigation' + (menuOpen ? " open" : "")}>
      {props.destination !== 'none' && (
        <IconExit className='ks-clickable ks-iconExit' onClick={() => { 
          Sounds.playButtonSound();
          props.destination ? history.push(props.destination) : history.goBack() }} />
      )}
      {props.home && (
        <IconHome className='ks-clickable ks-iconExit' onClick={() => { Sounds.playButtonSound(); history.push(props.home ? props.home : '') }} />
      )}
      {props.profilName && props.profilName !== '' ? (
        <div className={(props.profils && props.profils.length ? "ks-clickable " : '') + 'ks-usernameArrow'}
          onClick={() => { Sounds.playButtonSound(); if (props.profils && props.profils.length) setMenuOpen(!menuOpen) }}>
          <span className={(menuOpen ? " open" : "")}>
            {props.profilName}
          </span>
          {props.profils && props.profils.length && (<>
            <IconProfilArrow className="iconArrow" />
          </>
          )}
        </div>) : (
          <a href='/login' onClick={() => Sounds.playButtonSound()}>Se connecter</a>
        )}
      <FullScreenBtn />
    </div>
    {props.profils && props.profils.length && (
      <div className={"subMenu" + (menuOpen ? ' ks-subMenuOpen' : ' ks-subMenuClose')} >
        {props.profils.map((profil, index: number) => (
          <React.Fragment key={index}>
            {profil.profilKey !== props.profilName && (
              <div onClick={() => { Sounds.playButtonSound(); if (props.selectProfil) props.selectProfil(profil); setMenuOpen(!menuOpen) }}>
                <label>{profil.name || profil.profilKey}</label>
              </div>
            )}
          </React.Fragment>))}
        <div className="separator"><hr style={{ width: '85%' }} /></div>
        <div onClick={() => { Sounds.playButtonSound(); history.push('/profils') }} className="logout"><label>Gérer les profils</label></div>
        <div onClick={logout} className="logout"><label>Se déconnecter</label></div>
      </div>
    )}

  </>);
}

export default ExitProfilFullScreen;
