import React, { lazy } from 'react';
import UserDS from '../../datastore/UserDS';

const importView = (subreddit: string) =>
  lazy(() =>
    import(`./${subreddit}`)
  );

let View: any = {};

const BonusComponent = (props: any) => {
  const { currentProfil, setCurrentProfil, sessionDef, sessionTestKey } = props;

  const bonusResult = currentProfil[sessionDef.chapterId]
    && currentProfil[sessionDef.chapterId]['bonus']
    && currentProfil[sessionDef.chapterId]['bonus'][sessionDef.sessionKey]
    ? currentProfil[sessionDef.chapterId]['bonus'][sessionDef.sessionKey] : undefined
  const componentPromises = () => {
    if (!sessionDef.bonusType) return (<></>);
    const BonusView = View[sessionDef.bonusType] ? View[sessionDef.bonusType] : importView(sessionDef.bonusType);
    if(!View[sessionDef.bonusType]) View[sessionDef.bonusType] = BonusView;
    return <BonusView {...props} onBonusExit={onBonusExit} />;
  }

  const onBonusExit = (answer: any) => {
    if (!bonusResult && !sessionTestKey) {
      UserDS.completeBonus(currentProfil.profilKey, sessionDef.chapterId, sessionDef.sessionKey, { answer: answer })
        .then((newProfil: any) => {
          setCurrentProfil(newProfil);
        })
    }
  }
  return (<>
    <React.Suspense fallback='Loading Bonus...'>
      {componentPromises()}
    </React.Suspense>
  </>);
};

export default function Bonus(props: any) {
  return (
    <BonusComponent {...props} />
  );
}
