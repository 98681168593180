import "./HomepageLeftAreaV3.scss"
import { ReactComponent as AwladLogo } from '../../assets/v3/awlad-school-logo.svg';
import { ReactComponent as EmptyAvatar } from '../../assets/v3/empty-avatar.svg';
import { ReactComponent as BDouinLogo } from '../../assets/standalone/bdouin-logo.svg';
import { ReactComponent as TriangleIcon } from '../../assets/v3/white-triangle.svg';
import { ReactComponent as SettingsIcon } from '../../assets/v3/settings-icon.svg';
import { ReactComponent as ImageNotAuth } from '../../assets/standalone/left-img-notauth.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from "react";
import { ChapterContext } from "components/utils/ChapterContext";
import { TUserInfo } from "types";
import UserDS from "datastore/UserDS";
import SVG from "components/elements/SVG";
import { ClipLoader } from 'react-spinners';
import { BlockerContext, steps } from "components/utils/BlockerContext";
import FileDS from "datastore/FileDS";
import { UserContext } from "components/utils/UserContext";
import { ReactSVG } from "react-svg";
import { Auth } from "aws-amplify";

function HomepageLeftAreaV3() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>();
  const [selectedProfilIndex, setSelectedProfilIndex] = useState<number>();
  const [profils, setProfils] = useState<TUserInfo[]>([]);
  const { setCurrentProfil, currentProfil } = useContext(ChapterContext);
  const { block, setBlock, setUnblockStep } = useContext(BlockerContext);
  const { isUserAuthenticated } = useContext(UserContext);

  useEffect(() => {
    if (isUserAuthenticated) {
      UserDS.getCurrentUserProfil((profil: TUserInfo, error?: any) => {
        if (!error) {
          setCurrentProfil(profil);
          UserDS.getProfils().then((profilList: TUserInfo[]) => {
            profilList.sort((a: any, b: any) => a.creationDate - b.creationDate);
            setProfils(profilList);
            setSelectedProfilIndex(profilList?.map((p, index) => ({ profilKey: p.profilKey, index })).filter((p) => p.profilKey === profil.profilKey)[0].index)
          });
        } else {
          setProfils([]);
        }
        setLoading(true);
      });
    }
  }, [setCurrentProfil, isUserAuthenticated]);

  useEffect(() => {
    if (profils.length) {
      if (selectedProfilIndex !== undefined && profils[selectedProfilIndex] !== currentProfil && currentProfil.profilKey) {
        profils[selectedProfilIndex] = currentProfil;
      } else if (profils && !block && (!currentProfil || !currentProfil.profilKey)) {
        setCurrentProfil(profils[0]);
        setSelectedProfilIndex(0);
      }
    }
  }, [setCurrentProfil, block, currentProfil, profils, selectedProfilIndex]);

  const changeCurrentProfil = (profil: TUserInfo | undefined, index: number) => {
    if (profil) {
      UserDS.setCurrentUserProfil(profil.profilKey);
    } else {
      setUnblockStep(steps.STUDENT_PROFIL);
      setBlock(true)
    }
    setCurrentProfil(profil || {});
    setSelectedProfilIndex(index);
  }

  const getProfil = (index: number): any => {
    return profils[index]
  }

  const logout = async () => {
    await Auth.signOut();
    document.location.href = '/';
  }

  return (<>
    <AwladLogo className={'ks-awlad-logo ' + (block && 'ks-disabled')} onClick={() => history.push('/')} />

    {isUserAuthenticated && (
      <div className='ks-awlad-profiles'>
        <>
          {loading ? (<>
            {Array(4).fill(1).map((one, index) => (
              <div key={"profile_" + index} className={"ks-awlad-profiles-item " + (selectedProfilIndex === index && "selected")}
                onClick={() => {
                  if (selectedProfilIndex === index) {
                    setUnblockStep(steps.STUDENT_PROFIL);
                    setBlock(true)
                  } else {
                    changeCurrentProfil(profils && getProfil(index), index)
                  }
                }}>
                <SettingsIcon className="ks-awlad-profiles-item-setting-icon" />
                {profils && getProfil(index) ? (<>
                  {getProfil(index).iconAvatar && getProfil(index).iconAvatar.indexOf(".svg") > -1 ? (<>
                    {getProfil(index).iconAvatar.indexOf("man_avatar-sample.svg") > -1 ? (
                      <ReactSVG src={FileDS.getHoopowURLFile(getProfil(index).iconAvatar)} className="ks-awlad-profiles-item-img" />
                    ) : (
                      <SVG src={getProfil(index).iconAvatar} className="ks-awlad-profiles-item-img" />
                    )}
                  </>) : (<img src={FileDS.getHoopowURLFile(getProfil(index).iconAvatar)} className="ks-awlad-profiles-item-img" alt={(getProfil(index).name || getProfil(index).profilKey) + " Avatar"} />)}
                  <span>
                    <TriangleIcon className="ks-awlad-profiles-item-triangle" />
                    {getProfil(index).name || getProfil(index).profilKey}
                  </span>
                </>) : (<>
                  <EmptyAvatar className="ks-awlad-profiles-item-img" />
                  <span>
                    <TriangleIcon className="ks-awlad-profiles-item-triangle" />
                    ajouter
                  </span>
                </>)}
              </div>
            ))}
          </>) : (<div className="ks-awlad-profiles-loader"><ClipLoader color="white" /></div>)}
        </>
      </div>
    )}
    <div className={"ks-awlad-main-nav " + (isUserAuthenticated && 'authenticated')}>
      <Link to="/" className={" " + (location.pathname === '/' && "selected")}>ACCUEIL</Link>

      {!isUserAuthenticated && (
        <Link to="/aboutus" className={" " + (location.pathname === '/aboutus' && "selected")}>À propos</Link>
      )}
      <Link to="/shop" className="ks-disabled">LA BOUTIQUE</Link>
      <Link to="/contactus" className={" " + (location.pathname === '/contactus' && "selected")}>Contact</Link>

      {isUserAuthenticated && (
        <button onClick={logout}>SE DÉCONNECTER</button>
      )}
    </div>
    {!isUserAuthenticated && (
      <ImageNotAuth className='ks-awlad-info-btn' />
    )}
    <div className='ks-awlad-mention'>
      <BDouinLogo className='ks-bdouin-logo' />
      <p>Logiciel réalisé et illustré par le studio <a href="https://www.bdouin.com">BDouin</a></p>
    </div>
  </>);
}

export default HomepageLeftAreaV3;
