import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import * as serviceWorker from './serviceWorker';
import GA4React from 'ga-4-react';
import './i18n'

Amplify.configure(awsconfig);

(async () => {
  if (process.env.REACT_APP_GA_ID
    && process.env.REACT_APP_GA_ID !== '') {
    console.log('Initialise google analytics with the following code => ' + process.env.REACT_APP_GA_ID);
    const ga4react = new GA4React(document.domain === "awlad-school.com" ? process.env.REACT_APP_AWLAD_ONLY_GA_ID : process.env.REACT_APP_GA_ID);
    try {
      await ga4react.initialize();
    } catch (err) {
      console.error(err);
    }
  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("kalimatSchool")
  );
})();

if (process.env.REACT_APP_CACHE_ENABLED === "1") {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}

