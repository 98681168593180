import "./styles.scss";
import {
  setAllSent,
  setLetters,
  setIsRemake,
  setCorrected,
} from "components/phases/Contexts/actions/LetterAcions";
import Letter from "components/elements/Letter/NotCorrected";
import CorrectedLetter from "components/elements/Letter/Corrected";
import useAlphabets from "components/phases/Contexts/hooks/useAlphabets";
import { setCurrentLetter } from "components/phases/Contexts/actions/CurrentLetterActions";
import { Letter as LetterType } from "components/phases/Contexts/interfaces";
import useCurrentLetter from "components/phases/Contexts/hooks/useCurrentLetter";
import useDispatchCurrentLetter from "components/phases/Contexts/hooks/useDispatchCurrentLetter";
import { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import useDispatchLetter from "components/phases/Contexts/hooks/useDispatchLetter";
import RemakeAudio from "assets/Icons/RemakeAudio";
import { useState } from "react";
export default function Alphabets({ setModalTodisplay, loading }: any) {
  const dispatchLetter = useDispatchLetter();
  const {
    letters,
    isRemake,
    isCorrected,
    correctedLetters,
    notRecordedLetters,
  } = useAlphabets();
  const { currentLetter } = useCurrentLetter();
  const dispatchCurrentLetter = useDispatchCurrentLetter();
  const [displaRem, setDisplayRem] = useState(false);
  const [dispalyRemake, setDisplayRemake] = useState(false);
  const setLetter = (letter: LetterType) => {
    setCurrentLetter({ dispatchCurrentLetter, letter });
  };

  const isCorrectedLetters = correctedLetters.length !== 0;

  useEffect(() => {
    if (isRemake && notRecordedLetters.length === 0) {
      setModalTodisplay("AllSent");
      setAllSent({ dispatchLetter });
    }
    if (isCorrectedLetters) {
      setDisplayRem(true);
    }
  }, [
    notRecordedLetters,
    dispatchLetter,
    loading,
    isRemake,
    letters,
    setModalTodisplay,
    isCorrectedLetters,
  ]);

  const handleRemakeAudios = () => {
    if (!dispalyRemake) {
      const updatedLetters: any = [...notRecordedLetters];
      correctedLetters.forEach((elem) => {
        if (elem.status !== "approved") {
          if (!notRecordedLetters.some((letter) => letter.name === elem.name)) {
            const newElem = { ...elem, isRecorded: true, isSent: true };
            updatedLetters.push(newElem);
          }
        }
      });

      setDisplayRemake(true);
      setCorrected({ dispatchLetter, payload: false });
      setIsRemake({ dispatchLetter, payload: true });
      setLetters({ dispatchLetter, payload: updatedLetters });

      setCurrentLetter({
        dispatchCurrentLetter,
        letter: updatedLetters[0],
      });
    } else {
      setDisplayRemake(false);
      setCorrected({ dispatchLetter, payload: true });
      setIsRemake({ dispatchLetter, payload: false });
      setLetters({ dispatchLetter, payload: correctedLetters });
      setCurrentLetter({
        dispatchCurrentLetter,
        letter: correctedLetters[0],
      });
    }
  };

  useEffect(() => {
    if (letters.length !== 0 && !currentLetter.id) {
      const index = letters.findIndex((letter) => letter.isSent === false);
      if (index !== -1) {
        setCurrentLetter({ dispatchCurrentLetter, letter: letters[index] });
      } else {
        setCurrentLetter({ dispatchCurrentLetter, letter: letters[0] });
      }
    }
  }, [letters, dispatchCurrentLetter, currentLetter]);
  return (
    <div
      className={`alphabets-container ${
        letters.length === 0 ? "loading" : ""
      } ${letters.length < 8 ? "flex-start" : ""}`}
    >
      {!isCorrected ? (
        letters.length === 0 ? (
          <div style={{ margin: "12rem 2rem" }}>
            <ClipLoader color="black" size={60} />
          </div>
        ) : (
          letters
            .slice(0, 14)
            .map((item: any, id: number) => (
              <Letter
                key={id}
                letter={item}
                setLetter={setLetter}
                currentLetter={currentLetter}
              />
            ))
        )
      ) : (
        letters
          .slice(0, 14)
          .map((item: any, id: number) => (
            <CorrectedLetter
              key={id}
              setLetter={setLetter}
              letter={item}
              currentLetter={currentLetter}
            />
          ))
      )}
      {displaRem ? (
        <button onClick={handleRemakeAudios} className="remake-audio">
          {!dispalyRemake ? (
            <RemakeAudio />
          ) : (
            <span>REVENIR AUX CORRECTIONS</span>
          )}
        </button>
      ) : null}
    </div>
  );
}
