import HomepageLeftAreaV3 from "./HomepageLeftAreaV3";

function HomepageLeftArea() {

  return (<>
      <HomepageLeftAreaV3 />
  </>);
}

export default HomepageLeftArea;
