let dropSound: HTMLAudioElement | undefined = undefined;
let rightSound: HTMLAudioElement | undefined = undefined;
let wrongSound: HTMLAudioElement | undefined = undefined;
let loadingSound: HTMLAudioElement | undefined = undefined;

const init = () => {

  loadingSound = new Audio('/audios/loading_sound.mp3');
  loadingSound.volume = 1;
  loadingSound.loop = true;

  dropSound = new Audio('/audios/next_back_sound.mp3');
  dropSound.volume = 0.5;

  rightSound = new Audio('/audios/right.mp3');
  rightSound.volume = 0.6;

  wrongSound = new Audio('/audios/wrong.mp3');
  wrongSound.volume = 1;

}

const playSound = (sound: HTMLAudioElement | undefined, callback?: Function) => {
  if (sound) {
    sound.play().then(() => {
      if (callback) setTimeout(() => callback(), 200);
    }).catch(() => {
      console.log('error !!!');
      if (callback) callback();
    });
  } else {
    if (callback) callback();
  }
}

export default class Sounds {

  static init() { init(); }

  static playNextSound(callback?: Function) {
    playSound(dropSound, callback);
  }

  static playRightSound(callback?: Function) {
    playSound(rightSound, callback);
  }

  static playWrongSound(callback?: Function) {
    playSound(wrongSound, callback);
  }

  static playLoadingSound(callback?: Function) {
    playSound(loadingSound, callback);
  }

  static playButtonSound(callback?: Function) {
    // playSound(buttonSound, callback);
    if (callback) callback();
  }

  static stopLoadingSound() {
    try {
      if (loadingSound) {
        loadingSound.pause();
      }
    } catch (err) {
      console.log(err);
    }
  }
}