import { useEffect, useCallback, useContext, useRef } from 'react';
import './css/SessionLayoutV2.scss';
import { TReport, TSession, TUserInfo } from '../types';
import BaseDS from '../datastore/BaseDS';
import UserDS from '../datastore/UserDS';
import Tuto from './elements/Tuto';
import Screen from './screens/Screen';
import { useHistory } from "react-router-dom";
import FileLoadEvent from './utils/FileLoadEvent';
import { SessionContext } from './utils/SessionContext';
import { TutoContext } from './utils/TutoContext';
import LoadingPage from './elements/LoadingPage';

const goBackPath = (history: any, chapterId: string) => {
  return history.location.state && history.location.state.from ? history.location.state.from : '/phase3/' + chapterId.split('-')[1];
}

const goNextActivity = (currentInterfaceIndex: number, sessionDefinition: TSession, setCurrentInterfaceIndex: Function,
  currentProfil: TUserInfo, setTimeFinished: Function, history: any) => {
  if (currentInterfaceIndex < sessionDefinition.interface.length - 1) {
    setTimeFinished(false);
    setCurrentInterfaceIndex(currentInterfaceIndex + 1)
  } else {
    const nbSession = Object.keys(currentProfil[sessionDefinition.chapterId]).filter(k => !isNaN(parseInt(k))).length;
    console.log('currentProfil', currentProfil[sessionDefinition.chapterId]);
    console.log('nbSession => ' + nbSession);
    console.log('sessionDefinition.sessionKey  =>  ' + sessionDefinition.sessionKey);
    if (sessionDefinition.isLast) {
      history.push(`/`);
    } else {
      history.push(goBackPath(history, sessionDefinition.chapterId));
    }
  }
}

function SessionLayoutV2(props: any) {

  const { sessionDefinition, interfaceFinished, setSessionDefinition, currentInterfaceIndex, setCurrentInterfaceIndex,
    setNextInterfaceUnlock, setNbLoadIndex, currentInterfaceState, setCurrentInterfaceState,
    currentProfil, setCurrentProfil, setProfilSession, screen, setScreen, setCurrentScreen,
    setShowHelp, tuto, setTuto, currentTutoIndex, setCurrentTutoIndex,
    setTimeFinished, timerRef, setCurrentScreenInfo, nbLoadIndex, setLoadPageDisplayed } = useContext(SessionContext);

  const { showTuto, setShowTuto, setPlayTuto } = useContext(TutoContext);

  const history = useHistory<any>();
  const urlParams = new URLSearchParams(props.location.search);
  const showScreenList: any = urlParams.get('screensList');
  const chapterId = props.match.params.chapterId;
  const sessionKey = props.match.params.sessionKey;
  const interfaceKey = useRef<number>(parseInt(props.match.params.interfaceKey || -1));

  const goToInterface = useCallback((index: number) => {
    setCurrentInterfaceIndex(index);
    setNbLoadIndex((prev: number) => prev + 1);
    setNextInterfaceUnlock(false);
  }, [setCurrentInterfaceIndex, setNbLoadIndex, setNextInterfaceUnlock]);

  useEffect(() => {
    console.log('Load current user profil');
    FileLoadEvent.triggerFileToLoad('loadingCurrentProfil');
    UserDS.getCurrentUserProfil((profil: TUserInfo) => {
      setCurrentProfil(profil);
      setTimeout(() => FileLoadEvent.triggerFileLoaded('loadingCurrentProfil'), 500);
    })
  }, [setCurrentProfil]);

  useEffect(() => {
    if (chapterId && (sessionKey || sessionKey === 0)) {
      FileLoadEvent.triggerFileToLoad('Load session');
      BaseDS.getFullSessionById(chapterId, sessionKey).then((data: TSession) => {
        setSessionDefinition(data);
        setTimeout(() => FileLoadEvent.triggerFileLoaded('Load session'), 500);
      });
    }
  }, [chapterId, sessionKey, setSessionDefinition]);

  useEffect(() => {
    const currentChapterId = sessionDefinition?.chapterId;
    const currentSessionKey = sessionDefinition?.sessionKey;
    if (currentProfil && currentChapterId && currentSessionKey
      && currentProfil[currentChapterId] && currentProfil[currentChapterId][currentSessionKey]) {
      const currentInterface = currentProfil[currentChapterId][currentSessionKey][currentInterfaceIndex];
      setCurrentInterfaceState(currentInterface ? currentInterface.state : '');
    } else {
      setCurrentInterfaceState('');
    }
  }, [currentInterfaceIndex, sessionDefinition, currentProfil, setCurrentInterfaceState])

  useEffect(() => {
    setShowTuto(undefined);
    setTuto(undefined);
  }, [currentInterfaceIndex, setShowTuto, setTuto])

  useEffect(() => {
    if (currentProfil && sessionDefinition) {
      console.log('Calculate the session state for the user');
      const currentChapterId = sessionDefinition?.chapterId;
      const currentSessionKey = sessionDefinition?.sessionKey;
      if (currentProfil[currentChapterId]
        && currentProfil[currentChapterId][currentSessionKey]
        && currentProfil[currentChapterId][currentSessionKey].length) {
        setProfilSession(currentProfil[currentChapterId][currentSessionKey])
      } else {
        const defaultInterface = sessionDefinition?.interface[0];
        if (!currentProfil[currentChapterId]) currentProfil[currentChapterId] = {};
        currentProfil[currentChapterId][currentSessionKey] = [defaultInterface];
        setProfilSession([defaultInterface]);
        setCurrentProfil(currentProfil);
      }
      const interfaceIndex = currentProfil[currentChapterId][currentSessionKey].findIndex((i: any) => i && (interfaceKey.current === i.interfaceKey || i.state !== 'completed'));
      interfaceKey.current = -1;
      setCurrentInterfaceIndex(interfaceIndex > -1 ? interfaceIndex : 0);
    }
  }, [sessionDefinition, currentProfil, setCurrentInterfaceIndex, setCurrentProfil, setProfilSession, interfaceKey]);

  const openTuto = tuto && tuto.tutoSteps && ((tuto.displayTutoOnLoad && currentInterfaceState !== 'completed' && showTuto === undefined) || showTuto) && tuto.tutoSteps.length;

  useEffect(() => {
    if(openTuto && !showTuto) {
      setShowTuto(openTuto);
    }
  }, [openTuto, setShowTuto, showTuto])
  useEffect(() => {
    if (currentProfil && sessionDefinition && currentInterfaceIndex > -1 && sessionDefinition.interface[currentInterfaceIndex]) {
      console.log("interfaceIndex from screen render => " + currentInterfaceIndex);
      const screenType = sessionDefinition.interface[currentInterfaceIndex].screenType;
      console.log('Set screen type and helper');
      console.log('Go to next interface => ' + screenType);
      setScreen(
        <Screen key={currentInterfaceIndex} interfaceDef={sessionDefinition?.interface[currentInterfaceIndex]}
          screenType={sessionDefinition.interface[currentInterfaceIndex].screenType}
          sessionDef={sessionDefinition}
          currentProfil={currentProfil}
          history={history}
          setShowHelp={setShowHelp}
          setTuto={setTuto}
          currentTutoIndex={currentTutoIndex}
          setShowTuto={setShowTuto}
          setCurrentScreen={setCurrentScreen}
          loadingProfil={!currentProfil || props.externalLoadPageDisplayed}
          tutoOpen={openTuto}
          showScreenList={showScreenList}
          loadPageDisplayed={props.externalLoadPageDisplayed}
          sessionProfil={currentProfil[sessionDefinition.chapterId][sessionDefinition.sessionKey]}
          setCurrentScreenInfo={setCurrentScreenInfo}
          interfaceFinished={(practiseResult?: TReport[], callback?: Function) => {
            if (!timerRef || !timerRef.current || timerRef.current.isFinished()) {
              interfaceFinished(practiseResult, sessionDefinition, currentProfil, currentInterfaceIndex,
                setCurrentProfil, setProfilSession, setNextInterfaceUnlock, callback, true)
              return true;
            } else {
              return false;
            }
          }}
          goNextActivity={() => goNextActivity(currentInterfaceIndex, sessionDefinition, goToInterface,
            currentProfil, setTimeFinished, history)}
          timerRef={timerRef} />
      )
    }
  }, [setCurrentProfil, setCurrentScreenInfo, interfaceFinished, setCurrentScreen, setScreen, setShowHelp, setShowTuto, setTimeFinished, setTuto, timerRef, currentInterfaceIndex, showScreenList, currentTutoIndex, sessionDefinition, currentProfil, setNextInterfaceUnlock, setProfilSession, goToInterface, history, openTuto, props.isV2, props.externalLoadPageDisplayed]);

  useEffect(() => {
    setTimeFinished(false);
    if (timerRef && timerRef.current) {
      timerRef.current?.reset();
    }
  }, [currentInterfaceIndex, setTimeFinished, timerRef]);

  return (
    <>
      <LoadingPage key={nbLoadIndex} setLoadPageDisplayed={setLoadPageDisplayed} className="ks-sessionLayoutV2-loading" maxLoadingTimeInSec={3000} />
      {currentInterfaceIndex > -1 && (<>
        <div id='screen' className={`ks-sessionLayout main-chapter${sessionDefinition?.chapterId} ${sessionDefinition?.interface[currentInterfaceIndex] && sessionDefinition?.interface[currentInterfaceIndex].type} ${sessionDefinition?.interface[currentInterfaceIndex] && sessionDefinition?.interface[currentInterfaceIndex].screenType} ks-${sessionDefinition?.phaseType}`}>
          {tuto && tuto.tutoSteps?.length && (
            <Tuto steps={tuto.tutoSteps} arrowType={tuto.arrowType} onAudioEnded={() => setPlayTuto(false)} setCurrentTutoIndex={setCurrentTutoIndex} open={openTuto} loadingProfil={!currentProfil || props.externalLoadPageDisplayed} />
          )}
          <div className='ks-mainPanel'>
            <div className={`ks-mainScreen`}>
              {screen}
            </div>
          </div>
        </div>
      </>)}
    </>
  );
}

export default SessionLayoutV2;
