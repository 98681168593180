import { ReactComponent as AwladLogo } from 'assets/v3/awlad-school-logo.svg';
import BaseDS from "datastore/BaseDS";
import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import '../css/UnsubscribeProgressionEmailsPage.scss';
import { AmplifyLoadingSpinner } from '@aws-amplify/ui-react';

export const UnsubscribeProgressionEmailsPage = () => {
  const [isUnsubscriptionLoading, setIsUnsubscriptionLoading] = useState(false)
  const [isUnsubscriptionFailed, setIsUnsubscriptionFailed] = useState(false)
  const [isUnsubscriptionSuccessful, setIsUnsubscriptionSuccessful] = useState(false)

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const history = useHistory();

  const t = queryParams.get('t')

  const onPositiveClick = () => {
    if(!t) return

    if(window.confirm('Êtes-vous sûr de vouloir vous désinscrire des emails de suivi pour ce profil ?')) {
      setIsUnsubscriptionLoading(true)
      BaseDS.unsubscribeFromProgressionEmails({
        token: t
      }).then(() => {
        setIsUnsubscriptionSuccessful(true)
      }).catch(() => {
        setIsUnsubscriptionFailed(true);
      })
      .finally(() => {
        setIsUnsubscriptionLoading(false)
      })
    }
  }

  const onNegativeClick = () => {
    history.push('/')
  }
  
  const onContactClick = () => {
    history.push('/contactus')
  }

  return (
    <div style={{ background:'#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw', padding: '2rem', boxSizing: 'border-box' }}>
      <div style={{ background: '#fff', borderRadius: '18px', padding: '2rem', textAlign: 'center', maxWidth: '600px' }}>
        <AwladLogo className={'ks-awlad-logo'} />
        {!t ? (
          <h1 style={{ fontSize: '1.5rem' }}>Ce lien est invalide.</h1>
        ) : isUnsubscriptionFailed ? (<>
          <h1 style={{ fontSize: '1.5rem' }}>Une erreur est survenue. Veuillez réessayer et nous contacter si le problème persiste.</h1>
          <button onClick={onContactClick} style={{ backgroundColor: '#51AABC', color: '#fff', padding: '1rem', textAlign: 'center', border: 'none', cursor: 'pointer', margin: '.5rem', borderRadius: '10px', flexGrow: 1, fontSize: '1rem' }}>
            Nous contacter
          </button>
        </>) :isUnsubscriptionSuccessful ? (<>
          <h1 style={{ fontSize: '1.5rem' }}>Votre désinscription a bien été prise en compte !</h1>
          <p style={{ lineHeight: '1.2em' }}>Vous ne recevrez plus les e-mails de suivi de la progression de ce profil.</p>
        </>) : (<>
          <h1 style={{ fontSize: '1.5rem' }}>Voulez-vous vraiment vous désinscrire des emails de suivi de la progression de ce profil ?</h1>
          <p style={{ lineHeight: '1.2em' }}>Les e-mails de suivi vous permettent d'obtenir un récapitulatif hebdomadaire de votre progression et vous encouragent à garder le rythme !</p>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '1rem', marginTop: "1.5rem" }}>
            <button onClick={onPositiveClick} style={{ position: 'relative', backgroundColor: '#efefef', color: '#000', padding: '1rem', textAlign: 'center', border: 'none', cursor: 'pointer', borderRadius: '10px', flexGrow: 1, fontSize: '1rem', height: '50px' }}>
              <div style={{ opacity: isUnsubscriptionLoading ? 1 : 0, position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <AmplifyLoadingSpinner style={{ height: "1em" }} />
              </div>
              <div style={{ opacity: isUnsubscriptionLoading ? 0 : 1 }}>
                Oui
              </div>
            </button>
            <button onClick={onNegativeClick} style={{ backgroundColor: '#51AABC', color: '#fff', padding: '1rem', textAlign: 'center', border: 'none', cursor: 'pointer', borderRadius: '10px', flexGrow: 1, fontSize: '1rem', height: '50px' }}>
              Non
            </button>
          </div>
        </>)}
      </div>
    </div>
  )
}