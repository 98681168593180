import { useContext, useEffect, useState } from 'react';
import './LayoutV2.scss';
import './SpecificApp.scss';
import Sounds from 'components/utils/Sounds';
import BlockedModal from 'components/elements/common/freemium/BlockedModal';
import { BlockerContext } from 'components/utils/BlockerContext';
import Cookies from 'components/elements/common/Cookies';

function LayoutV2(props: { children: any, location?: any, className?: string, menuOpen?: boolean, isFullScreen?: boolean, isAuthenticated: boolean, leftArea: any }) {

  const urlParams = new URLSearchParams(props.location.search);
  const unlockLessons: any = urlParams.get('unlockLessons');
  const [blockActions, setBlockActions] = useState<boolean>();
  const { block, setBlock, unblockStep, setUnblockStep, nextStep } = useContext(BlockerContext);

  useEffect(() => {
    const blockActionsState = (e: any) => {
      setBlockActions(e.type === 'playingSound');
    }
    window.addEventListener('playingSound', blockActionsState);
    window.addEventListener('stopSound', blockActionsState);
    Sounds.init();
    return () => {
      window.removeEventListener('playingSound', blockActionsState);
      window.removeEventListener('stopSound', blockActionsState);
    }
  }, []);

  useEffect(() => {
    document.body.classList.remove('hp-body-homepage-notconnected-cgu');
    document.body.classList.remove('hp-body-homepage-notconnected');
  }, []);

  useEffect(() => {
    if (unlockLessons !== undefined) {
      if (unlockLessons === '1') {
        localStorage.setItem('unlockLessons', '1');
      } else {
        localStorage.removeItem('unlockLessons');
      }
    }
  }, [unlockLessons]);

  return (
    <div className={`${props.isFullScreen ? 'fullscreen' : ''} ks-mainFrame ${props.className || ''} hp-layoutV2 ${process.env.REACT_APP_FEATURE_V3 === "1" && "hp-layoutV3"}`}>
      <div id='hp-leftArea' className='hp-leftArea'>
        {props.leftArea}
      </div>
      <div id='mainContentScreen' className='ks-content'>
        {props.children}
        <BlockedModal open={block} setOpen={setBlock} unblockStep={unblockStep} setUnblockStep={setUnblockStep} nextStep={nextStep} />
      </div>
      {blockActions && (
        <div className='ks-blockAllActions' onClick={(e) => { e.stopPropagation(); }}></div>
      )}
      <Cookies />
    </div>
  );
}

export default LayoutV2;
