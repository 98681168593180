import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BaseDS from '../datastore/BaseDS';
import { TChapter, TSession, TUserInfo } from '../types';
import './css/ChapterMap.scss'
import Image from './elements/Image';
import { ReactComponent as IconDownloadBook } from '../assets/download_book.svg';
import { ReactComponent as ArrowNotification } from '../assets/arrow_notification.svg';
import { ReactComponent as Trophy } from '../assets/trophy.svg';
import { ReactComponent as NextBtn } from '../assets/walad_cards_nextBtn.svg';
import { ReactComponent as PreviousBtn } from '../assets/walad_cards_previousBtn.svg';
import { ReactComponent as WaladBtn } from '../assets/walad_cards_btn.svg';
import SVG from './elements/SVG';
import UserDS from '../datastore/UserDS';
import Bonus from './bonus/Bonus';
import SVGWithSoundIcon from './elements/SVGWithSoundIcon';
import LoadingPage from './elements/LoadingPage';
import Certificate from './elements/Certificate';
import FileLoadEvent from './utils/FileLoadEvent';
import Sounds from './utils/Sounds';
import WaladCardsModal from './bonus/WaladCardsModal';
import { ChapterContext, chapterIdToRoot } from './utils/ChapterContext';
import { ClipLoader } from 'react-spinners';
import useResponsiveSize from 'components/utils/useResponsiveSize';
import { unblockedUsers } from 'utils/Constants';

function ChapterMap(props: any) {

  const { chapterDefinition, setChapterDefinition, downloadFile,
    downloading, currentProfil, setCurrentProfil, setChapterId, showDiploma, setShowDiploma } = useContext(ChapterContext);
  const { showWaladCards, setShowWaladCards } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [mapUrl, setMapUrl] = useState<string>('');
  const [currentSession, setCurrentSession] = useState<TSession>();
  const [openBonus, setOpenBonus] = useState<boolean>(false);
  const [notificationPlayed, setNotificationPlayed] = useState<boolean>(false);
  const notificationImage = useRef<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const history = useHistory();
  const urlParams = new URLSearchParams(props.location.search);
  const bonusSessionKey: any = urlParams.get('sessionTest');
  const loaderSize = useResponsiveSize(160);

  const chapterId = props.chapterId || props.match.params.chapterId;
  useEffect(() => {
    setLoading(true);
    FileLoadEvent.triggerFileToLoad('loadingchapter');
    setChapterId(chapterId);
    UserDS.getCurrentUserProfil((profil: TUserInfo) => {
      setCurrentProfil(profil);
      BaseDS.getFullChapterById(chapterId).then((data: TChapter) => {
        setChapterDefinition(data);
        if (bonusSessionKey && data.session && data.session[(bonusSessionKey - 1)]) {
          setCurrentSession(data.session[(bonusSessionKey - 1)]);
        }
        if (data && data.mapImage) {
          setMapUrl(data.mapImage);
          if (data.session && data.session.length) {
            const css = document.createElement('style');
            css.textContent = data.session.map((session) => (`
            g#session_${session.sessionKey}.ks-session-enabled > path:first-child {
              fill: ${session.color};
              cursor: pointer;
            }`
            )).join('\n');
            document.head.append(css);
          }
        }
        setTimeout(() => FileLoadEvent.triggerFileLoaded('loadingchapter'), 500);
        setLoading(false);
      }).catch(() => setLoading(false));
    });
  }, [chapterId, setChapterId, bonusSessionKey, setChapterDefinition, setCurrentProfil]);

  const hasNewMessage = useCallback(() => {
    return bonusSessionKey || ((!currentProfil
      || !currentSession
      || !currentProfil[currentSession.chapterId as keyof TUserInfo]
      || !currentProfil[currentSession.chapterId as keyof TUserInfo]['bonus']
      || !currentProfil[currentSession.chapterId as keyof TUserInfo]['bonus'][currentSession.sessionKey])
      && currentSession && (currentSession.leftImage || currentSession.rightImage));
  }, [currentProfil, currentSession, bonusSessionKey])

  const addOnClickEvent = useCallback((svg: SVGElement | undefined) => {
    if (svg && chapterDefinition && chapterDefinition.session) {
      chapterDefinition.session.forEach((session: TSession) => {
        const sessionMapEle: SVGElement | null = svg.querySelector(`g[id^="session_${session.sessionKey}"]`);
        if (sessionMapEle) {
          if (session.sessionKey === 1 
            || unblockedUsers.indexOf(currentProfil.name) > -1 || unblockedUsers.indexOf(currentProfil.profilKey) > -1
            || (currentProfil && currentProfil[session.chapterId as keyof TUserInfo]
              && currentProfil[session.chapterId as keyof TUserInfo][session.sessionKey])) {

            sessionMapEle.classList.add('ks-session-enabled');

            if (unblockedUsers.indexOf(currentProfil?.name) === -1 && unblockedUsers.indexOf(currentProfil?.profilKey) === -1 && currentProfil && ((
              (!currentProfil[session.chapterId as keyof TUserInfo] || !currentProfil[session.chapterId as keyof TUserInfo][session.sessionKey]) && session.sessionKey === 1)
              || (currentProfil[session.chapterId as keyof TUserInfo][session.sessionKey]
                && currentProfil[session.chapterId as keyof TUserInfo][session.sessionKey].length === 0)
              || (currentProfil[session.chapterId as keyof TUserInfo][session.sessionKey]
                && currentProfil[session.chapterId as keyof TUserInfo][session.sessionKey].filter((s: any) => s.state !== 'completed').length > 0))) {
              sessionMapEle.classList.add('ks-session-current');
              if (!currentSession && !bonusSessionKey) {
                setCurrentSession(session);
              }
            }
            sessionMapEle.addEventListener('click', () => {
              if (!hasNewMessage()) {
                Sounds.playButtonSound();
                const to = `/chapter/${chapterDefinition.id}/session/${session.sessionKey}`
                history.push(to, {
                  from: process.env.REACT_APP_FEATURE_FREEMIUM === '1'
                    ? chapterIdToRoot[chapterDefinition.id].replace(':chapterId', chapterDefinition.id)
                    : `/chapter/${chapterDefinition.id}`
                })
              }
            }, false);
          }
        }
      });
    }
  }, [currentProfil, chapterDefinition, history, currentSession, hasNewMessage, bonusSessionKey])

  const bonusSession = () => {
    return bonusSessionKey && chapterDefinition && chapterDefinition?.session && chapterDefinition?.session[(bonusSessionKey - 1)]
      ? chapterDefinition.session[bonusSessionKey - 1] : currentSession
  }

  useEffect(() => {
    if (hasNewMessage() && notificationImage.current
      && !isLoading
      && !notificationPlayed) {
      notificationImage.current.play();
      setNotificationPlayed(true);
    }
  }, [hasNewMessage, notificationPlayed, isLoading]);

  const calculateLoaderSize = (optimalSize: number) => {
    const screen = document.getElementsByClassName('ks-mainFrame');
    return screen && screen.length ? optimalSize * screen[0].clientWidth / 1527 : optimalSize;
  }

  const scrollLeft = () => {
    document.getElementsByClassName('ks-imageMap')[0].scroll({
      left: document.getElementsByClassName('ks-imageMap')[0].scrollLeft + calculateLoaderSize(975),
      behavior: 'smooth'
    });
  }

  const scrollRight = () => {
    document.getElementsByClassName('ks-imageMap')[0].scroll({
      left: document.getElementsByClassName('ks-imageMap')[0].scrollLeft - calculateLoaderSize(975),
      behavior: 'smooth'
    });
  }

  return (
    <>
      <LoadingPage key={'chapterMap_' + chapterId} afterLoad={() => setIsLoading(false)} />
      {loading ? (<div className="ks-chapterMapLoading">
        <ClipLoader color='white' size={loaderSize} />
      </div>) : (
        <>
          <WaladCardsModal waladCardsIsOpen={showWaladCards} setWaladCardsIsOpen={setShowWaladCards} userProgress={currentProfil} />
          <div className={"ks-chapterMapContent ks-chapter_" + chapterDefinition?.chapterKey + ' ks-' + chapterDefinition?.phaseType}>
            {currentSession && (
              <Bonus sessionDef={bonusSession()} modalIsOpen={openBonus} setIsOpen={setOpenBonus}
                currentProfil={currentProfil} setCurrentProfil={setCurrentProfil} chapterDef={chapterDefinition}
                sessionTestKey={bonusSessionKey} />
            )}
            <div className='ks-background'>
              <Image
                imageSrc={chapterDefinition?.background ? chapterDefinition?.background : null}
                imageAlt={chapterDefinition?.name ? chapterDefinition?.name : ''} />
            </div>
            {chapterDefinition && ((currentSession && currentSession.leftImage) || chapterDefinition.chapterKey === 0) && (
              <div className={'ks-imageBottomLeft ks-clickable' + (hasNewMessage() ? ' ks-newMessage' : '')} onClick={() => {
                Sounds.playButtonSound(); setOpenBonus(true);
              }}>
                <SVGWithSoundIcon ref={notificationImage} className='ks-imageChapterMap'
                  imageAudio={currentSession && currentSession.leftImage ? currentSession.leftImage : 'images/walad_default.png'} hideAudioIcon onClick={() => (true)} />
                {hasNewMessage() && (
                  <ArrowNotification className='ks-arrowBottomLeft' />
                )}
              </div>
            )}
            {chapterDefinition && ((currentSession && currentSession.rightImage) || chapterDefinition.chapterKey === 1) && (
              <div className={'ks-imageBottomRight ks-clickable' + (hasNewMessage() ? ' ks-newMessage' : '')} onClick={() => { Sounds.playButtonSound(); setOpenBonus(true); }}>
                <SVGWithSoundIcon ref={notificationImage} className={'ks-imageChapterMap ' + (currentSession && currentSession.rightImage ? '' : 'ks-flipImage')}
                  imageAudio={currentSession && currentSession.rightImage ? currentSession.rightImage : 'images/walad_default.png'} hideAudioIcon />
                {hasNewMessage() && (
                  <ArrowNotification className='ks-arrowBottomRight' />
                )}
              </div>
            )}

            <div className='ks-leftPanel'>
              {process.env.REACT_APP_FEATURE_FREEMIUM !== '1' && (<>
                <div className={'ks-downloadBook ks-clickable ' + (downloading ? 'ks-disabled' : '')} onClick={() => {
                  Sounds.playButtonSound();
                  downloadFile()
                }}>
                  <IconDownloadBook />
                  <div className='ks-usnername'>Livre PDF</div>
                </div>
                <div className="ks-waladCardsBtn ks-clickable" onClick={() => { Sounds.playButtonSound(); setShowWaladCards(true); }}>
                  <WaladBtn />
                  <div className='ks-usnername'>Mes cartes</div>
                </div>
                {currentProfil && currentProfil[chapterId] && currentProfil[chapterId].assessmentResult && (
                  <div className="ks-trophy ks-clickable" onClick={() => { Sounds.playButtonSound(); setShowDiploma(true) }}>
                    <Trophy />
                    <div className='ks-usnername'>Mon diplôme</div>
                  </div>
                )}
              </>)}
            </div>
            <div className='ks-chapterTitleImage'>

              <div className='ks-arrowPrevious ks-clickable' onClick={() => scrollRight()}><PreviousBtn /></div>
              <SVG className={'ks-imageMap' + (hasNewMessage() ? ' ks-disabled' : '')} onClick={(e) => { e.stopPropagation() }}
                src={mapUrl} afterInjection={(err: any, svg: any) => addOnClickEvent(svg)} />

              <div className='ks-arrowNext ks-clickable' onClick={() => scrollLeft()}><NextBtn /></div>
            </div>

            {currentProfil && currentProfil[chapterId] && currentProfil[chapterId].assessmentResult && (
              <Certificate modalIsOpen={showDiploma} setIsOpen={setShowDiploma} userInfo={currentProfil} chapterDetails={currentProfil ? currentProfil[chapterId] : {}} />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ChapterMap;
